import checkout from "layouts/pages/Requsition/new Requsition/schemas/form";
const {
  formField: { WHID, quantityRqst, mmuCode, MMUItemCode, type },
} = checkout;

const initialValues = {
  [WHID.name]: "",
  // [quantityRqst.name]: "",
  //  [mmuCode.name]: "",

  // [range.name]: "",
  [MMUItemCode.name]: "",
  [type.name]: "",
};

export default initialValues;
