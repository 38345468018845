import { useState } from "react";
// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useLocation, useNavigate } from "react-router-dom";

// NewUser page components
import ItemInfo from "layouts/pages/item/new item/componets/ItemInfo";

// Newlab layout schemas for form and form feilds
import validations from "layouts/pages/item/new item/schemas/validations";

import form from "layouts/pages/item/new item/schemas/form";
import initialValues from "layouts/pages/item/new item/schemas/initialValues";
import axios from "axios";
import MDAlert from "components/MDAlert";

function getSteps() {
  return ["Item Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <ItemInfo formData={formData} />;

    default:
      return null;
  }
}

function CreateItem() {
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const params = new URLSearchParams(location.search);
  console.log("location", location);
  const id = params.get("id");
  console.log("hii", id);
  // useEffect(() => {
  //   if (id == null) {
  //     setRoleName("");
  //     setRoleDescription("");
  //   }
  // }, [id]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    console.log("hii", values);
    var attributeObject = {};
    values.attributes?.keyList.map((key) => {
      attributeObject[key] = values[key];
    });
    console.log("attribFinal", attributeObject);
    const getData = {
      mmucode: values.sapcode.trim(),
      sapcode: "",
      uom: values.uom,
      approvedby: "",
      approveddate: "",
      classname: "",
      createdby:
        JSON.parse(localStorage.getItem("mmu-auth")).firstname +
        " " +
        JSON.parse(localStorage.getItem("mmu-auth")).lastname,
      createddate: null,
      documentofproof: "",
      id: null,
      latlong: "",
      location: null,
      modifiedby: "",
      modifieddate: null,
      //name: values.itemame.trim(),
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "SYNCED",
      type: values.itemtype,
      attributes: attributeObject,
      packaging: values.package,
    };
    await axios
      .post(
        "itemmaster-service/api/itemMaster/v1/create",
        getData
      )

      .then((response) => {
        console.log("gfgfgf", response);
        navigate("/pages/users/item-list");
      })
      .catch((error) => {
        setErrorMsg("Something went wrong!!");

       
        setErrorAlert(true);
      });

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log("140",values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {errorAlert && (
        <MDAlert color="error" dismissible>
          {errorMsg}
        </MDAlert>
      )}

      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          ITEM MASTER
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateItem;
