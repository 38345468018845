import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Newtype page components
import TypeInfo from "layouts/pages/type/new-type/componets/typeInfo";


// Newlab layout schemas for form and form feilds
import validations from "layouts/pages/type/new-type/schemas/validations";
import form from "layouts/pages/type/new-type/schemas/form";
import initialValues from "layouts/pages/type/new-type/schemas/initialValues";

import axios from "axios";
import { useNavigate } from "react-router-dom";






function getSteps() {
  return ["Type Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <TypeInfo formData={formData} />;
    // case 1:
    //   return <Address formData={formData} />;
    // case 2:
    //   return <Socials formData={formData} />;
    // case 3:
    //   return <Profile formData={formData} />;
    default:
      return null;
  }
}


function Createtype() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    console.log("hii", values);
    const getData = {

      code: null,

      approvedby: null,
      approveddate: null,
      category: values.Category,
      categorytype:values.Categorytype,
      classname: "",
      code: "",
      createdby:
        JSON.parse(localStorage.getItem("mmu-auth")).firstname +
        " " +
        JSON.parse(localStorage.getItem("mmu-auth")).lastname,
      createddate: null,
      description: values.typeDescrption,
       documentofproof: "",
      id: null,
      latlong: "",
      modifiedby: null,
      modifieddate: null,
      name: values.typeName,
      recstatus: "OPEN",
      resourcecode: "DPF",
      rolecode: "",
      status: "Active",
      syncstatus: "SYNCED",
      tempid: ""

    };
    await axios
      .post("typemaster-service/api/TypeMaster/v1/create", getData)

      .then((response) => {

        console.log("gfgfgf", response);
      })
      .catch((error) => {
        console.log(error);
      });
    navigate("/page/component/type-list");

    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      {/* <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper> */}
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography variant="h4" fontWeight="small" color="white" mt={1}>
                          Type Master
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                            onClick={() => { console.log("submit") }}
                          >
                            submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default Createtype