import * as Yup from "yup";
import checkout from "layouts/pages/Projectmmu/new Projectmmu/schemas/form";

const {
  formField: {
    createproject,
    
  },
} = checkout;

const validations = [
  Yup.object().shape({
    // [createproject.name]: Yup.string().required(createproject.errorMsg),
    // [projectselection.name]: Yup.string().required(projectselection.errorMsg),
  }),
];

export default validations;
