/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";

import SignInBasic from "layouts/authentication/sign-in/basic";


// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////


// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";



import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import Chatus from "layouts/pages/QuestionAnswer/Chatus";

const routeChatUs = [
    {
        type: "collapse",

        name: localStorage.getItem("mmu-auth")
            ? JSON.parse(localStorage.getItem("mmu-auth")).firstname +
            " " +
            JSON.parse(localStorage.getItem("mmu-auth")).lastname
            : "",

        key: "brooklyn-alice",
        icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
        collapse: [
            {
                name: "My Profile",
                key: "my-profile",
                route: "/pages/profile/profile-overview",
                component: <ProfileOverview />,
            },

            {
                name: "Logout",
                key: "logout",
                route: "/authentication/sign-in/basic",
                component: <SignInBasic />,
            },
        ],
    },
    // { type: "divider", key: "divider-0" },
    // {
    //     type: "collapse",
    //     name: "Dashboards",
    //     key: "dashboards",
    //     icon: <Icon fontSize="medium">dashboard</Icon>,
    //     collapse: [
    //         {
    //             name: "Analytics",
    //             key: "analytics",
    //             route: "/dashboards/analytics",
    //             component: <Analytics />,
    //         },
            // {
            //     name: "Patient Report",
            //     key: "patient-report",
            //     route: "/dashboards/patient-report",
            //     component: <PatientReport />,
            //   },
            // {
            //     name: "Prescription Report",
            //     key: "prescription-report",
            //     route: "/dashboards/prescription-report",
            //     component: <PrescriptionReport />,
            //   }

   //     ],
  //  },
    /// /////////////////////////CUSTOM///////////////////////////////////

    { type: "title", title: "Masters", key: "title-masters" },
   ,
    {
        type: "collapse",
        name: "CHAT US",
        key: "CHAT-US",
        icon: <CalendarTodayIcon fontSize="medium"></CalendarTodayIcon>,
        collapse: [
            {
                name: "Chat Us",
                key: "chat-us",
                route: "/pages/users/chat-us",
                component: <Chatus />,
              },
          
        ],
      },
    

  
  



];


export default routeChatUs;