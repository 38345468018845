import * as Yup from "yup";
import checkout from "layouts/pages/Association/new Association/schemas/form";

const {
  formField: {
    mmu,
    mmuname,
    vechicleassigned,
    vehicleNo,
    profile,
    mmudescription,
    equipmentassigned,
    warehouse,
    role,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    // [mmu.name]: Yup.string().required(mmu.errorMsg),
    // [mmuname.name]: Yup.string().required(mmuname.errorMsg),
    // [vechicleassigned.name]: Yup.string().required(vechicleassigned.errorMsg),
    // [vehicleNo.name]: Yup.string().required(vehicleNo.errorMsg),
    // [mmudescription.name]: Yup.string().required(mmudescription.errorMsg),
    // [equipmentassigned.name]: Yup.string().required(equipmentassigned.errorMsg),
    // [warehouse.name]: Yup.string().required(warehouse.errorMsg),
    // [role.name]: Yup.string().required(role.errorMsg),
    // [profile.name]: Yup.string().required(profile.errorMsg),
  }),
];

export default validations;
