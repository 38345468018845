/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import { Autocomplete, TextField } from "@mui/material";
import { BarLoader } from "react-spinners";
import MDInput from "components/MDInput";
import DatePicker from "react-flatpickr";

import moment from "moment-timezone";
import MDButton from "components/MDButton";
import { CSVLink } from "react-csv";
import { getFilter } from "service/reporting";
import { getComponent } from "service/inputComponents";


function Test() {
    function stringTokenizer(string, delim) {
        const st = string.split(delim);
        return st;
    }

    const { sales, tasks, patient, prescription } = reportsLineChartData;
    const [barData, setBarData] = useState(reportsBarChartData);
    const [patientData, setPatientData] = useState(patient);
    const [presData, setPresData] = useState(prescription);
    const [totalVisits, setTotalVisits] = useState(0);
    const [totalPatients, setTotalPatients] = useState(0);
    const [totalPres, setTotalPres] = useState(0);
    const [filterMmu, setFilterMmu] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
    const [currentMonth, setCurrentMonth] = useState(moment().format('YYYY-MM'));
    const [month, setMonth] = useState(currentMonth ? stringTokenizer(currentMonth, "-")[1] : 0);
    const [year, setYear] = useState(currentMonth ? stringTokenizer(currentMonth, "-")[0] : 0);
    const [startDate, setStartDate] = useState(new Date().toDateString());
    const [endDate, setEndDate] = useState(new Date());
    const [color] = useState("#344767");
    const [projectDetail, setProjectDetail] = useState(localStorage.getItem("project-Detail"));
    const [pincodeList, setPincodeList] = useState(
        // projectDetail ? JSON.parse(projectDetail).pincodelist : []
        []
    );

    const [mmuList, setMmuList] = useState(projectDetail ? JSON.parse(projectDetail).mmulist : [])
    const [projectList, setProjectList] = useState(projectDetail ? JSON.parse(projectDetail).projectlist : [])
    const dataObj = {
        columns: [
            {
                "accessor": "MMU Name",
                "Header": "MMU Name"
            },
            {
                "accessor": "Vehicle No.",
                "Header": "Vehicle No."
            },
            {
                "accessor": "State Name",
                "Header": "State Name"
            },
            {
                "accessor": "District Name",
                "Header": "District Name"
            },
            {
                "accessor": "Sub-district Name",
                "Header": "Sub-district Name"
            },
            {
                "accessor": "Village Name",
                "Header": "Village Name"
            }

        ],
        rows: [],
    };
    function tConvert(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1); // Remove full string match value
            time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }
    function convertTZ(date) {
        var format = "YYYY-MM-DDTHH:mm:ss.sssZ";
        var df = moment(date, format).tz("Asia/Kolkata").format(format);
        return new Date(df).toLocaleDateString() + " " + tConvert(new Date(df).toLocaleTimeString());
    }
    const [tableDatas, setTableDatas] = useState(dataObj);
    const [projectValue, setProjectValue] = useState(null);
    const [filters, setFilters] = useState(getFilter("", ""));
    const [mmuvalue, setMmuvalue] = useState(null);
    const [typevalue, settypevalue] = useState(null);
    const [days, setDays] = useState(null);
    const [datas, setDatas] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [filename, setFilename] = useState("Patient_Repot.csv");
    const [engagementList, setEngagementList] = useState();


    // Action buttons for the BookingCard
    const actionButtons = (
        <>
            <Tooltip title="Refresh" placement="bottom">
                <MDTypography
                    variant="body1"
                    color="primary"
                    lineHeight={1}
                    sx={{ cursor: "pointer", mx: 3 }}
                >
                    <Icon color="inherit">refresh</Icon>
                </MDTypography>
            </Tooltip>
            <Tooltip title="Edit" placement="bottom">
                <MDTypography
                    variant="body1"
                    color="info"
                    lineHeight={1}
                    sx={{ cursor: "pointer", mx: 3 }}
                >
                    <Icon color="inherit">edit</Icon>
                </MDTypography>
            </Tooltip>
        </>
    );

    async function getEngagementData(projectCode) {
        await axios
            .get(`/visualization-service/api/visualization/v1//project/getTotalPatients?projectCodeList=${projectCode}`)
            .then((response) => {
                console.log("93", response.data);
                setEngagementList(response.data);


            })
            .catch((e) => {
                console.log("error", e);
            });
    }
    async function getVisitCount(mmucode) {
        await axios
            .get(`/visualization-service/api/visualization/v1/getTotalVisit?mmuCodeList=${mmucode}`)
            .then((response) => {
                console.log("106", response.data);
                setTotalVisits(response.data);


            })
            .catch((e) => {
                console.log("error", e);
            });
    }
    async function getBarData(mmucode) {
        await axios
            .get(`/visualization-service/api/visualization/v1/getCoverage?template=BARCHART001&mmuCodeList=${mmucode}`)
            .then((response) => {
                console.log("119", response.data);
                setBarData(response.data);


            })
            .catch((e) => {
                console.log("error", e);
            });
    }


    async function getPresData(mmucode) {
        await axios
            .get(`/visualization-service/api/visualization/v1/getLine?template=LINECHART002&mmuCodeList=${mmucode}&type=Prescription`)
            .then((response) => {
                console.log("149", response.data);
                setPresData(response.data);
                var totalCount = 0;
                response.data?.datasets?.data.map((count) => {
                    totalCount = totalCount + parseInt(count, 10);
                })
                setTotalPres(totalCount);

            })
            .catch((e) => {
                console.log("error", e);
            });
    }
    async function getMmu(projectCode) {
        await axios
            .get(`/project-service/api/ProjectMMU/v1/get?projectcode=${projectCode}`)
            .then((response) => {
                console.log("149", response.data);
                setFilterMmu(response.data.data.dtoList);

            })
            .catch((e) => {
                console.log("error", e);
            });
    }

    function getMmuCode() {
        var mmucode = ""
        var projectCode = ""
        // if(mmuList.length > 0)
        mmuList.map((item) => {
            if (mmucode === "") {
                mmucode = mmucode + item.code;
            } else {
                mmucode = mmucode + "," + item.code;
            }

        })
        projectList.map((item) => {
            if (projectCode === "") {
                projectCode = projectCode + item.code;
            } else {
                projectCode = projectCode + "," + item.code;
            }

        })
        console.log("144", projectCode);
        getEngagementData(projectCode)
        getBarData(mmucode);
        getPatientData(mmucode);
        getPresData(mmucode);
        getVisitCount(mmucode);

    }
    function buildFilename(name, code) {
        setFilename("Patient_Report_" + name + "_" + code + "_" + moment().format('YYYY-MM-DD') + ".csv");
    }
    function buildFilenameV2(type, code) {
        setFilename(type + "_" + code + "_" + moment().format('YYYY-MM-DD') + ".csv");
    }
    async function getData(mmuCode, type, year, month) {
        setLoading(true);
        await axios
            .get(`/visualization-service/api/visualization/v2/getReport?month=${month}&year=${year}&mmucode=${mmuCode}&type=${type}`)
            // .get(`http://localhost:9009/api/visualization/v2/getReport?month=${month}&year=${year}&mmucode=${mmuCode}&type=${type}`)
            .then((response) => {

                console.log("132", response.data);
               

                const dataObj = {
                    columns: response.data.tableHeaders,
                    rows: response.data.data,
                };
             
                setHeaders(response.data.csvheaders);
                buildFilenameV2(type, mmuCode);
                setDatas(response.data.data);
                setTableDatas(dataObj)
                setLoading(false);

            })
            .catch((e) => {
                console.log("error", e);
                setLoading(false);
            });
    }
    async function getPatientDataByProject(projectCode, name) {
        setLoading(true);
        await axios
            .get(`/visualization-service/api/visualization/v2/getLineByProject?template=LINECHART001&projectCode=${projectCode}&type=Patient`)
            .then((response) => {
                console.log("132", response.data);
                setPatientData(response.data);
                var totalCount = 0;
                response.data?.datasets?.data.map((count) => {
                    totalCount = totalCount + parseInt(count, 10);
                })
                setTotalPatients(totalCount);
                const dataObj = {
                    columns: [
                        { Header: "patientid", accessor: "patientid" },
                        { Header: "name", accessor: "name" },

                        { Header: "age", accessor: "age" },
                        { Header: "gender", accessor: "gender" },
                        { Header: "village", accessor: "village" },
                        { Header: "mobile no", accessor: "mobileno" },
                        { Header: "document", accessor: "documenttype" },
                        { Header: "document number", accessor: "documentno" },

                        { Header: "created date", accessor: "fcreateddate" },

                    ],
                    rows: [],
                };
                response.data?.objects.map((o) => {
                    o.projectcode = projectCode;
                    o.projectname = name;
                    o.fcreateddate = tConvert(o.createddate);
                    dataObj.rows.push(o);
                })
                setHeaders([
                    { label: "patientid", key: "patientid" },
                    { label: "name", key: "name" },
                    { label: "age", key: "age" },
                    { label: "gender", key: "gender" },
                    { label: "village", key: "village" },
                    { label: "mobile no", key: "mobileno" },
                    { label: "document", key: "documenttype" },
                    { label: "document number", key: "documentno" },
                    { label: "created date", key: "fcreateddate" },
                    { label: "project name", key: "projectname" },
                    { label: "project code", key: "projectcode" },
                ]);
                buildFilename(name, projectCode);
                setDatas(response.data?.objects);
                setTableDatas(dataObj)
                setLoading(false);

            })
            .catch((e) => {
                console.log("error", e);
                setLoading(false);
            });
    }
    async function getPatientDataByProjectV2(projectCode, name, date, days) {
        setLoading(true);
        await axios
            // .get(`/visualization-service/api/visualization/v2/getLineByProject?template=LINECHART001&projectCode=${projectCode}&type=Patient`)
            .get(`/visualization-service/api/visualization/v2/getData?template=LINECHART001&projectCode=${projectCode}&type=Patient&currentDate=${date}&days=${days}`)
            .then((response) => {
                console.log("132", response.data);
                setPatientData(response.data);
                var totalCount = 0;
                response.data?.datasets?.data.map((count) => {
                    totalCount = totalCount + parseInt(count, 10);
                })
                setTotalPatients(totalCount);
                const dataObj = {
                    columns: [
                        { Header: "patientid", accessor: "patientid" },
                        { Header: "name", accessor: "name" },

                        { Header: "age", accessor: "age" },
                        { Header: "gender", accessor: "gender" },
                        { Header: "village", accessor: "village" },
                        { Header: "mobile no", accessor: "mobileno" },
                        { Header: "document", accessor: "documenttype" },
                        { Header: "document number", accessor: "documentno" },

                        { Header: "created date", accessor: "fcreateddate" },

                    ],
                    rows: [],
                };
                response.data?.objects.map((o) => {
                    o.projectcode = projectCode;
                    o.projectname = name;
                    o.fcreateddate = tConvert(o.createddate);
                    dataObj.rows.push(o);
                })
                setHeaders([
                    { label: "patientid", key: "patientid" },
                    { label: "name", key: "name" },
                    { label: "age", key: "age" },
                    { label: "gender", key: "gender" },
                    { label: "village", key: "village" },
                    { label: "mobile no", key: "mobileno" },
                    { label: "document", key: "documenttype" },
                    { label: "document number", key: "documentno" },
                    { label: "created date", key: "fcreateddate" },
                    { label: "project name", key: "projectname" },
                    { label: "project code", key: "projectcode" },
                ]);
                buildFilename(name, projectCode);
                setDatas(response.data?.objects);
                setTableDatas(dataObj)
                setLoading(false);

            })
            .catch((e) => {
                console.log("error", e);
                setLoading(false);
            });
    }
    async function getPatientDataByMmuV2(mmuCode, name, date, days) {
        setLoading(true);
        await axios
            // .get(`/visualization-service/api/visualization/v2/getLine?template=LINECHART001&mmucode=${mmuCode}&type=Patient`)
            .get(`/visualization-service/api/visualization/v2/getData?template=LINECHART001&mmucode=${mmuCode}&type=Patient&currentDate=${date}&days=${days}`)
            .then((response) => {

                console.log("132", response.data);
                setPatientData(response.data);
                var totalCount = 0;
                response.data?.datasets?.data.map((count) => {
                    totalCount = totalCount + parseInt(count, 10);
                })
                setTotalPatients(totalCount);
                const dataObj = {
                    columns: [
                        { Header: "patientid", accessor: "patientid" },
                        { Header: "name", accessor: "name" },

                        { Header: "age", accessor: "age" },
                        { Header: "gender", accessor: "gender" },
                        { Header: "village", accessor: "village" },
                        { Header: "mobile no", accessor: "mobileno" },
                        { Header: "document", accessor: "documenttype" },
                        { Header: "document number", accessor: "documentno" },
                        { Header: "created date", accessor: "fcreateddate" },


                    ],
                    rows: response.data?.objects,
                };
                response.data?.objects.map((o) => {
                    o.mmucode = mmuCode;
                    o.mmuname = name;
                    o.fcreateddate = tConvert(o.createddate);
                })
                setHeaders([
                    { label: "patientid", key: "patientid" },
                    { label: "name", key: "name" },
                    { label: "age", key: "age" },
                    { label: "gender", key: "gender" },
                    { label: "village", key: "village" },
                    { label: "mobile no", key: "mobileno" },
                    { label: "document", key: "documenttype" },
                    { label: "document number", key: "documentno" },
                    { label: "created date", key: "fcreateddate" },
                    { label: "mmu", key: "mmuname" },
                    { label: "mmucode", key: "mmucode" },
                ]);
                buildFilename(name, mmuCode);
                setDatas(response.data?.objects);
                setTableDatas(dataObj)
                setLoading(false);

            })
            .catch((e) => {
                console.log("error", e);
                setLoading(false);
            });
    }
    function handleFilter() {
        console.log(mmuvalue.mmu?.code);
        console.log(typevalue.type);
        console.log(year);
        console.log(month);

        if (mmuvalue != null && typevalue != null && year != 0 && month != 0) {
            console.log("proje");
            getData(mmuvalue.mmu?.code, typevalue.type, year, month);
        }
        // console.log("filter", mmuvalue.mmu?.code);
        // if (mmuvalue == null && projectValue != null && days != null) {
        //     console.log("proje");
        //     getPatientDataByProjectV2(projectValue.code, projectValue.name, currentDate, days.day);
        // } else if (mmuvalue != null && days != null) {
        //     console.log("mmu");
        //     getPatientDataByMmuV2(mmuvalue.mmu?.code, mmuvalue.mmu?.name, currentDate, days.day);
        // } else {
        //     console.log("nothing");
        // }

    }

    const defaultPropsProject = {
        options: !projectList ? [{ name: "Loading...", id: 0 }] : projectList,
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option.id === value.id,
    };
    const defaultPropsmmuCode = {
        options: !filterMmu
            ? [{ mmu: { name: "Loading..." }, id: 0 }]
            : filterMmu,
        getOptionLabel: (option) => option.mmu?.name,
        isOptionEqualToValue: (option, value) => option.id === value.id,
    };
    const defaultPropsDays = {
        options: [{ day: "1", uom: "day", code: "1" },
        { day: "2", uom: "days", code: "2" }, { day: "3", uom: "days", code: "3" },
        { day: "5", uom: "days", code: "5" },
        { day: "7", uom: "days", code: "7" },
        { day: "10", uom: "days", code: "10" }
        ],
        getOptionLabel: (option) => option.day + " " + option.uom,
        isOptionEqualToValue: (option, value) => option.code === value.code,
    };
    const defaultPropsType = {
        options: [{ type: "Prescription", code: "x" }, { type: "Patient", code: "y" }
        ],
        getOptionLabel: (option) => option.type,
        isOptionEqualToValue: (option, value) => option.code === value.code,
    };

    


    const csvReport = {
        data: datas,
        headers: headers,
        filename: filename
    };

    useEffect(() => {
        // getMmuCode();
        // var data = getFilter("", "");
        // setFilters(data);
        console.log("138", filters);

    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                <BarLoader
                    color={color}
                    loading={loading}
                    aria-label="Loading Spinner"
                />
                {/* <Grid container spacing={3} my={1}>
                    {filters.map((x) => {
                        console.log(x)
                        return (<Grid item xs={12} sm={3}>{getComponent(x)}</Grid>)
                      
                    })}
                </Grid> */}

                <Grid container spacing={3} my={1}>

                    <Grid item xs={12} sm={2.5}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            // sx={{ width: 280 }}
                            {...defaultPropsProject}
                            value={projectValue}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                setProjectValue(newValue)
                                // getPatientDataByProject(newValue.code, newValue.name);
                                getMmu(newValue.code);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Project" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            // sx={{ width: 280, marginLeft: "24px" }}
                            {...defaultPropsmmuCode}
                            value={mmuvalue}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                setMmuvalue(newValue);
                                // getPatientDataByMmu(newValue.mmu?.code, newValue.mmu?.name);
                                // getDataBymmu(newValue?.code);
                                // setMmuvalue(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Select MMU" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            // sx={{ width: 280, marginLeft: "24px" }}
                            {...defaultPropsType}
                            value={typevalue}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                settypevalue(newValue);
                                // getPatientDataByMmu(newValue.mmu?.code, newValue.mmu?.name);
                                // getDataBymmu(newValue?.code);
                                // setMmuvalue(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Data Type" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>

                        {/* <MDInput type="date" label="Select Date" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)}
                        /> */}
                        <MDInput type="month" label="Month" value={currentMonth} onChange={(e) => {
                            setCurrentMonth(e.target.value)
                            // console.log(e.target.value);
                            var st = stringTokenizer(e.target.value, "-");
                            setYear(st[0]);
                            setMonth(st[1]);
                            // console.log(st);
                        }} />

                    </Grid>

                    <Grid item xs={12} sm={3.5}>
                        <MDButton
                            variant="gradient"
                            color="success"
                            size="small"
                            onClick={handleFilter}
                        >
                            Filter
                        </MDButton>
                    </Grid>
                </Grid>

                {/* <Grid container >
          <SalesByCountry salesTable={engagementList} />
        </Grid> */}
                <MDBox mt={6}>
                    <Grid container spacing={3}>


                        <Grid item xs={12} md={6} lg={12}>
                            <MDBox mb={3}>
                                {/* <ReportsLineChart
                                    color="success"
                                    title="patient"
                                    description="Campaign Performance"

                                    date="just updated"
                                    chart={patientData}
                                /> */}
                                <MDBox m={1}>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        size="small"

                                    // onClick={handleFilter}
                                    >
                                        <CSVLink {...csvReport} style={{ color: "white" }}>Export to CSV</CSVLink>
                                    </MDButton>
                                </MDBox>
                                <DataTable table={tableDatas} canSearch={true} />
                            </MDBox>
                        </Grid>

                    </Grid>
                </MDBox>

            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Test;

