import checkout from "layouts/pages/item/new item/schemas/form";

const {
  formField: { itemtype, sapcode, uom },
} = checkout;

const initialValues = {
  // [itemame.name]: "",
  [itemtype.name]: "",
  [sapcode.name]: "",
  [uom.name]: "",
};

export default initialValues;
