// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function UserInfo({ formData }) {
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");

  const [value, setValue] = React.useState(null);
  const [roleList, setRoleList] = useState("");

  const [departmentList, setDepartmentList] = useState("");
  const [designationList, setDesignationList] = useState("");
  const [departmentValue, setDepartmentValue] = useState(null);
  const [designationValue, setDesignationValue] = useState(null);

  const { formField, values, errors, touched } = formData;
  const defaultProps = {
    options: !roleList ? [{ name: "Loading...", id: 0 }] : roleList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparentdepartment = {
    options: !departmentList ? [{ name: "Loading...", id: 0 }] : departmentList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparentdesignation = {
    options: !designationList
      ? [{ name: "Loading...", id: 0 }]
      : designationList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const {
    firstName,
    lastName,
    username,
    role,
    password,
    repeatPassword,
    rolename,
    designation,
    department,
  } = formField;
  var {
    firstName: firstNameV,
    lastName: lastNameV,
    username: usernameV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
  } = values;

  async function getDepartmentData() {
    await axios
      .get(
        "department-service-mongo/api/department/v2/get"
      )
      .then((response) => {
        setDepartmentList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getDesignationData(deptCode) {
    await axios
      .get(
        `designation-service-mongo/api/designation/v2/get?deptcode=${deptCode}`
      )
      .then((response) => {
        setDesignationList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getData() {
    await axios
      .get(
        `role-service-mongo/api/role/v2/get`
      )
      .then((response) => {
        setRoleList(response.data.data.dtoList);
        getDepartmentData();
        setLoading(false);
        console.log("====================================");
        console.log("de", defaultProps);
        console.log("====================================");
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  const handleChange = (event) => {
    roleV = event.target.value.value;
    console.log(roleV);
    setValue(event.target.value);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">New User</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={firstName.type}
                label={firstName.label}
                name={firstName.name}
                value={firstNameV}
                placeholder={firstName.placeholder}
                error={errors.firstName && touched.firstName}
                success={firstNameV.length > 0 && !errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={lastName.type}
                label={lastName.label}
                name={lastName.name}
                value={lastNameV}
                placeholder={lastName.placeholder}
                // error={errors.lastName && touched.lastName}
                // success={lastNameV.length > 0 && !errors.lastName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={username.type}
                label={username.label}
                name={username.name}
                value={usernameV}
                placeholder={username.placeholder}
                error={errors.username && touched.username}
                success={usernameV.length > 0 && !errors.username}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultProps}
                value={value}
                onChange={(event, newValue) => {
                  // handleChange(event);
                  console.log(newValue);
                  setValue(newValue);
                  values.role = newValue.rolecode;
                  values.rolename = newValue.name;

                  console.log(values);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.role && touched.role}
                    success={role.length > 0 && !errors.role}
                    variant="standard"
                    label={role.label}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsparentdepartment}
                value={departmentValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setDepartmentValue(newValue);
                  getDesignationData(newValue.code);
                  values.department = newValue;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={department.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsparentdesignation}
                value={designationValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setDesignationValue(newValue);
                  values.designation = newValue;
                  // values.designation =newValue?.designationname;
                  // console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.designation && touched.designation}
                    success={designation.length > 0 && !errors.designation}
                    variant="standard"
                    label={designation.label}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={password.type}
                label={password.label}
                name={password.name}
                value={passwordV}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordV.length > 0 && !errors.password}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={repeatPassword.type}
                label={repeatPassword.label}
                name={repeatPassword.name}
                value={repeatPasswordV}
                placeholder={repeatPassword.placeholder}
                error={errors.repeatPassword && touched.repeatPassword}
                success={repeatPasswordV.length > 0 && !errors.repeatPassword}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
