// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";

// vitals page components
import FormField from "layouts/pages/Disease/new-disease/componets/FormField";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";


function DiseaseInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [diseaseValue, setDiseaseValue] = useState(null);
  const [diseaseList, setDiseaseList] = useState("");
  // const [categorytypeList, setCategorytypeList] = useState("");
  // const [categorytypevalue, setCategorytypeValue] = useState(null);
  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const { diseaseName, diseaseDescrption,diseasetype} = formField;
  var { 
    diseaseName: diseaseNameV, 
    diseaseDescrption: diseaseDescrptionV 
     } = values;


  const defaultPropsDisease = {
    options: !diseaseList
      ? [
          { name: "Communicable Disease",code:"X" },
          { name: "Non-Communicable Disease",code:"y"},
              ]
      : diseaseList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

 

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Disease Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={diseaseName.type}
                label={diseaseName.label}
                name={diseaseName.name}
                value={diseaseNameV}
                placeholder={diseaseName.placeholder}
                error={errors.diseaseName && touched.diseaseName}
                success={diseaseNameV.length > 0 && !errors.diseaseName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={diseaseDescrption.type}
                label={diseaseDescrption.label}
                name={diseaseDescrption.name}
                value={diseaseDescrptionV}
                placeholder={diseaseDescrption.placeholder}
                error={errors.diseaseDescrption && touched.diseaseDescrption}
                success={diseaseDescrptionV.length > 0 && !errors.diseaseDescrption}
              />
            </Grid>
          </Grid>
           <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsDisease}
                value={diseaseValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setDiseaseValue(newValue);

                  values.diseasetype = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.diseasetype && touched.diseasetype}
                    success={diseasetype.length > 0 && !errors.diseasetype}
                    variant="standard"
                    label={diseasetype.label}
                  />
                )}
              />
            </Grid>
            
          </Grid> 
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for DiseaseInfo
DiseaseInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DiseaseInfo;
