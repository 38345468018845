import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
import { MdOutlinePreview } from "react-icons/md";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { Autocomplete, Grid, TextField } from "@mui/material";

const SearchPrescription = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [color] = useState("#344767");
  

  const [mmucode, setMmucode] = useState(
    JSON.parse(localStorage.getItem("association"))[0].mmucode);
    const [getresponse, setResponse] = useState([]);
    const [itemobject, setItemobj] = useState("");

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const dataMed = {
    columns: [
      { Header: "s.no", accessor: "presid" },
  
      { Header: "medicine description", accessor: "name" },
      { Header: "Uom", accessor: "age" },
      { Header: "QTY", accessor: "paientid" },
      
  
      
    ],
    rows: []
    
  };


  const dataObj = {
    columns: [
      { Header: "prescription id", accessor: "presid" },

      { Header: "name", accessor: "name" },
      { Header: "age", accessor: "age" },
      { Header: "patient id", accessor: "patientid" },
      { Header: "mobile no.", accessor: "mobileno" },

      { Header: "action", accessor: "action" }
    ],
    rows: [],
  };
  

  const [tableDatas, setTableDatas] = useState(dataObj);
  const [tableMed, setTableMed] = useState(dataMed);

  function viewPrescrption(item){
console.log("97",item)
const dataMed = {
  columns: [
   

    { Header: "medicine description", accessor: "medicinename" },
    { Header: "Uom", accessor: "uom" },
    { Header: "QTY", accessor: "quantity" },
    

    
  ],
  rows: item.doseslist,
  
};
item.dataMed=dataMed;
console.log("101",item)
setItemobj(item);
  }

const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function getPrescription(mmucode) {
    console.log("88", mmucode);


    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "prescription id", accessor: "prescription.presid" },

        { Header: "name", accessor: "patient.name" },
        { Header: "age", accessor: "patient.age" },
        { Header: "patient id", accessor: "patient.patientid" },
        { Header: "mobile no.", accessor: "patient.mobileno" },

        { Header: "action", accessor: "action" }
      ],
      rows: [],
    };


    await axios
      .get(
        `aggregation-service/api/Aggregation/v1/getPrescription?mmucode=${mmucode}`
      )
      .then((response) => {
        response.data.data.prescriptionAggregationDTOList
          .map((item) => {
            item.action = (
              <Link to={{}}>
                <MdOutlinePreview
                  style={iconStyle}
                  onClick={() => {
                    viewPrescrption(item);
                    setOpen(true);
                  }}
                />
              </Link>




            );
            dataObj.rows.push(item);
          });
         
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        
        setResponse(response.data.data.prescriptionAggregationDTOList);
        console.log("====================================");
        console.log(getresponse);
        console.log(response.data.data.prescriptionAggregationDTOList);
        console.log("====================================");
        
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  

  useEffect(() => {
    getPrescription(mmucode);
    
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Search-Prescription
                  </MDTypography>
                </span>



              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Prescription</DialogTitle>
       
          <div>
          
           
          {itemobject && <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div style={{ fontSize: "15px", color: "#7b809a" }}>
                    <div>
                      <text style={{ fontWeight: "bold" }}>
                        Name :
                      </text>
                      {" " + itemobject.patient.name}

                    </div>

                    <div>
                      <text style={{ fontWeight: "bold" }}>
                        Village :
                      </text>
                      {" " + itemobject.patient.village}

                    </div>
                    <div>
                      <text style={{ fontWeight: "bold" }}>
                        Address :
                      </text>
                      {" " + itemobject.patient.address}

                    </div>
                    <div>
                      <text style={{ fontWeight: "bold" }}>
                        Age :
                      </text>
                      {" " + itemobject.patient.age}

                    </div>


                    <div>
                      <text style={{ fontWeight: "bold" }}>
                        Gender. :
                      </text>
                      {" " + itemobject.patient.gender}

                    </div>



                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <DataTable table={itemobject.dataMed} canSearch={false} entriesPerPage={false} showTotalEntries={false}/>
              </AccordionDetails>
            </Accordion>
          }
            
          </div>


       
        <DialogActions>


          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button  >Update</Button> */}
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
};

export default SearchPrescription;