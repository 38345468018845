import * as React from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Autocomplete } from "@mui/material";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
//Edit button
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

function WareHouseList() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [Updatename, setUpdatename] = useState("");
  const [address, setAddress] = useState("");
  const [sapcode, setSapcode] = useState("");
  const [pincode, setPincode] = useState("");

  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [item, setItem] = useState({});
  const [err, setErr] = useState("");
  const [typeList, setTypelist] = useState("");
  const [stateList, setStateList] = useState("");
  const [districtList, setDistrictList] = useState("");
  const [subDistrictList, setSubDistrictList] = useState("");
  const [pincodeList, setPincodeList] = useState("");

  const [selecttype, setSelecttype] = useState("");
  const [selectstate, setSelectState] = useState("");
  const [selectdistrict, setSelectdistrict] = useState("");
  const [selectsubdistrict, setSelectsubdistrict] = useState("");
  const [selectpincode, setSelectpincode] = useState("");

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const defaultPropstype = {
    options: !typeList ? [{ name: "Loading...", id: 0 }] : typeList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsState = {
    options: !stateList
      ? [{ id: 0, state: { name: "Loading...", shortname: "" } }]
      : stateList,
    getOptionLabel: (option) =>
      option.state?.name + " (" + option.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsDistrict = {
    options: !districtList
      ? [{ id: 0, district: { name: "Loading...", shortname: "" } }]
      : districtList,
    getOptionLabel: (option) =>
      option.district?.name + " (" + option.district?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsSubDistrict = {
    options: !subDistrictList
      ? [{ id: 0, subdistrict: { name: "Loading...", shortname: "" } }]
      : subDistrictList,
    getOptionLabel: (option) =>
      option.subdistrict?.name + " (" + option.subdistrict?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropspincode = {
    options: !pincodeList
      ? [{ id: 0, village: { name: "Loading..." }, pincode: "" }]
      : pincodeList,
    getOptionLabel: (option) =>
      option.village?.name + " (" + option.pincode + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function typeData() {
    await axios
      .get(
        `typemaster-service/api/TypeMaster/v1/get?category=WAREHOUSE`
      )

      .then((response) => {
        setTypelist(response.data.data.dtoList);
        stateData(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function stateData(typedata) {
    //setting state===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=STATE`
      )
      .then((response) => {
        setStateList(response.data.data.dtoList);
        getData(typedata, response.data.data.dtoList);
        console.log("135", response.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getDistrictData(stateCode) {
    console.log("146", stateCode);

    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${stateCode}`
      )
      .then((response) => {
        setDistrictList(response.data.data.dtoList);

        setLoading(false);
        return [response.data.data.dtoList];
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getSubDistrictData(stateCode, districtCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&state.code=${stateCode}&district.code=${districtCode}`
      )
      .then((response) => {
        console.log("201", response);
        setSubDistrictList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getVillageData(stateCode, districtCode, subDistrictCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=VILLAGE&state.code=${stateCode}&district.code=${districtCode}&subdistrict.code=${subDistrictCode}`
      )
      .then((response) => {
        setPincodeList(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function onUpdatedata(object, typedata, stateData) {
    console.log("195", object);
    console.log("194", typedata);
    console.log("196", stateData);

    for (let i = 0; i < typedata.length; i++) {
      if (typedata[i].name === object.type) {
        console.log("229", typedata[i]);
        setSelecttype(typedata[i]);
      }
    }

    for (let i = 0; i < stateData.length; i++) {
      if (stateData[i].state.code === object.pincodeaddress.state.code) {
        console.log("237", stateData[i]);
        setSelectState(stateData[i]);
        var statecode = stateData[i].state.code;
        axios
          .get(
            `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=` +
              stateData[i].state.code
          )
          .then((response) => {
            setDistrictList(response.data.data.dtoList);

            setLoading(false);
            for (let i = 0; i < response.data.data.dtoList.length; i++) {
              if (
                response.data.data.dtoList[i].district.code ===
                object.pincodeaddress.district.code
              ) {
                setSelectdistrict(response.data.data.dtoList[i]);
                var selecteddistricCode =
                  response.data.data.dtoList[i].district.code;

                axios
                  .get(
                    `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&state.code=${statecode}&district.code=${selecteddistricCode}`
                  )
                  .then((response) => {
                    setSubDistrictList(response.data.data.dtoList);

                    for (
                      let i = 0;
                      i < response.data.data.dtoList.length;
                      i++
                    ) {
                      if (
                        response.data.data.dtoList[i].subdistrict.code ===
                        object.pincodeaddress.subdistrict.code
                      ) {
                        setSelectsubdistrict(response.data.data.dtoList[i]);
                        var selectpincodeCode =
                          response.data.data.dtoList[i].subdistrict.code;

                        axios
                          .get(
                            `pincode-service/api/pincode/v1/get?type=VILLAGE&state.code=${statecode}&district.code=${selecteddistricCode}&subdistrict.code=${selectpincodeCode}`
                          )
                          .then((response) => {
                            setPincodeList(response.data.data.dtoList);
                            console.log("276", response);
                            for (
                              let i = 0;
                              i < response.data.data.dtoList.length;
                              i++
                            ) {
                              if (
                                response.data.data.dtoList[i].village.code ===
                                object.pincodeaddress.village.code
                              ) {
                                setSelectpincode(response.data.data.dtoList[i]);
                              }
                            }
                          })
                          .catch((e) => {
                            console.log("error", e);
                          });
                      }
                    }
                  })
                  .catch((e) => {
                    console.log("error", e);
                  });
              }
            }
          })
          .catch((e) => {
            console.log("error", e);
          });
      }
    }

    setItem(object);

    setUpdatename(object.name);
    setSapcode(object.whsapcode);

    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function onDelete(item) {
    if (window.confirm("Are you sure that you want to delete warehouse record"))
      await axios
        .put(
          "wareHouseMaster-service/api/WareHouseMaster/v1/delete",
          item
        )
        .then((response) => {
          console.log(response);
          getData();
        })
        .catch((error) => {
          console.log(error);
        });
  }

  async function updateData() {
    item.name = Updatename;
    item.whsapcode = sapcode;

    await axios
      .put(
        "wareHouseMaster-service/api/WareHouseMaster/v1/update",
        item
      )
      .then((response) => {
        setOpen(false);
        typeData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData(typedata, stateData) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Name", accessor: "name" },
        { Header: "TYPE", accessor: "type" },
        { Header: "ADDRESS", accessor: "dynamicAddress" },
        { Header: "WHSAPCODE", accessor: "whsapcode" },
        // { Header: "WHMMUCODE", accessor: "whmmucode" },
        { Header: "WarehouseCode", accessor: "whmmucode" },
        { Header: "PINCODE", accessor: "pincodeaddress.pincode" },

        { Header: "ACTION", accessor: "action" },
      ],
      rows: [],
    };
    axios
      .get(
        `wareHouseMaster-service/api/WareHouseMaster/v1/get`
      )
      .then((response) => {
        response.data.data.dtoList.map((item) => {
          item.action = (
            <span>
              <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item, typedata, stateData);
                  }}
                />
              </Link>

              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          item.dynamicAddress =
            item.pincodeaddress?.village?.name +
            " (" +
            item.pincodeaddress?.pincode +
            "), " +
            item.pincodeaddress?.subdistrict?.name +
            ", " +
            item.pincodeaddress?.district?.name +
            ", " +
            item.pincodeaddress?.state?.name;
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    typeData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    WareHouse Table
                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/pages/warehouse/create-warehouse"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update WareHouse</DialogTitle>
        <DialogContent style={{ width: 550, height: 450 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="WareHouse Name"
              variant="standard"
              fullWidth
              value={Updatename}
              onChange={(e) => setUpdatename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label=" sapcode"
              variant="standard"
              fullWidth
              value={sapcode}
              onChange={(e) => setSapcode(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropstype}
              value={selecttype}
              onChange={(event, newValue) => {
                // handleChange(event);

                item.type = newValue?.name;
                setSelecttype(newValue);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="type" />
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropsState}
              value={selectstate}
              onChange={(event, newValue) => {
                setSelectState(newValue);
                getDistrictData(newValue.state?.code);
                item.pincodeaddress.state.name = newValue.state.name;
                item.pincodeaddress.state.code = newValue.state.code;
                item.pincodeaddress.state.shortname = newValue.state.shortname;

                console.log("321", selectstate);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="State" />
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropsDistrict}
              value={selectdistrict}
              onChange={(event, newValue) => {
                // handleChange(event);
                //item.type = newValue.name;

                setSelectdistrict(newValue);
                getSubDistrictData(
                  newValue.state?.code,
                  newValue.district?.code
                );
                item.pincodeaddress.district.name = newValue.district.name;
                item.pincodeaddress.district.code = newValue.district.code;
                item.pincodeaddress.district.shortname =
                  newValue.district.shortname;
                console.log("517", selectdistrict);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="Distric" />
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropsSubDistrict}
              value={selectsubdistrict}
              onChange={(event, newValue) => {
                // handleChange(event);

                setSelectsubdistrict(newValue);
                getVillageData(
                  newValue.state?.code,
                  newValue.district?.code,
                  newValue.subdistrict?.code
                );

                item.pincodeaddress.subdistrict.name =
                  newValue.subdistrict.name;
                item.pincodeaddress.subdistrict.code =
                  newValue.subdistrict.code;
                item.pincodeaddress.subdistrict.shortname =
                  newValue.subdistrict.shortname;
                console.log("405", selectsubdistrict);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="subdistic" />
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropspincode}
              value={selectpincode}
              onChange={(event, newValue) => {
                // handleChange(event);
                // item.type = newValue.name;

                setSelectpincode(newValue);
                item.pincodeaddress.village.name = newValue.village.name;
                item.pincodeaddress.village.code = newValue.village.code;
                item.pincodeaddress.village.shortname =
                  newValue.village.shortname;
                console.log("424", selectpincode);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="pincode" />
              )}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!Updatename}>
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default WareHouseList;
