const form = {
  formId: "new-mmucreation-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "name name is required.",
    },
    testname: {
      name: "testname",
      label: "Test Name",
      type: "text",
      errorMsg: "testname is required.",
    },
  
    investigationname:{
      name: "investigationname",
      label: "Investigation Name",
      type: "text",
      errorMsg: "investigationname is required.",
    },
    uom:{
      name: "uom",
      label: "Uom",
      type: "text",
      errorMsg: "uom is required.",
    },
    range: {
      name: "range",
      label: "Range",
      type: "text",
      errorMsg: "range is required.",
    },
   
    mapware: {
      name: "mapware",
      label: "Map ware-house",
      type: "text",
      errorMsg: " mapware is required.",
    },

   
    AssignEquipment: {
      name: "AssignEquipment",
      label: "Assign Equipment",
      type: "text",
      errorMsg: "AssignEquipment is required.",
    },
    EquipmentList: {
      name: "Equipment",
      label: "Equipment",
      type: "text",
      errorMsg: "Equipment is required.",
    },
  },
};

export default form;
