import checkout from "layouts/pages/mmucreation/new mmucreation/schemas/form";
const {
  formField: {
    MMUName,
    MMUDescription,
    VehicleAssign,
    AssignEquipment,
    mapware,
    EquipmentList,
  },
} = checkout;

const initialValues = {
  [MMUName.name]: "",
  //[MMUDescription.name]: "",
  // [EquipmentList.name]: "",

  // [AssignEquipment.name]: "",
  // [VehicleAssign.name]: "",
  // [mapware.name]: "",
};

export default initialValues;
