// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Autocomplete } from "@mui/material";
import axios from "axios";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function Address({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { phonenumber, email, state,
    district,
    subDistrict,
    pincode} = formField;
  const { phonenumber: phonenumberV } = values;
  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setdistrictValue] = useState(null);
  const [subDistrictValue, setSubDistrictValue] = useState(null);
  const [pincodeValue, setPincodeValue] = useState(null);

  const [stateList, setStateList] = useState("");
  const [districtList, setDistrictList] = useState("");
  const [subDistrictList, setSubDistrictList] = useState("");
  const [pincodeList, setPincodeList] = useState("");
  
  const { email: emailV } = values;

  const defaultPropsState = {
    options: !stateList
      ? [{ id: 0, state: { name: "Loading...", shortname: "" } }]
      : stateList,
    getOptionLabel: (option) =>
      option.state?.name + " (" + option.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsDistrict = {
    options: !districtList
      ? [{ id: 0, district: { name: "Loading...", shortname: "" } }]
      : districtList,
    getOptionLabel: (option) =>
      option.district?.name + " (" + option.district?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsSubDistrict = {
    options: !subDistrictList
      ? [{ id: 0, subdistrict: { name: "Loading...", shortname: "" } }]
      : subDistrictList,
    getOptionLabel: (option) =>
      option.subdistrict?.name + " (" + option.subdistrict?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparentpincode = {
    options: !pincodeList
      ? [{ id: 0, village: { name: "Loading..." }, pincode: "" }]
      : pincodeList,
    getOptionLabel: (option) =>
      option.village?.name + " (" + option.pincode + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  async function getDistrictData(stateCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${stateCode}`
      )
      .then((response) => {
        setDistrictList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getSubDistrictData(stateCode, districtCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&state.code=${stateCode}&district.code=${districtCode}`
      )
      .then((response) => {
        setSubDistrictList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getVillageData(stateCode, districtCode, subDistrictCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=VILLAGE&state.code=${stateCode}&district.code=${districtCode}&subdistrict.code=${subDistrictCode}`
      )
      .then((response) => {
        setPincodeList(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getData() {
   
    //state
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=STATE`
      )
      .then((response) => {
        setStateList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Additional Information
      </MDTypography>
      <MDBox mt={1.625}>
      

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={phonenumber.type}
              label={phonenumber.label}
              name={phonenumber.name}
              value={phonenumberV}
              placeholder={phonenumber.placeholder}
              error={errors.phonenumber && touched.phonenumber}
              success={phonenumberV.length > 0 && !errors.phonenumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
          </Grid>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
          
              <Autocomplete
                {...defaultPropsState}
                value={stateValue}
                onChange={(event, newValue) => {
                  setStateValue(newValue);
                  getDistrictData(newValue.state?.code);
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label={state.label} />
                )}
              />
            </Grid>
          

         
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsDistrict}
                value={districtValue}
                onChange={(event, newValue) => {
                  setdistrictValue(newValue);
                  getSubDistrictData(
                    newValue.state?.code,
                    newValue.district?.code
                  );
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={district.label}
                  />
                )}
              />
            </Grid>
            </Grid> */}
            {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsSubDistrict}
                value={subDistrictValue}
                onChange={(event, newValue) => {
                  setSubDistrictValue(newValue);
                  getVillageData(
                    newValue.state?.code,
                    newValue.district?.code,
                    newValue.subdistrict?.code
                  );
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={subDistrict.label}
                  />
                )}
              />
            </Grid>
         
       
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsparentpincode}
                value={pincodeValue}
                onChange={(event, newValue) => {
                  setPincodeValue(newValue);
                  values.pincode = newValue;
                  console.log("village", newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={pincode.label}
                  />
                )}
              />
            </Grid>
         
        </Grid> */}
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Address
Address.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Address;
