import * as React from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Autocomplete } from "@mui/material";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

//EDit button

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

function Projectlist() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});

  const [item, setItem] = useState({});
  const [open, setOpen] = React.useState(false);


  const [stateList, setStateList] = useState("");
  const [districtList, setDistrictList] = useState("");
  const [subDistrictList, setSubDistrictList] = useState("");
  const [pincodeList, setPincodeList] = useState("");
  const [parenthierachicalList, setparenthierachicalList] = useState("");
  const [warehouseList, setWarehouseList] = useState("");


  const [updateName, setUpdateName] = useState("");
  const [updateDescrpition, setUpdateDescrption] = useState("");

  const [selectstate, setSelectState] = useState("");
  const [selectdistrict, setSelectdistrict] = useState("");
  const [selectsubdistrict, setSelectsubdistrict] = useState("");
  const [selectpincode, setSelectpincode] = useState("");
  const [selectparentherical, setSelectparentherical] = useState("");
  const [selectwarehouse, setSelectwarehouse] = useState("");

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const defaultPropsState = {
    options: !stateList
      ? [{ id: 0, state: { name: "Loading...", shortname: "" } }]
      : stateList,
    getOptionLabel: (option) =>
      option.state?.name + " (" + option.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsDistrict = {
    options: !districtList
      ? [{ id: 0, district: { name: "Loading...", shortname: "" } }]
      : districtList,
    getOptionLabel: (option) =>
      option.district?.name + " (" + option.district?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsSubDistrict = {
    options: !subDistrictList
      ? [{ id: 0, subdistrict: { name: "Loading...", shortname: "" } }]
      : subDistrictList,
    getOptionLabel: (option) =>
      option.subdistrict?.name + " (" + option.subdistrict?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropspincode = {
    options: !pincodeList
      ? [{ id: 0, village: { name: "Loading..." }, pincode: "" }]
      : pincodeList,
    getOptionLabel: (option) =>
      option.village?.name + " (" + option.pincode + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparenthierachical = {
    options: !parenthierachicalList
      ? [{ name: "Loading...", id: 0 }]
      : parenthierachicalList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropswarehouse = {
    options: !warehouseList
      ? [{ name: "Loading...", id: 0 }]
      : warehouseList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  }
  async function parenthericalData() {
    //parent herical===================
    await axios
      .get(
        `project-service/api/Project/v1/get`
      )
      .then((response) => {
        setparenthierachicalList(response.data.data.dtoList);
        warehouseData(response.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function warehouseData(parenthericData) {
    console.log("137", parenthericData);
    // warehouse list------------------
    await axios
      .get(
        `wareHouseMaster-service/api/WareHouseMaster/v1/get?recStatus=OPEN`
      )
      .then((response) => {
        setWarehouseList(response.data.data.dtoList);

        stateData(parenthericData);
      })
      .catch((e) => {
        console.log("error", e);
      });

  }


  async function stateData(parenthericData) {

    //setting state===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=STATE`
      )
      .then((response) => {
        setStateList(response.data.data.dtoList);
        getData(parenthericData, response.data.data.dtoList)
        console.log("164", response.data?.data?.dtoList);


      })
      .catch((e) => {
        console.log("error", e);
      });

  }

  async function getDistrictData(stateCode) {
    console.log("176", stateCode)

    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${stateCode}`
      )
      .then((response) => {
        setDistrictList(response.data.data.dtoList);


        setLoading(false);
        return [response.data.data.dtoList]
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getSubDistrictData(stateCode, districtCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&state.code=${stateCode}&district.code=${districtCode}`
      )
      .then((response) => {
        console.log("201", response);
        setSubDistrictList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getVillageData(stateCode, districtCode, subDistrictCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=VILLAGE&state.code=${stateCode}&district.code=${districtCode}&subdistrict.code=${subDistrictCode}`
      )
      .then((response) => {
        setPincodeList(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }




  function onUpdatedata(object, parenthericData, stateData) {
    console.log("221", object);
    console.log("222", parenthericData)
    console.log("223", stateData)

    for (let i = 0; i < parenthericData.length; i++) {

      if (parenthericData[i].projecthirarichalcode === object.parentproject) {

        console.log("229", parenthericData[i])
        setSelectparentherical(parenthericData[i]);
      }
    }
    setOpen(true);
    for (let i = 0; i < stateData.length; i++) {

      if (stateData[i].state.code === object.pinCode.state.code) {

        console.log("237", stateData[i])
        setSelectState(stateData[i]);
        var statecode = stateData[i].state.code
        axios
          .get(
            `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=` + stateData[i].state.code
          )
          .then((response) => {
            setDistrictList(response.data.data.dtoList);


            setLoading(false);
            for (let i = 0; i < response.data.data.dtoList.length; i++) {

              if (response.data.data.dtoList[i].district.code === object.pinCode.district.code) {
                setSelectdistrict(response.data.data.dtoList[i]);
                var selecteddistricCode = response.data.data.dtoList[i].district.code

                axios.get(
                  `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&state.code=${statecode}&district.code=${selecteddistricCode}`
                )
                  .then((response) => {
                    setSubDistrictList(response.data.data.dtoList);

                    for (let i = 0; i < response.data.data.dtoList.length; i++) {
                      if (response.data.data.dtoList[i].subdistrict.code === object.pinCode.subdistrict.code) {
                        setSelectsubdistrict(response.data.data.dtoList[i]);
                        var selectpincodeCode = response.data.data.dtoList[i].subdistrict.code

                        axios.get(
                          `pincode-service/api/pincode/v1/get?type=VILLAGE&state.code=${statecode}&district.code=${selecteddistricCode}&subdistrict.code=${selectpincodeCode}`
                        )
                          .then((response) => {
                            setPincodeList(response.data.data.dtoList);
                            console.log("276", response);
                            for (let i = 0; i < response.data.data.dtoList.length; i++) {
                              if (response.data.data.dtoList[i].village.code === object.pinCode.village.code) {
                                setSelectpincode(response.data.data.dtoList[i]);

                               }
                             }
                          })
                          .catch((e) => {
                            console.log("error", e);
                          });

                      }
                    }
                  })
                  .catch((e) => {
                    console.log("error", e);
                  });
              }
            }

          })
          .catch((e) => {
            console.log("error", e);
          });



      }
    }
  

    setSelectwarehouse(object.warehouse);
         setItem(object);
        setUpdateName(object.name);
    setUpdateDescrption(object.desc);
              }


  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function onDelete(item) {
    if (
      window.confirm(
        "Are you sure that you want to delete that Project " + item.name
      )
    )
      await axios
        .put(
          'project-service/api/Project/v1/delete',
          item
        )
        .then((response) => {
          console.log(response);
          parenthericalData();
        })
        .catch((error) => {
          console.log(error);
        });
  }
  async function updateData() {
    item.name = updateName;
    item.desc = updateDescrpition;

    item.modifiedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;
    delete item.action;
    await axios
      .put(
        'project-service/api/Project/v1/update',
        item
      )
      .then((response) => {
        setOpen(false);
        parenthericalData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData(parenthericData, stateData) {
    console.log("282", parenthericData);


    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Name", accessor: "name" },
        { Header: "Description", accessor: "desc" },
        { Header: "Code", accessor: "code" },
        { Header: "Project Heirarchy", accessor: "projecthirarichalcode", },
        { Header: "warehouse", accessor: "warehouse.name" },
        {
          Header: "Address", accessor: "dynamicAddress",
        },
        {
          Header: "Created By", accessor: "createdby",
        },
        { Header: "action", accessor: "action" },
      ],
      rows: [],
    };
    axios
      .get(
        `project-service/api/Project/v1/get`
      )
      .then((response) => {
        response.data.data.dtoList.map((item) => {
          console.log("392",item)
          item.action = (
            <span>
              <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item, parenthericData, stateData);
                  }}
                />
              </Link>

              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          item.dynamicAddress =
            item.pinCode?.village?.name +
            " (" +
            item.pinCode?.pincode +
            "), " +
            item.pinCode?.subdistrict?.name +
            ", " +
            item.pinCode?.district?.name +
            ", " +
            item.pinCode?.state?.name;
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    parenthericalData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Project Table
                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/page/component/create-project"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Project</DialogTitle>
        <DialogContent style={{ width: 550, height: 530 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label=" Name"
              variant="standard"
              fullWidth
              value={updateName}
              onChange={(e) => setUpdateName(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Descrpition"
              variant="standard"
              fullWidth
              value={updateDescrpition}
              onChange={(e) => setUpdateDescrption(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropsparenthierachical}
              value={selectparentherical}
              onChange={(event, newValue) => {
                // handleChange(event);
                //item.type = newValue.name;

                setSelectparentherical(newValue);
                item.code=newValue?.code;
                item.projecthirarichalcode=newValue?.projecthirarichalcode;
                console.log("442", selectparentherical);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="parenthierachical" />
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropswarehouse}
              value={selectwarehouse}
              onChange={(event, newValue) => {
                // handleChange(event);


                setSelectwarehouse(newValue);
                item.warehouse = newValue;

                console.log("459", item.warehouse.name);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="warehouse" />
              )}
            />
          </MDBox>

          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropsState}
              value={selectstate}
              onChange={(event, newValue) => {
              

                setSelectState(newValue);
                getDistrictData(newValue.state?.code);
                item.pinCode.state.name=newValue.state.name;
                item.pinCode.state.code=newValue.state.code;
                item.pinCode.state.shortname=newValue.state.shortname;
                
                console.log("321", selectstate);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="State" />
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropsDistrict}
              value={selectdistrict}
              onChange={(event, newValue) => {
                // handleChange(event);
                //item.type = newValue.name;

                setSelectdistrict(newValue);
                getSubDistrictData(
                  newValue.state?.code,
                  newValue.district?.code
                );
               item.pinCode.district.name=newValue.district.name;
               item.pinCode.district.code=newValue.district.code;
               item.pinCode.district.shortname=newValue.district.shortname;
                console.log("339", selectdistrict);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="Distric" />
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropsSubDistrict}
              value={selectsubdistrict}
              onChange={(event, newValue) => {
                // handleChange(event);
               

                setSelectsubdistrict(newValue);
                getVillageData(
                  newValue.state?.code,
                  newValue.district?.code,
                  newValue.subdistrict?.code
                );

                item.pinCode.subdistrict.name=newValue.subdistrict.name;
                item.pinCode.subdistrict.code=newValue.subdistrict.code;
                item.pinCode.subdistrict.shortname=newValue.subdistrict.shortname;
                console.log("405", selectsubdistrict);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="subdistic" />
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropspincode}
              value={selectpincode}
              onChange={(event, newValue) => {
                // handleChange(event);
                // item.type = newValue.name;

                setSelectpincode(newValue);
                item.pinCode.village.name=newValue.village.name;
                item.pinCode.village.code=newValue.village.code;
                item.pinCode.village.shortname=newValue.village.shortname;
                console.log("424", selectpincode);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="pincode" />
              )}
            />
          </MDBox>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData}>Update</Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default Projectlist;

