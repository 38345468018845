
import { combineReducers, legacy_createStore as createStore, applyMiddleware } from
    "redux"
import thunk from "redux-thunk"
import AuthReducer from "./Reducers/AuthReducer"
import { composeWithDevTools } from 'redux-devtools-extension'

const store = createStore(
    combineReducers({AuthReducer}),
    composeWithDevTools(applyMiddleware(thunk))
)

export default store