import * as Yup from "yup";
import checkout from "layouts/pages/Test/new test/schemas/form";

const {
  formField: {
    name,
    testname,
    investigationname,
    range,
    AssignEquipment,
    EquipmentList,
    mapware,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
    [testname.name]: Yup.string().required(testname.errorMsg),
    // [range.name]: Yup.string().required(range.errorMsg),
    // [AssignEquipment.name]: Yup.string().required(AssignEquipment.errorMsg),
    [investigationname.name]: Yup.string().required(investigationname.errorMsg),
    // [mapware.name]: Yup.string().required(mapware.errorMsg),
  }),
];

export default validations;
