import * as Yup from "yup";
import checkout from "layouts/pages/Projectteam/new Projectteam/schemas/form";

const {
  formField: { designation},
} = checkout;

const validations = [
  Yup.object().shape({
    
    // [createproject.name]: Yup.string().required(createproject.errorMsg),
    // [designation.name]: Yup.string().required(designation.errorMsg),
   
   


  }),
];

export default validations;
