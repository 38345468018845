import * as Yup from "yup";
import checkout from "layouts/pages/role/new-role/schemas/form";
const {
  formField:{roleName, roleDescrption},
} = checkout;

const validations = [
  Yup.object().shape({
    [roleName.name]: Yup.string().required(roleName.errorMsg),
    [roleDescrption.name]: Yup.string().required(roleDescrption.errorMsg),
  }),
];

export default validations;
