import * as Yup from "yup";
import checkout from "layouts/pages/SubDistrictInfo/schemas/form";

const {
  formField: {
    state,
    district,
    subDistrictCode,
    shortName,
    subDistrictName,
    pincode,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    // [state.name]: Yup.string().required(state.errorMsg),
    // [district.name]: Yup.string().required(district.errorMsg),
    [subDistrictCode.name]: Yup.string().required(subDistrictCode.errorMsg),
    [shortName.name]: Yup.string().required(shortName.errorMsg),
    [subDistrictName.name]: Yup.string().required(subDistrictName.errorMsg),
  }),
];

export default validations;
