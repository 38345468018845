import React from 'react'
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

const Powerbi = () => {
  return (
    <>
  {/* <PowerBIEmbed
	embedConfig = {{
		type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
		id: undefined,
        embedUrl: undefined,
		accessToken: undefined,    // Keep as empty string, null or undefined
		tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
		settings: {
			panes: {
				filters: {
					expanded: false,
					visible: false
				}
			},
			background: models.BackgroundType.Transparent,
		}
	}}

	eventHandlers = {
		new Map([
			['loaded', function () {console.log('Report loaded');}],
			['rendered', function () {console.log('Report rendered');}],
			['error', function (event) {console.log(event.detail);}],
			['visualClicked', () => console.log('visual clicked')],
			['pageChanged', (event) => console.log(event)],
		])
	}

	cssClassName = { "reportClass" }

	getEmbeddedComponent = { (embeddedReport) => {
		window.report = embeddedReport;
	}}
/> */}
{/* <iframe title="test" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=6293f290-a0bf-43ab-950b-f40d6d0f0c11&autoAuth=true&ctid=bbfff6bb-06a6-4452-8426-33a4fd606cca" frameborder="0" allowFullScreen="true"></iframe> */}
{/* <iframe title="Mobile Medical Unit" width="900" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=9b6edc61-c715-44ef-9b85-d32fd6d4189f&autoAuth=true&ctid=4c77f23d-5730-4c05-b9fa-2f43a3ff20df" frameborder="0" allowFullScreen="true"></iframe> */}
<iframe title="MMUOperational" width="900" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=1e896255-8de1-44b7-a5e9-4b330719a9fd&autoAuth=true&ctid=4c77f23d-5730-4c05-b9fa-2f43a3ff20df" frameborder="0" allowFullScreen="true"></iframe>
{/* <PowerBIEmbed
	embedConfig = {{
		type: 'report',   // Supported types: report, dashboard, tile, visual, qna and paginated report
		id: undefined,
		embedUrl: undefined,
		accessToken: undefined,    // Keep as empty string, null or undefined
		tokenType: models.TokenType.Embed
	}}
/> */}
   
    </>
  )
}

export default Powerbi