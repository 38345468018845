import * as Yup from "yup";
import checkout from "layouts/pages/Disease/new-disease/schemas/form";
const {
  formField:{ diseaseName, diseaseDescrption,diseasetype,Categorytype},
} = checkout;

const validations = [
  Yup.object().shape({
    [diseaseName.name]: Yup.string().required(diseaseName.errorMsg),
    [diseaseDescrption.name]: Yup.string().required(diseaseDescrption.errorMsg),
    [diseasetype.name]: Yup.string().required(diseasetype.errorMsg),
    // [Categorytype.name]: Yup.string().required(Categorytype.errorMsg),
 
  }),
];

export default validations;
