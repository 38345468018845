import * as Yup from "yup";

import checkout from "layouts/pages/item/new item/schemas/form";
const {
  formField: { itemtype, sapcode, uom },
} = checkout;

const validations = [
  Yup.object().shape({
    // [itemame.name]: Yup.string().required(itemame.errorMsg),
    [itemtype.name]: Yup.string().required(itemtype.errorMsg),
    [sapcode.name]: Yup.string().required(sapcode.errorMsg),
    [uom.name]: Yup.string().required(uom.errorMsg),
  }),
];

export default validations;
