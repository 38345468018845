import * as Yup from "yup";

import checkout from "layouts/pages/stock/new stock/schemas/form";
const {
  formField: {
    MRNumber,
    WHID,
    flowType,
    reqLineItemId,
    mmuCode,
    PONumber,
    uom,
    quantity,
    quantityRqst,
    MMUItemCode,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [MRNumber.name]: Yup.string().required(MRNumber.errorMsg),
    [WHID.name]: Yup.string().required(WHID.errorMsg),
    [flowType.name]: Yup.string().required(flowType.errorMsg),
    [reqLineItemId.name]: Yup.string().required(reqLineItemId.errorMsg),
    [mmuCode.name]: Yup.string().required(mmuCode.errorMsg),
    [PONumber.name]: Yup.string().required(PONumber.errorMsg),
    [uom.name]: Yup.string().required(uom.errorMsg),
    [quantity.name]: Yup.string().required(quantity.errorMsg),
    [quantityRqst.name]: Yup.string().required(quantityRqst.errorMsg),
    [MMUItemCode.name]: Yup.string().required(MMUItemCode.errorMsg),
  }),
];

export default validations;
