const form = {
  formId: "new Requsition-form",
  formField: {
    type: {
      name: "type",
      label: "Type",
      type: "text",
      errorMsg: "type is required.",
    },
    itemtype: {
      name: "itemtype",
      label: "Itemtype",
      type: "text",
      errorMsg: "itemtype is reqired.",
    },

    WHID: {
      name: "WHID",
      label: "WareHouse",
      type: "text",
      errorMsg: "WareHouse is required.",
    },
    flowType: {
      name: "flowType",
      label: "FlowType",
      type: "text",
      errorMsg: "flowType is required.",
    },
    reqLineItemId: {
      name: "reqLineItemId",
      label: "Select Item",
      type: "text",
      errorMsg: "Item is required.",
    },

    mmuCode: {
      name: "mmuCode",
      label: "Select MMU",
      type: "text",
      errorMsg: "mmuCode address is required.",
    },
    PONumber: {
      name: "PONumber",
      label: "Purchase Order Number",
      type: "text",
      errorMsg: "PONumber is required.",
    },
    uom: {
      name: "uom",
      label: "uom",
      type: "text",
      errorMsg: "uom is required.",
    },

    quantity: {
      name: "quantity",
      label: "Quantity",
      type: "number",
      errorMsg: "quantity is required.",
    },

    //Med-requisition========

    // quantityRqst: {
    //   name: "quantityRqst",
    //   label: "Quantity",
    //   type: "number",
    //   errorMsg: "quantity is required.",
    // },
    MMUItemCode: {
      name: "MMUItemCode",
      label: "Select Item",
      type: "text",
      errorMsg: "Item is required.",
    },
  },
};

export default form;
