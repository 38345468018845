/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-lab-form",
  formField: {
    shortname: {
      name: "shortname",
      label: "Short Name",
      type: "text",
      errorMsg: "shortname is required.",
    },
    state: {
      name: "state",
      label: "State",
      type: "text",
      errorMsg: "State is required.",
    },
    districCode: {
      name: "districCode",
      label: "District Code",
      type: "number",
      errorMsg: "districCode is required.",
    },
    districtname: {
      name: "districtname",
      label: "District Name",
      type: "text",
      errorMsg: "districtname is required.",
    },
  },
};

export default form;
