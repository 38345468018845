import { useEffect, useState } from "react";

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { Autocomplete } from "@mui/material";
import axios from "axios";
// @mui material components
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";

const initialValues = {
    state: "",
    district: "",
    subDistrict: "",
    villageName: "",
    shortname: "",
    villageCode: "",
    pincode: ""
}

const Pincodecomponent = (props) => {
    const { tittle, children, childTopincode, openpopuppincode, setopenpopuppincode } = props
    const [stateList, setStateList] = useState("");
    const [districtList, setDistrictList] = useState("");
    const [subDistrictList, setSubDistrictList] = useState("");
    const [stateValue, setStateValue] = useState(null);
    const [districtValue, setDistrictValue] = useState(null);
    const [subDistrictValue, setSubDistrictValue] = useState(null);
    function autocompleteValuenull(){
      
        setStateValue(null);
        setDistrictValue(null);
        setSubDistrictValue(null);
          }

    async function createpincode(pinCodeObject) {
        axios
            .post(
                "pincode-service/api/pincode/v1/create",
                pinCodeObject
            )

            .then((response) => {
                console.log("gfgfgf", response);

                setopenpopuppincode(false)
                autocompleteValuenull()
                if (response) {
                    console.log("40", response.data.data.dto)
                    childTopincode(response.data.data.dto)

                }




            })
            .catch((error) => {
                console.log(error);


            });

    }


    const defaultPropsState = {
        options: !stateList
            ? [
                {
                    state: {
                        shortname: "- -",
                        name: "Loading...",
                    },
                    id: 0,
                },
            ]
            : stateList,
        getOptionLabel: (option) =>
            option.state?.name + " (" + option.state?.shortname + ")",
        isOptionEqualToValue: (option, value) => option.id === value.id,
    };

    const districtDefaultProp = {
        options: !districtList
            ? [
                {
                    district: {
                        shortname: "- -",
                        name: "Loading...",
                    },
                    id: 0,
                },
            ]
            : districtList,

        getOptionLabel: (option) =>
            option.district?.name + " (" + option.district?.shortname + ")",
        isOptionEqualToValue: (option, value) => option.id === value.id,
    };
    const subDistrictDefaultProp = {
        options: !subDistrictList
            ? [
                {
                    subdistrict: {
                        shortname: "- -",
                        name: "Loading...",
                    },
                    id: 0,
                },
            ]
            : subDistrictList,

        getOptionLabel: (option) =>
            option.subdistrict?.name + " (" + option.subdistrict?.shortname + ")",
        isOptionEqualToValue: (option, value) => option.id === value.id,
    };


    const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, action) => {
            console.log("91", values)
            const pinCodeObject = {
                id: null,
                createdby:
                    JSON.parse(localStorage.getItem("mmu-auth")).firstname +
                    " " +
                    JSON.parse(localStorage.getItem("mmu-auth")).lastname,

                modifiedby: "",
                approvedby: "",

                recstatus: "OPEN",
                syncstatus: "synced",
                status: "ACTIVE",
                documentofproof: "",

                tempid: "",
                state: values.state,
                district: values.district,
                subdistrict: values.subDistrict,
                village: {
                    code: values.villageCode,
                    shortname: values.shortname,
                    name: values.villageName,
                },
                pincode: values.pincode,

                resourcecode: "DPF",
                type: "VILLAGE",
            };

            createpincode(pinCodeObject)
            swal("Alert!", "...your Pincode has been sucessfully create!", "success")
                .then((value) => {
                    // window.location.reload(true)

                    action.resetForm();

                });

        }
    })





    const handleClose = () => {
        console.log("dsfsdfs");
        setopenpopuppincode(false);
    };

    async function getDistrictData(stateCode) {
        await axios
            .get(
                `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${stateCode}`
            )
            .then((response) => {
                setDistrictList(response.data.data.dtoList);

            })
            .catch((e) => {
                console.log("error", e);
            });
    }
    async function getSubDistrictData(districtCode) {
        await axios
            .get(
                `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&district.code=${districtCode}`
            )
            .then((response) => {
                setSubDistrictList(response.data.data.dtoList);

            })
            .catch((e) => {
                console.log("error", e);
            });
    }
    async function getData() {
        await axios
            .get(
                `pincode-service/api/pincode/v1/get?type=state`
            )
            .then((response) => {
                setStateList(response.data.data.dtoList);

            })

            .catch((e) => {
                console.log("error", e);
            });
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Dialog open={openpopuppincode} onClose={handleClose} >
            <MDBox mx={2} mt={2}>

                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-2}
                    p={2}
                    mb={-2}
                    textAlign="center"
                >
                    <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                    >
                        Pincode
                    </MDTypography>
                </MDBox>
            </MDBox>
            <form onSubmit={handleSubmit}>
                <DialogContent style={{ width: 550, height: 260 }}>
                    <Grid container spacing={3} mt={.5}>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                {...defaultPropsState}
                                value={stateValue}
                                onChange={(event, newValue) => {
                                    // handleChange(event);
                                    setStateValue(newValue);
                                    // setValue(newValue);
                                    getDistrictData(newValue?.state?.code);
                                    values.state = newValue?.state;
                                    console.log(newValue);
                                }}
                                renderInput={(params) => (
                                    <MDInput
                                        {...params}

                                        type="text"
                                        autocomplete="off"
                                        name="state"
                                        label="State"
                                        id="state"

                                        onChange={handleChange}
                                        onBlur={handleBlur}

                                        variant="standard"



                                    />
                                )}
                            />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                {...districtDefaultProp}
                                value={districtValue}
                                onChange={(event, newValue) => {
                                    setDistrictValue(newValue);
                                    getSubDistrictData(newValue?.district?.code);
                                    values.district = newValue.district;
                                    console.log(newValue);
                                }}
                                renderInput={(params) => (
                                    <MDInput
                                        {...params}
                                        type="text"
                                        autocomplete="off"
                                        name="distric"

                                        id="distric"

                                        onChange={handleChange}
                                        onBlur={handleBlur}

                                        variant="standard"


                                        label="District"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} mt={.5}>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                {...subDistrictDefaultProp}
                                value={subDistrictValue}
                                onChange={(event, newValue) => {
                                    // handleChange(event);
                                    setSubDistrictValue(newValue);
                                    // setValue(newValue);

                                    values.subDistrict = newValue?.subdistrict;
                                    console.log(newValue);
                                }}
                                renderInput={(params) => (
                                    <MDInput
                                        {...params}
                                        type="text"
                                        autocomplete="off"
                                        name="subDistrict"

                                        id="subDistrict"

                                        onChange={handleChange}
                                        onBlur={handleBlur}

                                        variant="standard"

                                        label="SubDistrict"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MDInput
                                type="text"
                                autocomplete="off"
                                name="villageName"
                                label="VillageName"
                                id="villageName"
                                variant="standard"
                                fullWidth
                                value={values.villageName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} mt={.5}>
                            <MDInput
                                type="text"
                                autocomplete="off"
                                name="shortname"
                                label="Shortname"
                                id="shortname"
                                variant="standard"
                                fullWidth
                                value={values.shortname}
                                onChange={handleChange}
                                onBlur={handleBlur}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MDInput
                                type="text"
                                autocomplete="off"
                                name="villageCode"
                                label="VillageCode"
                                id="villageCode"
                                variant="standard"
                                fullWidth
                                value={values.villageCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <MDInput
                                type="number"
                                autocomplete="off"
                                name="pincode"
                                label="Pincode"
                                id="pincode"
                                variant="standard"
                                fullWidth
                                value={values.pincode}
                                onChange={handleChange}
                                onBlur={handleBlur}

                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">create </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}













export default Pincodecomponent;