import * as Yup from "yup";
import checkout from "layouts/pages/vitals/new-vitals/schemas/form";

const {
  formField: { vitalsName, vitalsType,vitalrange,uom},
} = checkout;

const validations = [
  Yup.object().shape({
    [vitalsName.name]: Yup.string().required(vitalsName.errorMsg),
    [vitalsType.name]: Yup.string().required(vitalsType.errorMsg),
    [vitalrange.name]: Yup.string().required(vitalrange.errorMsg)
    .min(1, vitalrange.invalidMsg).max(100, vitalrange.invalidMsg),
    [uom.name]: Yup.string().required(uom.errorMsg),
 
 
  }),
];

export default validations;
