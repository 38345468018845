   /**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import { useEffect, useState } from "react";
import axios from "axios";

function Analytics() {
  const { sales, tasks, patient, prescription } = reportsLineChartData;
  const [barData, setBarData] = useState(reportsBarChartData);
  const [patientData, setPatientData] = useState(patient);
  const [presData, setPresData] = useState(prescription);
  const [totalVisits, setTotalVisits] = useState(0);
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalPres, setTotalPres] = useState(0);
  const [projectDetail, setProjectDetail] = useState(localStorage.getItem("project-Detail"));
  const [mmuList, setMmuList] = useState(projectDetail ? JSON.parse(projectDetail).mmulist : [])
  const [projectList, setProjectList] = useState(projectDetail ? JSON.parse(projectDetail).projectlist : [])

  const [engagementList, setEngagementList] = useState();


  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography
          variant="body1"
          color="info"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  async function getEngagementData(projectCode) {
    await axios
      .get(`/visualization-service/api/visualization/v1//project/getTotalPatients?projectCodeList=${projectCode}`)
      .then((response) => {
        console.log("93", response.data);
        setEngagementList(response.data);


      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getVisitCount(mmucode) {
    await axios
      .get(`/visualization-service/api/visualization/v1/getTotalVisit?mmuCodeList=${mmucode}`)
      .then((response) => {
        console.log("106", response.data);
        setTotalVisits(response.data);


      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getBarData(mmucode) {
    await axios
      .get(`/visualization-service/api/visualization/v1/getCoverage?template=BARCHART001&mmuCodeList=${mmucode}`)
      .then((response) => {
        console.log("119", response.data);
        setBarData(response.data);


      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getPatientData(mmucode) {
    await axios
      .get(`/visualization-service/api/visualization/v1/getLine?template=LINECHART001&mmuCodeList=${mmucode}&type=Patient`)
      .then((response) => {
        console.log("132", response.data);
        setPatientData(response.data);
        var totalCount = 0;
        response.data?.datasets?.data.map((count) => {
          totalCount = totalCount + parseInt(count, 10);
        })
        setTotalPatients(totalCount);

      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getPresData(mmucode) {
    await axios
      .get(`/visualization-service/api/visualization/v1/getLine?template=LINECHART002&mmuCodeList=${mmucode}&type=Prescription`)
      .then((response) => {
        console.log("149", response.data);
        setPresData(response.data);
        var totalCount = 0;
        response.data?.datasets?.data.map((count) => {
          totalCount = totalCount + parseInt(count, 10);
        })
        setTotalPres(totalCount);

      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  function getMmuCode() {
    var mmucode = ""
    var projectCode = ""
    // if(mmuList.length > 0)
    mmuList.map((item) => {
      if (mmucode === "") {
        mmucode = mmucode + item.code;
      } else {
        mmucode = mmucode + "," + item.code;
      }

    })
    projectList.map((item) => {
      if (projectCode === "") {
        projectCode = projectCode + item.code;
      } else {
        projectCode = projectCode + "," + item.code;
      }

    })
    console.log("144", projectCode);
    getEngagementData(projectCode)
    getBarData(mmucode);
    getPatientData(mmucode);
    getPresData(mmucode);
    getVisitCount(mmucode);
    
  }

  useEffect(() => {
    getMmuCode();

    console.log("138", mmuList);

  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
      
        <Grid container >
          <SalesByCountry salesTable={engagementList} />
        </Grid>
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="village coverage"
                  description="Campaign Performance"
                  date="just updated"
                  chart={barData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="patient"
                  description="Campaign Performance"
                  // description={
                  //   <>
                  //     (<strong>+15%</strong>) increase in today coverage.
                  //   </>
                  // }
                  date="just updated"
                  chart={patientData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="prescription"
                  description="Campaign Performance"
                  date="just updated"
                  chart={presData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Total Visits"
                  count={totalVisits}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                // percentage={{
                //   color: "success",
                //   amount: "+55%",
                //   label: "than lask week",
                // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="person_add"
                  title="Total Patients"
                  count={totalPatients}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="leaderboard"
                  // icon="store"
                  title="Total Prescription"
                  count={totalPres}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Followers"
                  count="+91"
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid> */}
          </Grid>
        </MDBox>
        {/*
          <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking1}
                  title="Cozy 5 Stars Apartment"
                  description='The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona.'
                  price="Rs899/night"
                  location="Barcelona, Spain"
                  action={actionButtons}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking2}
                  title="Office Studio"
                  description='The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the night life in London, UK.'
                  price="$1.119/night"
                  location="London, UK"
                  action={actionButtons}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking3}
                  title="Beautiful Castle"
                  description='The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Milan.'
                  price="$459/night"
                  location="Milan, Italy"
                  action={actionButtons}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
                */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;

