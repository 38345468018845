import * as Yup from "yup";
import checkout from "layouts/pages/Designation/new-designation/schemas/form";

const {
  formField: { designationName, designationDescription, department,designation  },
} = checkout;

const validations = [
  Yup.object().shape({
    [designationName.name]: Yup.string().required(designationName.errorMsg),
    [designationDescription.name]: Yup.string().required(designationDescription.errorMsg),
  [department.name]: Yup.string().required(department.errorMsg),
  // [designation.name]: Yup.string().required(designation.errorMsg),
 
  }),
];

export default validations;
