import React from 'react'

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import { Autocomplete } from "@mui/material";


import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

const Typelist = () => {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [item, setItem] = useState({});
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const [Updatename, setUpdatename] = useState("");
  const [selectcategory, setSelectCategory] = useState("");
  const [selectcategorytype, setSelectCategoryType] = useState("");
  const [categoryList, setCategoryList] = useState("");
  const [categorytypeList, setCategorytypeList] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const defaultPropsCategory = {
    options: !categoryList
      ? [
          { name: "ITEM", code: "A" },
          { name: "VITAL", code: "B" },
          { name: "COMPDOC", code: "C" },
          { name: "OCCUPATION", code: "D" },
          { name: "EDUCATION", code: "E" },
        ]
      : categoryList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  const defaultPropsCategorytype = {
    options: !categorytypeList
      ? [
          { name: "OPTIONS", code: "x" },
          { name: "ADMIN_OPTIONS", code: "y" },
        ]
      : categorytypeList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  async function getCategoryTypeData() {
  await axios
      .get(
        `category-service/api/Category/v1/get?type=CATEGORY_TYPE`
      )
      .then((response) => {
        getCategoryData(response.data?.data?.dtoList);
        setCategorytypeList(response.data?.data?.dtoList);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getCategoryData(CategorytypeIdlist) {
  
    // getting category
    await axios
      .get(
        `category-service/api/Category/v1/get?type=CATEGORY`
      )
      .then((response) => {
        setCategoryList(response.data?.data?.dtoList);
        getData(CategorytypeIdlist, response.data?.data?.dtoList);
      })

      .catch((e) => {
        console.log("error", e);
      });
    }
  async function onUpdatedata(object,CategorytypeIdlist,CategoryIdlist) {
    console.log("116",CategorytypeIdlist);
    console.log("117",CategoryIdlist);
    for (let i = 0; i < CategorytypeIdlist.length; i++) {

      if (CategorytypeIdlist[i].name === object.categorytype) {
       
        setSelectCategoryType(CategorytypeIdlist[i]);
      }
    }
    for (let i = 0; i < CategoryIdlist.length; i++) {
      if (CategoryIdlist[i].name === object.category) {
        console.log("118", CategoryIdlist[i])
        setSelectCategory(CategoryIdlist[i]);
      }
    }
  
    setItem(object)
    setUpdatename(object.name);
    setDescription(object.description);
    
    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function onDelete(item) {
    if (window.confirm("Are you sure that you want to delete that Type record"))
      await axios
        .put(
          "typemaster-service/api/TypeMaster/v1/delete",
          item
        )
        .then((response) => {
          console.log(response);
          getData();
        })
        .catch((error) => {
          console.log(error);
        });
  }



  async function updateData() {

    item.name = Updatename;
    item.description = description;
    delete item.action
    await axios
      .put(
        "typemaster-service/api/TypeMaster/v1/update",
        item
      )
      .then((response) => {

        setOpen(false);
        getCategoryTypeData();
      })
      .catch((error) => {
        console.log(error);
      });

  }



  function getData(CategorytypeIdlist,CategoryIdlist) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Name", accessor: "name", width: "25%" },
        { Header: "Type Description", accessor: "description" },
        { Header: "Category", accessor: "category" },
        { Header: "Category Type", accessor: "categorytype" },
       
        { Header: "action", accessor: "action", width: "12%" },
      ],
      rows: [],
    };
    axios
      .get(
        `typemaster-service/api/TypeMaster/v1/get`
      )
      .then((response) => {
        response.data.data.dtoList.map((item) => {

          item.action = (
            <span>
              <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item,CategorytypeIdlist,CategoryIdlist);
                  }}
                />
              </Link>
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />

              </Link>

            </span>
          );
          dataObj.rows.push(item);
        });

        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getCategoryTypeData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Type List

                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/page/component/create-type"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>



      <Dialog open={open} onClose={handleClose}>
        <DialogTitle >Update Type List</DialogTitle>
        <DialogContent style={{ width: 550, height: 250 }}>
          <MDBox mb={2}>

            <MDInput
              type="text"
              label="Type Name"
              variant="standard"
              fullWidth
              value={Updatename}
              onChange={(e) => setUpdatename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Vitals Type"
              variant="standard"
              fullWidth
              value={description}    
              onChange={(e) => setDescription(e.target.value)}

            />

          </MDBox>
          <MDBox mb={2}>
          <Autocomplete
            {...defaultPropsCategory}
            value={selectcategory}
            onChange={(event, newValue) => {
              // handleChange(event);
           

              setSelectCategory(newValue);
              item.category = newValue.name;
             

            }}
            renderInput={(params) => (
              <MDInput
                {...params}

                variant="standard"
                label="Category"
              />
            )}
          />
            </MDBox>
            <MDBox mb={2}>
          <Autocomplete
            {...defaultPropsCategorytype}
            value={selectcategorytype}
            onChange={(event, newValue) => {
              // handleChange(event);
           

              setSelectCategoryType(newValue);
              item.categorytype = newValue.name;
             

            }}
            renderInput={(params) => (
              <MDInput
                {...params}

                variant="standard"
                label="Category Type"
              />
            )}
          />
            </MDBox>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!Updatename}>Update</Button>
        </DialogActions>
      </Dialog>


      <Footer />
    </DashboardLayout>
  );
}



export default Typelist