/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-lab-form",
  formField: {
    testName: {
      name: "testName",
      label: "Test Name",
      type: "text",
      errorMsg: "Test name is required.",
    },
    testResult: {
      name: "testResult",
      label: "Test Result",
      type: "text",
      errorMsg: "Test result is required.",
    },
    presId: {
      name: "presId",
      label: "Prescription Id",
      type: "text",
      errorMsg: "Prescription is required.",
    },
    mmu: {
      name: "mmu",
      label: "MMU",
      type: "text",
      errorMsg: "MMU is required.",
    },
    detailResult: {
      name: "detailResult",
      label: "Detail Result",
      type: "text",
      errorMsg: "DetailResult is required.",
    },
  },
};

export default form;
