import React from 'react'

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";




import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete } from '@mui/material';


const Diseaselist = () => {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [item, setItem] = useState({});
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const [Updatename, setUpdatename] = useState("");

  const [selectitem, setSelectItem] = useState(null);
  const [diseaseList, setDiseaseList] = useState("");

  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const defaultPropsDisease = {
    options: !diseaseList
      ? [
        { name: "Communicable Disease", code: "X" },
        { name: "Non-Communicable Disease", code: "y" },
      ]
      : diseaseList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  async function onUpdatedata(object) {
    console.log("75",object);
    setSelectItem({ name: object.type, code: object.code})
    console.log("76",defaultPropsDisease);
        //  setDiseaseList(diseaseList);
   
    
    // for (let i=0;i<disease.length;i++){

    //   if(disease[i].name===object.type){
    //     console.log("900",disease[i])
    //     setSelectItem(disease[i]);
    //   }
    // }
    setItem(object)

    setUpdatename(object.name);
    setDescription(object.description);

    
    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function onDelete(item) {
    if (window.confirm("Are you sure that you want to delete that Disease record"))
      await axios
        .put(
          "disease-service/api/Disease/v1/delete",
          item
        )
        .then((response) => {
          console.log(response);
          getData();
        })
        .catch((error) => {
          console.log(error);
        });
  }



  async function updateData() {

    item.name = Updatename;
    item.description = description;
    item.type = selectitem.name
    await axios
      .put(
        "disease-service/api/Disease/v1/update",
        item
      )
      .then((response) => {

        setOpen(false);
        getData();
      })
      .catch((error) => {
        console.log(error);
      });

  }



  function getData() {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Disease Name", accessor: "name", width: "25%" },
        { Header: "Disease Description", accessor: "description" },
        { Header: "Disease Type", accessor: "type" },
        // { Header: "Category Type", accessor: "categorytype" },

        { Header: "action", accessor: "action", width: "12%" },
      ],
      rows: [],
    };
    axios
      .get(
        `disease-service/api/Disease/v1/get`
      )
      .then((response) => {
        response.data.data.dtoList.map((item) => {

          item.action = (
            <span>
              <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item);
                  }}
                />
              </Link>
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />

              </Link>

            </span>
          );
          dataObj.rows.push(item);
        });

        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Disease List

                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/page/component/create-disease"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>



      <Dialog open={open} onClose={handleClose}>
        <DialogTitle >Update Disease List</DialogTitle>
        <DialogContent style={{ width: 550, height: 180 }}>
          <MDBox mb={2}>

            <MDInput
              type="text"
              label="Disease  Name"
              variant="standard"
              fullWidth
              value={Updatename}
              onChange={(e) => setUpdatename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Disease  Description"
              variant="standard"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}

            />

          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropsDisease}
              value={selectitem}
              onChange={(event, newValue) => {
                // handleChange(event);
                item.type = newValue.name;

                setSelectItem(newValue);
                console.log("281", selectitem)

              }}
              renderInput={(params) => (
                <MDInput
                  {...params}

                  variant="standard"
                  label="Disease Type"
                />
              )}
            />

          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!Updatename}>Update</Button>
        </DialogActions>
      </Dialog>


      <Footer />
    </DashboardLayout>
  );
}





export default Diseaselist