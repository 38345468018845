import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, TextField } from "@mui/material";

const VillageList = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [color] = useState("#344767");

  const [item, setItem] = useState({});
 
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [stateList, setStateList] = useState("");
  const [districtList, setDistrictList] = useState("");
  const [subDistrictList, setSubDistrictList] = useState("");
  const [subDistrictValue, setSubDistrictValue] = useState(null);
  const [villagename, setVillagename] = useState("");
  const [testname, setTestname] = useState("");
  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setDistrictValue] = useState(null);
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  async function onUpdatedata(object) {
    setItem(object);

    setVillagename(object.village.name);
    
    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };
  const dataObj = {
    columns: [
      { Header: "Village name", accessor: "village.name", width: "25%" },
      { Header: "short name", accessor: "village.shortname" },
      { Header: "code", accessor: "village.code" },
      { Header: "pincode", accessor: "pincode" },
      {
        Header: "sub-district",
        accessor: "subDistrictFullname",
        width: "15%",
      },
      {
        Header: "district",
        accessor: "districtFullname",
        width: "15%",
      },
      {
        Header: "state",
        accessor: "stateFullname",
        width: "15%",
      },
      { Header: "action", accessor: "action", width: "12%" },
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  const defaultPropsState = {
    options: !stateList
      ? [
          {
            state: {
              shortname: "- -",
              name: "Loading...",
            },
            id: 0,
          },
        ]
      : stateList,
    getOptionLabel: (option) =>
      option.state?.name + " (" + option.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const districtDefaultProp = {
    options: !districtList
      ? [
          {
            district: {
              shortname: "- -",
              name: "Loading...",
            },
            id: 0,
          },
        ]
      : districtList,

    getOptionLabel: (option) =>
      option.district?.name + " (" + option.district?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const subDistrictDefaultProp = {
    options: !subDistrictList
      ? [
          {
            subdistrict: {
              shortname: "- -",
              name: "Loading...",
            },
            id: 0,
          },
        ]
      : subDistrictList,

    getOptionLabel: (option) =>
      option.subdistrict?.name + " (" + option.subdistrict?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

 

  async function onDelete(item) {
    if (
      window.confirm("Are you sure that you want to delete that user record")
    ) {
      await axios
        .put(
          "pincode-service/api/pincode/v1/delete",
          item
        )
        .then((response) => {
          console.log(response);
          getVillageData(item.state?.code, item.district?.code);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

 

  function getVillageData(stateCode, districtCode, subDistrictCode) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Village name", accessor: "village.name", width: "25%" },
        { Header: "short name", accessor: "village.shortname" },
        { Header: "code", accessor: "village.code" },
        { Header: "pincode", accessor: "pincode" },
        {
          Header: "sub-district",
          accessor: "subDistrictFullname",
          width: "15%",
        },
        {
          Header: "district",
          accessor: "districtFullname",
          width: "15%",
        },
        {
          Header: "state",
          accessor: "stateFullname",
          width: "15%",
        },
        { Header: "action", accessor: "action", width: "12%" },
      ],
      rows: [],
    };
    axios
      .get(
        `pincode-service/api/pincode/v1/get?type=VILLAGE&district.code=${districtCode}&state.code=${stateCode}&subdistrict.code=${subDistrictCode}`
      )
      .then((response) => {
        response.data.data.dtoList.map((item) => {
          item.action = (
            <span>
             <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item);
                  }}
                />
              </Link>
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          item.stateFullname =
            item.state.name + " (" + item.state.shortname + ")";

          item.districtFullname =
            item.district.name + " (" + item.district.shortname + ")";
          item.subDistrictFullname =
            item.subdistrict.name + " (" + item.subdistrict.shortname + ")";
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getSubDistrictData(stateCode, districtCode) {
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&state.code=${stateCode}&district.code=${districtCode}`
      )
      .then((response) => {
        setSubDistrictList(response.data.data.dtoList);
        //  setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  function getDistrictData(stateCode) {
    axios
      .get(
        `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${stateCode}`
      )
      .then((response) => {
        setDistrictList(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function updateData() {
    item.village.name = villagename;
   
    item.modifiedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;
    item.modifieddate = new Date();
    await axios
      .put(
        'pincode-service/api/ProjectPincode/v1/update?type=STATE',
        item
      )
      .then((response) => {
        setOpen(false);
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getStateData() {
    setLoading(true);

    axios
      .get(
        `pincode-service/api/pincode/v1/get?type=STATE`
      )
      .then((response) => {
        setTableDatas(dataObj);
        setStateList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getStateData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Village List
                  </MDTypography>
                </span>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280 }}
                      {...defaultPropsState}
                      onChange={(event, newValue) => {
                        getDistrictData(newValue?.state?.code);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select State" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280 }}
                      {...districtDefaultProp}
                      onChange={(event, newValue) => {
                        getSubDistrictData(
                          newValue?.state?.code,
                          newValue?.district?.code
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select District" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280 }}
                      {...subDistrictDefaultProp}
                      onChange={(event, newValue) => {
                        getVillageData(
                          newValue?.state?.code,
                          newValue?.district?.code,
                          newValue?.subdistrict?.code
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Sub-District" />
                      )}
                    />
                  </Grid>
                </Grid>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/page/component/create-village"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
    
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Village List</DialogTitle>
        <DialogContent style={{ width: 550, height: 150 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Village Name"
              variant="standard"
              fullWidth
              value={villagename}
              onChange={(e) => setVillagename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!villagename}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
};
export default VillageList;
