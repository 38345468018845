import checkout from "layouts/pages/warehouse/new warehouse/schemas/form"



const {
  formField: { name, type, sapcode, firstline, state,
    district,
    subDistrict,
    pincode,},
} = checkout;

const initialValues = {
  [name.name]: "",
    [type.name]: "",
  [sapcode.name]: "",
  [firstline.name]: "",
  [state.name]: "",
  [district.name]: "",
  [subDistrict.name]: "",
  [pincode.name]: "",

};

export default initialValues;
