const form = {
  formId: "new-mmucreation-form",
  formField: {
    MMUName: {
      name: "MMUName",
      label: "MMUName",
      type: "text",
      errorMsg: "MMUName name is required.",
    },
    mapware: {
      name: "mapware",
      label: "Map ware-house",
      type: "text",
      errorMsg: " mapware is required.",
    },

    RaspberryAssign: {
      name: "RaspberryAssign",
      label: "Raspberry Assign",
      type: "text",
      errorMsg: "RaspberryAssign is required.",
    },

    VehicleAssign: {
      name: "VehicleAssign",
      label: "Vehicle Assign",
      type: "text",
      errorMsg: "VehicleAssign is required.",
    },
    AssignEquipment: {
      name: "AssignEquipment",
      label: "Assign Equipment",
      type: "text",
      errorMsg: "AssignEquipment is required.",
    },
    EquipmentList: {
      name: "Equipment",
      label: "Equipment",
      type: "text",
      errorMsg: "Equipment is required.",
    },
  },
};

export default form;
