



import * as React from "react";


// @mui material components
import Grid from "@mui/material/Grid";


import { Autocomplete } from "@mui/material";






// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";

import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';

 

const Pincodeprojectlist = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const dataObj = {
    columns: [
                   { Header: "Project Name", accessor: "projectname" },
        { Header: "Project Code", accessor: "projectcode" },
        { Header: "state", accessor: "pincode.state.name" },
        
        { Header: "Distric", accessor: "pincode.district.name" },
        {
          Header: "Sub Distric",accessor: "pincode.subdistrict.name"},
          {
            Header: "vILLAGE",
            accessor: "pincode.village.name",
          },
        {Header: "Pincode", accessor: "pincode.pincode"},
        {Header: "Created By",accessor: "createdby"},
        { Header: "action", accessor: "action" }

    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const [mmucode, setMmuCode] = React.useState(null);
  const [proPincodeList, setProPincodeList] = useState("");
  const defaultPropsprojectteam = {
    options: !proPincodeList ? [{ name: "Loading...", id: 0 }] : proPincodeList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const removeItem = function (id) {
    const objWithIdIndex = tableDatas.rows.findIndex((obj) => obj.id === id);
    tableDatas.rows.splice(objWithIdIndex, 1);
    setTableDatas(tableDatas);
    
  };
  async function onDelete(item) {
    if (window.confirm("Are you sure that you want to delete that MMu record"))
      await axios
        .put(
          'project-service/api/ProjectPincode/v1/delete',
          item
        )
        .then((response) => {
          console.log(response);
          // getData();
          // tableDatas.rows=[]
          // setTableDatas(tableDatas)
          removeItem(item.id);
        })
        .catch((error) => {
          console.log(error);
        });
  }
  async function getData() {
    await axios
      .put(
        "itemmaster-service/api/itemMaster/v1/update",
        item
      )
      .then((response) => {
        setOpen(false);
        getItemtData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData(typelist) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Project Name", accessor: "projectname" },
        { Header: "Project Code", accessor: "projectcode" },
       
        { Header: "state", accessor: "pincode.state.name" },
        { Header: "Distric", accessor: "pincode.district.name" },
        {
          Header: "Sub Distric",

          accessor: "pincode.subdistrict.name",
        },
        {
          accessor: "pincode.pincode",
          Header: "Pincode",
        },
        {
          Header: "vILLAGE",
          accessor: "pincode.village.name",
        },
        { Header: "action", accessor: "action", width: "12%" },
      ],
      rows: [],
    };
    axios
      .get(
        `project-service/api/Project/v1/get`
      )
      .then((response) => {
        setProPincodeList(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });

   

}

  useEffect(() => {
    getData();

  }, []);
  
  const getpropincode=(projectcode)=>{
    axios
    .get(
      `project-service/api/ProjectPincode/v1/get?projectcode=${projectcode}`
    )
    .then((response) => {
      response.data.data.dtoList.map((item) => {
        item.action = (
          <span>
           

            <Link to={{}}>
              <MdDelete
                style={iconStyle}
                onClick={() => {
                  onDelete(item);
                }}
              />
            </Link>

          </span>
        );
        dataObj.rows.push(item);
       
      });
      setTableDatas(dataObj)
     
      // setTableDatas(dataObj);
      // setLoading(false);
    })
    .catch((e) => {
      console.log("error", e);
    });
  }

  return (
   
    <DashboardLayout>
      <DashboardNavbar />
      <Card>

        <MDTypography variant="h5" fontWeight="medium" style={{ marginTop: "20px",marginLeft:"20px" }}>
          Project Pincode List
        </MDTypography>
        <MDBox pl={3} pr={3} lineHeight={1}>
          <div style={addButtonStyle}>
            <span
              style={{
                alignSelf: "flex-start",
                marginTop: "20px",
              }}
            >

            </span>

            <Link
              style={{
                alignSelf: "flex-end",
                marginRight: "10px",
                marginTop: "-23px",
                color: "#344767",
              }}
              to={"/page/component/pincode-project"}
            >
              <MdOutlineLibraryAdd />
            </Link>
          </div>
        </MDBox>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
          
            <Autocomplete
              disablePortal
              id="combo-box-demo"
                style={{marginLeft:"20px"}}
                sx={{ width: 300 }}
                {...defaultPropsprojectteam}
              
                onChange={(event, newValue) => {
                  getpropincode(newValue.code)
                  
                  console.log(newValue);
                }}
                
                  renderInput={(params) => (
                <TextField {...params} label="Project Pincode" />
                )}
              />
            </Grid>
          </Grid>
        
        <MDBox pt={3}>
          <MDBox>
            <Card>

              
               <DataTable table={tableDatas} />
            </Card>
          </MDBox>
        </MDBox>
      </Card>
      <Footer />
    </DashboardLayout>
  )
}



export default Pincodeprojectlist
