import checkout from "layouts/pages/stock/new stock/schemas/form";

const {
  formField: {
    MRNumber,
    WHID,
    flowType,
    reqLineItemId,
    mmuCode,
    PONumber,
    uom,
    quantity,
    quantityRqst,
    MMUItemCode,
  },
} = checkout;

const initialValues = {
  [MRNumber.name]: "",
  [WHID.name]: "",
  [flowType.name]: "",
  [reqLineItemId.name]: "",
  [mmuCode.name]: "",
  [PONumber.name]: "",
  [uom.name]: "",
  [quantity.name]: "",
  [quantityRqst.name]: "",
  [MMUItemCode.name]: "",
};

export default initialValues;
