/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-designation-form",
  formField: {
    state: {
      name: "state",
      label: "State",
      type: "text",
      errorMsg: "state is required.",
    },
    district: {
      name: "district",
      label: "District",
      type: "text",
      errorMsg: "district is required.",
    },

    subDistrictCode: {
      name: "subDistrictCode",
      label: "SubDistrict Code",
      type: "number",
      errorMsg: "SubDistrict code is required.",
    },
    shortName: {
      name: "shortName",
      label: "Short Name",
      type: "text",
      errorMsg: "Short Name is required.",
    },
    subDistrictName: {
      name: "subDistrictName",
      label: "SubDistrict Name",
      type: "text",
      errorMsg: "SubDistrict Name is required.",
    },
    pincode: {
      name: "pincode",
      label: "Pincode",
      type: "number",
      errorMsg: "pincode is required.",
    },
  },
};

export default form;
