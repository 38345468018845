// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Autocomplete } from "@mui/material";
import axios from "axios";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function AddressInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { name2, mobileno2, address2} = formField;
  var { name2: name2V,
  mobileno2:mobileno2V,
address2:address2V} = values;
  
  
  

 

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
       Emergency Contact Details
      </MDTypography>
      <MDBox mt={1.625}>
      

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={name2.type}
              label={name2.label}
              name={name2.name}
              value={name2V}
              placeholder={name2.placeholder}
              // error={errors.name2 && touched.name2}
              // success={name2V.length > 0 && !errors.name2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={mobileno2.type}
              label={mobileno2.label}
              name={mobileno2.name}
              value={mobileno2V}
              placeholder={mobileno2.placeholder}
              // error={errors.mobileno2 && touched.mobileno2}
              // success={mobileno2V.length > 0 && !errors.mobileno2}
            />
          </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
          
            <FormField
              type={address2.type}
              label={address2.label}
              name={address2.name}
              multiline
              value={address2V}
              placeholder={address2.placeholder}
              // error={errors.address2 && touched.address2}
              // success={address2V.length > 0 && !errors.address2}
            />
            </Grid>
          

         
            </Grid>
            
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Address
AddressInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AddressInfo;
