/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////
import UsersListTable from "components/MMU/components/User";
import RolesListTable from "components/MMU/components/Role";
import DepartmentsList from "components/MMU/components/Department";
import DesignationList from "components/MMU/components/Designation";
import { AccountTree, GridView, Warehouse } from "@mui/icons-material";
import CreateRole from "components/MMU/components/Role/CreateRole";
import CreateDepartment from "components/MMU/components/Department/CreateDepartment";

import CreateDesignation from "components/MMU/components/Designation/CreateDesignation";
import CreateItem from "components/MMU/components/Item/CreateItem";
import ItemList from "components/MMU/components/Item/ItemList";
import MmuCreation from "components/MMU/components/Mmu Master/MmuCreation";
import MmuList from "components/MMU/components/Mmu Master/MmuList";
import CreateLab from "components/MMU/components/Lab Master/CreateLab";
import LabList from "components/MMU/components/Lab Master/LabList";
import CreateAssociation from "components/MMU/components/Association master/CreateAssociation";
import AssociationList from "components/MMU/components/Association master/AssociationList";
import CreateStock from "components/MMU/components/StockMaster/CreateStock";
import StockList from "components/MMU/components/StockMaster/StockList";
import CreateWareHouse from "components/MMU/components/WareHouse MASTER/CreateWareHouse";
import WareHouseList from "components/MMU/components/WareHouse MASTER/WareHouseList";
import Requisition from "components/MMU/components/StockMaster/Requisition";

// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";
import MedRequisition from "components/MMU/components/StockMaster/MedRequisition";
import RequisitionFulFillment from "components/MMU/components/StockMaster/RequisitionFulFillment";
import CreateVitals from "components/MMU/components/Vitals/CreateVitals";
import Vitalslist from "components/MMU/components/Vitals/Vitalslist";
import Createfrequency from "components/MMU/components/frequency/Createfrequency";
import Frequencylist from "components/MMU/components/frequency/Frequencylist";
import Createtype from "components/MMU/components/TypeMaster/Createtype";
import Typelist from "components/MMU/components/TypeMaster/Typelist";
import Agregation from "components/MMU/components/StockMaster/Agregation";
import Createproject from "components/MMU/components/Project Master/Createproject";
import Projectlist from "components/MMU/components/Project Master/Projectlist";
import MmuCreationView from "components/MMU/components/Mmu Master/MmuCreationView";
import ViewAssociation from "components/MMU/components/Association master/ViewAssociation";
import MmuScheduler from "components/MMU/components/mmuSchedule/MmuScheduler";

import Statelist from "components/MMU/components/State/Statelist";
import Createstate from "components/MMU/components/State/Createstate";
import CreateDistric from "components/MMU/components/DistricMaster/CreateDistric";
import Districlist from "components/MMU/components/DistricMaster/Districlist";
import Village from "components/MMU/components/Village/Village";
import VillageList from "components/MMU/components/Village/VillageList";
import Createdisease from "components/MMU/components/Disease Master/Createdisease";
import Diseaselist from "components/MMU/components/Disease Master/Diseaselist";

import SubDistrict from "components/MMU/components/SubDistrict/SubDistrict";
import SubDistrictList from "components/MMU/components/SubDistrict/SubDistrictList";

import Pincodeproject from "components/MMU/components/Project Master/Pincodeproject";
import Projectteam from "components/MMU/components/Project Master/Projectteam";
import Pincodeprojectlist from "components/MMU/components/Project Master/Pincodeprojectlist";
import Projectmmu from "components/MMU/components/Project Master/Projectmmu";
import Projectmmulist from "components/MMU/components/Project Master/Projectmmulist";
import Projectteamlist from "components/MMU/components/Project Master/Projectteamlist";
import Createtest from "components/MMU/components/Test master/Createtest";
import Testlist from "components/MMU/components/Test master/Testlist";
import ApprovedScheduler from "components/MMU/components/mmuSchedule/ApprovedScheduler";
import ScheduleList from "components/MMU/components/mmuSchedule/ScheduleList";
import CreateRequisition from "components/MMU/components/StockMaster/CreateRequisition";
import RequsitionList from "components/MMU/components/StockMaster/RequsitionList";

import Requistionapproval from "components/MMU/components/StockMaster/Requistionapproval";
//icons
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import ApprovalIcon from "@mui/icons-material/Approval";
import RadioIcon from "@mui/icons-material/Radio";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SpeedIcon from "@mui/icons-material/Speed";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import BalconyIcon from "@mui/icons-material/Balcony";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import HouseIcon from "@mui/icons-material/House";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import MmuApproval from "components/MMU/components/Mmu Master/MmuApproval";
import UploadSchedule from "components/MMU/components/mmuSchedule/UploadSchedule";
import BulkApprovedScheduler from "components/MMU/components/mmuSchedule/BulkApprovedScheduler";
import PatientReport from "components/MMU/components/Analytics/PatientReport";
import PrescriptionReport from "components/MMU/components/Analytics/PrescriptionReport";

const routesChiefpharmacisit = [
    {
        type: "collapse",

        name: localStorage.getItem("mmu-auth")
            ? JSON.parse(localStorage.getItem("mmu-auth")).firstname +
            " " +
            JSON.parse(localStorage.getItem("mmu-auth")).lastname
            : "",

        key: "brooklyn-alice",
        icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
        collapse: [
            {
                name: "My Profile",
                key: "my-profile",
                route: "/pages/profile/profile-overview",
                component: <ProfileOverview />,
            },

            {
                name: "Logout",
                key: "logout",
                route: "/authentication/sign-in/basic",
                component: <SignInBasic />,
            },
        ],
    },
    { type: "divider", key: "divider-0" },
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Icon fontSize="medium">dashboard</Icon>,
        collapse: [
            {
                name: "Analytics",
                key: "analytics",
                route: "/dashboards/analytics",
                component: <Analytics />,
            },
            {
                name: "Patient Report",
                key: "patient-report",
                route: "/dashboards/patient-report",
                component: <PatientReport />,
              },
            {
                name: "Prescription Report",
                key: "prescription-report",
                route: "/dashboards/prescription-report",
                component: <PrescriptionReport />,
              }

        ],
    },
    /// /////////////////////////CUSTOM///////////////////////////////////

    { type: "title", title: "Masters", key: "title-masters" },
    {
        type: "collapse",
        name: "MMU Association",
        key: "maMMUAssociation",
        icon: <CorporateFareIcon fontSize="medium"></CorporateFareIcon>,
        collapse: [
          {
            name: "Create Association",
            key: "create-association",
            route: "/mmu/component/create-association",
            component: <CreateAssociation />,
          },
          {
            name: "Association List",
            key: "association-list",
            route: "/mmu/component/association-list",
            component: <AssociationList />,          
          },
        ],
      },
    {
        type: "collapse",
        name: "MMU Scheduler",
        key: "MMUScheduler",
        icon: <CalendarTodayIcon fontSize="medium"></CalendarTodayIcon>,
        collapse: [
          {
            name: "Scheduler",
            key: "scheduler",
            route: "/mmu/component/scheduler",
            component: <MmuScheduler />,
          },
          {
            name: "Schedule List",
            key: "event-list",
            route: "/mmu/component/event-list",
            component: <ScheduleList />,
          },
        
          {
            name: "Upload Schedule",
            key: "upload-schedule",
            route: "/mmu/component/upload-schedule",
            component: <UploadSchedule />,
          },
        ],
      },
    {
        type: "collapse",
        name: "MMU Master",
        key: "mammumaster",
        icon: <AirportShuttleIcon fontSize="medium">person</AirportShuttleIcon>,
        collapse: [
         
    
          {
            name: "Mmu List",
            key: "mmu-list",
            route: "/pages/users/mmu-list",
            component: <MmuList />,
          },
          {
            name: "Mmu Approval",
            key: "mmu-approval",
            route: "/pages/users/mmu-approval",
            component: <MmuApproval />,
          },
         
          // {
          //   name: "Mmu View",
          //   key: "mmu-view",
          //   route: "/pages/users/mmu-view",
          //   component: <MmuCreationView />,
          // },
        ],
      },

    {
        type: "collapse",
        name: "Project Master",
        key: "project-master",
        icon: <ModeStandbyIcon fontSize="medium">person</ModeStandbyIcon>,
        collapse: [
          {
            name: "Create Project",
            key: "create-project",
            route: "/page/component/create-project",
            component: <Createproject />,
          },
          {
            name: "Project List",
            key: "project-list",
            route: "/page/component/project-list",
            component: <Projectlist />,
          },
    
          {
            name: "Project Pincode",
            key: "pincode-project",
            route: "/page/component/pincode-project",
            component: <Pincodeproject />,
          },
          {
            name: "Project Pincodelist",
            key: "project-pincodelist",
            route: "/page/component/project-pincodelist",
            component: <Pincodeprojectlist />,
          },
          {
            name: "Project Mmu",
            key: "project-mmu",
            route: "/page/component/project-mmu",
            component: <Projectmmu />,
          },
          {
            name: "Project Mmulist",
            key: "project-mmulist",
            route: "/page/component/project-mmulist",
            component: <Projectmmulist />,
          },
    
          {
            name: "Project team",
            key: "project-team",
            route: "/page/component/project-team",
            component: <Projectteam />,
          },
          {
            name: "Project teamlist",
            key: "project-teamlist",
            route: "/page/component/project-teamlist",
            component: <Projectteamlist />,
          },
        ],
      },
    {
        type: "collapse",
        name: "Stock Master",
        key: "maStockmaster",
        icon: <AccountTree fontSize="medium" />,
        collapse: [
          {
            name: "Create Requisition",
            key: "createrequisition",
            route: "/pages/stock/create-requisition",
            component: <CreateRequisition />,
          },
          {
            name: "Requsition List",
            key: "RequsitionList",
            route: "/pages/stock/requsition-list",
            component: <RequsitionList />,
          },
          {
            name: "Requisition Fulfillment",
            key: " RequisitionFulfillment",
            route: "/pages/stock/fulfillment-requisition",
            component: <RequisitionFulFillment />,
          },
          {
            name: "Requistion Approval",
            key: " Requisition Approval",
            route: "/pages/stock/requisition-approval",
            component: <Requistionapproval />,
          },
    
          {
            name: "Stock List",
            key: "stock-list",
            route: "/pages/stock/stock-list",
            component: <StockList />,
          },
        ],
      },



];

export default routesChiefpharmacisit;