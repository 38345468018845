import { useEffect, useState } from "react";

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import FormField from "layouts/pages/Lab/new-lab/componets/FormField";
// @mui material components
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import axios from "axios";

const initialValues = {
    state: "",
    shortname: "",
    statecode: ""
}

const Statecomponent = (props) => {

    const { tittle, children, childToparent, openpopupstate, setopenpopupstate } = props
    async function createstate(pinCodeObject) {
        axios
            .post(
                "pincode-service/api/pincode/v1/create",
                pinCodeObject
            )

            .then((response) => {
                console.log("gfgfgf", response);

                setopenpopupstate(false)
                if (response) {
                    console.log("38", response.data.data.dto)
                    childToparent(response.data.data.dto)

                }




            })
            .catch((error) => {
                console.log(error);


            });

    }
    const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, action) => {

            const pinCodeObject = {
                id: null,
                createdby:
                    JSON.parse(localStorage.getItem("mmu-auth")).firstname +
                    " " +
                    JSON.parse(localStorage.getItem("mmu-auth")).lastname,
                modifiedby: "",
                approvedby: "",
                recstatus: "OPEN",
                syncstatus: "synced",
                status: "ACTIVE",
                documentofproof: "",
                tempid: "",
                state: {
                    code: values.statecode,
                    shortname: values.shortname,
                    name: values.state,
                },

                resourcecode: "DPF",
                type: "STATE",
            };

            createstate(pinCodeObject)
            swal("Alert!", "...your state has been sucessfully create!", "success")
                .then((value) => {
                    // window.location.reload(true)

                    action.resetForm();

                });

        }
    })


    const handleClose = () => {
        console.log("dsfsdfs");
        setopenpopupstate(false);
    };
    ;

    return (
        <Dialog open={openpopupstate} onClose={handleClose} >
            <MDBox mx={2} mt={2}>

                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-2}
                    p={2}
                    mb={-2}
                    textAlign="center"
                >
                    <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                    >
                        State
                    </MDTypography>
                </MDBox>
            </MDBox>
            <form onSubmit={handleSubmit}>
                <DialogContent style={{ width: 550, height: 170 }}>
                    <Grid container spacing={3} mt={.5}>
                        <Grid item xs={12} sm={6}>
                            <MDInput
                                type="text"
                                autocomplete="off"
                                name="state"
                                label="State"
                                id="state"
                                variant="standard"
                                fullWidth
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MDInput
                                type="text"
                                autocomplete="off"
                                name="shortname"
                                label="Shortname"
                                id="shortname"
                                variant="standard"
                                fullWidth
                                value={values.shortname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} mt={.5}>
                        <Grid item xs={12} sm={6}>
                            <MDInput
                                type="number"
                                autocomplete="off"
                                name="statecode"
                                label="Statecode"
                                id="statecode"

                                variant="standard"
                                fullWidth
                                value={values.statecode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" >create</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}








export default Statecomponent;