// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { func } from "prop-types";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { itemContent } from "examples/Sidenav/styles/sidenavItem";
import { queryByTestId } from "@testing-library/react";


const Agregation = () => {
  
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState();
  const [shortreciving, setShortreciving] = useState('');
  
   let qty = 0;

  // const handleChange = (event) => {
  //   setTableDatas(event.target.value);

  //   console.log("value is", event.target.value);
  // };
  
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginLeft: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginLeft: "10px",
  };
  function changeValue(Quantity,quantityrqst){

    if(quantityrqst < Quantity){
      alert("Qty Should be less than Qty Raised");
    }
    else{
      qty = (quantityrqst - Quantity);
      console.log("Hiii",qty);
     
    }
  }

  // const [,setValue] = useState();

  function getData() {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Item No.", accessor: "id", width: "25%" },
        { Header: "Indent No.", accessor: "mrnumber", width: "25%" },
        { Header: "Item Name", accessor: "name", width: "20%" },
        { Header: "Qty. Raised", accessor: "quantityapproved" },
        { Header: "Qty.", accessor: "input", width: "15%" },
        { Header: "Short Receiving", accessor: "short" , width: "12%" },
        { Header: "Action", accessor: "action", width: "15%" },
      ],
      rows: [],
    };
    axios
      .get(
        `api/MedRequisitionGen/v1/get?recStatus=PROCESSED`
      )
      .then((response) => {
        console.log("response",response)
        response?.data?.data?.dtoList?.map((item) => {
          console.log("item",item)
          item.input = (
            <MDInput
            type="number"
            variant="outlined"
            id="txtqut"
            size="small"
            onChange={(e) => changeValue(e.target.value,item.quantityrqst)}
            style={{ width: "70px" }}
          />
            // <MDInput
            //   type="number"
            //    value="" 
               
            //   // // variant="outlined"
            //   // // size="small"
            //   // onKeyPress={(e)=>{
            //   //   changeValue(e.target.value,item.quantityrqst)}}
            //   style={{ width: "70px" }}
            // />
          );

          item.short = qty;
       
          item.action = (
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              value="Submit"
              // onClick={(e) => handleSubmit(e.target.value)}
            >
              Submit
            </MDButton>
          );
           dataObj.rows.push(item);
          //  dataObj.rows.push(qty);
        });
        console.log("object datas", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getData();
  }, []);
  
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Agregation Table
                  </MDTypography>
                </span>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && (
              <DataTable
                table={tableDatas}
                
                canSearch={true}
              />
            )}
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Agregation;