/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////
import UsersListTable from "components/MMU/components/User";
import RolesListTable from "components/MMU/components/Role";
import DepartmentsList from "components/MMU/components/Department";
import DesignationList from "components/MMU/components/Designation";
import { AccountTree, GridView, Warehouse } from "@mui/icons-material";
import CreateRole from "components/MMU/components/Role/CreateRole";
import CreateDepartment from "components/MMU/components/Department/CreateDepartment";

import CreateDesignation from "components/MMU/components/Designation/CreateDesignation";
import CreateItem from "components/MMU/components/Item/CreateItem";
import ItemList from "components/MMU/components/Item/ItemList";
import MmuCreation from "components/MMU/components/Mmu Master/MmuCreation";
import MmuList from "components/MMU/components/Mmu Master/MmuList";
import CreateLab from "components/MMU/components/Lab Master/CreateLab";
import LabList from "components/MMU/components/Lab Master/LabList";
import CreateAssociation from "components/MMU/components/Association master/CreateAssociation";
import AssociationList from "components/MMU/components/Association master/AssociationList";
import CreateStock from "components/MMU/components/StockMaster/CreateStock";
import StockList from "components/MMU/components/StockMaster/StockList";
import CreateWareHouse from "components/MMU/components/WareHouse MASTER/CreateWareHouse";
import WareHouseList from "components/MMU/components/WareHouse MASTER/WareHouseList";
import Requisition from "components/MMU/components/StockMaster/Requisition";
// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";
import MedRequisition from "components/MMU/components/StockMaster/MedRequisition";
import RequisitionFulFillment from "components/MMU/components/StockMaster/RequisitionFulFillment";
import CreateVitals from "components/MMU/components/Vitals/CreateVitals";
import Vitalslist from "components/MMU/components/Vitals/Vitalslist";
import Createfrequency from "components/MMU/components/frequency/Createfrequency";
import Frequencylist from "components/MMU/components/frequency/Frequencylist";
import Createtype from "components/MMU/components/TypeMaster/Createtype";
import Typelist from "components/MMU/components/TypeMaster/Typelist";
import Agregation from "components/MMU/components/StockMaster/Agregation";
import Createproject from "components/MMU/components/Project Master/Createproject";
import Projectlist from "components/MMU/components/Project Master/Projectlist";
import MmuCreationView from "components/MMU/components/Mmu Master/MmuCreationView";
import ViewAssociation from "components/MMU/components/Association master/ViewAssociation";
import MmuScheduler from "components/MMU/components/mmuSchedule/MmuScheduler";

import Statelist from "components/MMU/components/State/Statelist";
import Createstate from "components/MMU/components/State/Createstate";
import CreateDistric from "components/MMU/components/DistricMaster/CreateDistric";
import Districlist from "components/MMU/components/DistricMaster/Districlist";
import Village from "components/MMU/components/Village/Village";
import VillageList from "components/MMU/components/Village/VillageList";
import Createdisease from "components/MMU/components/Disease Master/Createdisease";
import Diseaselist from "components/MMU/components/Disease Master/Diseaselist";

import SubDistrict from "components/MMU/components/SubDistrict/SubDistrict";
import SubDistrictList from "components/MMU/components/SubDistrict/SubDistrictList";

import Pincodeproject from "components/MMU/components/Project Master/Pincodeproject";
import Projectteam from "components/MMU/components/Project Master/Projectteam";
import Pincodeprojectlist from "components/MMU/components/Project Master/Pincodeprojectlist";
import Projectmmu from "components/MMU/components/Project Master/Projectmmu";
import Projectmmulist from "components/MMU/components/Project Master/Projectmmulist";
import Projectteamlist from "components/MMU/components/Project Master/Projectteamlist";
import Createtest from "components/MMU/components/Test master/Createtest";
import Testlist from "components/MMU/components/Test master/Testlist";
import ApprovedScheduler from "components/MMU/components/mmuSchedule/ApprovedScheduler";
//icons 


import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import ApprovalIcon from '@mui/icons-material/Approval';
import RadioIcon from '@mui/icons-material/Radio';
import VaccinesIcon from '@mui/icons-material/Vaccines';

import SpeedIcon from '@mui/icons-material/Speed';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import BalconyIcon from '@mui/icons-material/Balcony';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import HouseIcon from '@mui/icons-material/House';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
const routesMasterDataManager = [
  {
    type: "collapse",

    name: localStorage.getItem("mmu-auth")
      ? JSON.parse(localStorage.getItem("mmu-auth")).firstname +
        " " +
        JSON.parse(localStorage.getItem("mmu-auth")).lastname
      : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
     
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
     
    ],
  },
  /// /////////////////////////CUSTOM///////////////////////////////////

  { type: "title", title: "Masters", key: "title-masters" },

  {
    type: "collapse",
    name: "User Master",
    key: "mausermaster",
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [
      {
        name: "New User",
        key: "new-user",
        route: "/pages/users/new-user",
        component: <NewUser />,
      },
      {
        name: "Users List",
        key: "users-list",
        route: "/pages/users/users-list",
        component: <UsersListTable />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Role Master",
    key: "marolemaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create Role",
        key: "CreateRole",
        route: "/pages/profile/create-role",
        component: <CreateRole />,
      },
      {
        name: "Roles List",
        key: "roles-list",
        route: "/pages/profile/roles-list",
        component: <RolesListTable />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Department Master",
    key: "madepartmentmaster",
    icon: <GridView fontSize="medium" />,
    collapse: [
      {
        name: "Create Department",
        key: "CreateDepartment",
        route: "/pages/profile/create-department",
        component: <CreateDepartment />,
      },
      {
        name: "Departments List",
        key: "departments-list",
        route: "/pages/profile/departments-list",
        component: <DepartmentsList />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Designation Master",
    key: "madesignationmaster",
    icon: <ApprovalIcon fontSize="medium" />,
    collapse: [
      {
        name: "Create Designation",
        key: " CreateDesignation",
        route: "/pages/profile/create-designation",
        component: <CreateDesignation />,
      },
      {
        name: "Designation List",
        key: "designation-list",
        route: "/pages/profile/designation-list",
        component: <DesignationList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Item Master",
    key: "maitemmaster",
    icon: <FeaturedPlayListIcon fontSize="medium">badge</FeaturedPlayListIcon>,
    collapse: [
      {
        name: "Create item",
        key: "CreateItem",
        route: "/pages/users/Create-item",
        component: <CreateItem />,
      },
      {
        name: "Item List",
        key: "item-List",
        route: "/pages/users/item-list",
        component: <ItemList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "MMU Master",
    key: "mammumaster",
    icon: <AirportShuttleIcon fontSize="medium">person</AirportShuttleIcon>,
    collapse: [
      {
        name: "Mmu Creation",
        key: "mmu-Creation",
        route: "/pages/users/mmu-Creation",
        component: <MmuCreation />,
      },
      {
        name: "Mmu List",
        key: "mmu-list",
        route: "/pages/users/mmu-list",
        component: <MmuList />,
      },
      // {
      //   name: "Mmu View",
      //   key: "mmu-view",
      //   route: "/pages/users/mmu-view",
      //   component: <MmuCreationView />,
      // },
    ],
  },
  
 
  
  {
    type: "collapse",
    name: "Stock Master",
    key: "maStockmaster",
    icon: <AccountTree fontSize="medium" />,
    collapse: [
     
      {
        name: "Requisition Fulfillment",
        key: " RequisitionFulfillment",
        route: "/pages/stock/fulfillment-requisition",
        component: <RequisitionFulFillment />,
      },
      {
        name: "Agregation",
        key: " Agregation",
        route: "/pages/stock/Agregation",
        component: <Agregation />,
      },
      {
        name: "Create Stock",
        key: "createStock",
        route: "/pages/stock/create-stock",
        component: <CreateStock />,
      },
      {
        name: "Requisition Status",
        key: " requisition",
        route: "/pages/stock/Requisition",
        component: <Requisition />,
      },
      {
        name: "Stock List",
        key: "stock-list",
        route: "/pages/stock/stock-list",
        component: <StockList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "WareHouse Master",
    key: "mawaremaster",
    icon: <HouseIcon fontSize="medium">badge</HouseIcon>,
    collapse: [
      {
        name: "Create WareHouse",
        key: "CreateWareHouse",
        route: "/pages/warehouse/create-warehouse",
        component: <CreateWareHouse />,
      },
      {
        name: "WareHouse List",
        key: "warehouse-list",
        route: "/pages/warehouse/warehouse-list",
        component: <WareHouseList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Vitals Master",
    key: "vitals",
    icon: <VaccinesIcon fontSize="medium">person</VaccinesIcon>,
    collapse: [
      {
        name: "Create Vitals",
        key: "create-vitals",
        route: "/page/component/create-vitals",
        component: <CreateVitals />,
      },
      {
        name: "Vitals List",
        key: "vitals-list",
        route: "/page/component/vitals-list",
        component: <Vitalslist />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Frequency Master",
    key: "frequency",
    icon: <RadioIcon fontSize="medium">person</RadioIcon>,
    collapse: [
      {
        name: "Create Frequency",
        key: "create-frequency",
        route: "/page/component/create-frequency",
        component: <Createfrequency />,
      },
      {
        name: "Frequency List",
        key: "frequency-list",
        route: "/page/component/frequency-list",
        component: <Frequencylist />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Type Master",
    key: "type",
    icon: <SavedSearchIcon fontSize="medium"></SavedSearchIcon>,
    collapse: [
      {
        name: "Create Type",
        key: "create-type",
        route: "/page/component/create-type",
        component: <Createtype />,
      },
      {
        name: "Type List",
        key: "type-list",
        route: "/page/component/type-list",
        component: <Typelist />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Project Master",
    key: "project-master",
    icon: <ModeStandbyIcon fontSize="medium"></ModeStandbyIcon>,
    collapse: [
      {
        name: "Create Project",
        key: "create-project",
        route: "/page/component/create-project",
        component: <Createproject />,
      },
      {
        name: "Project List",
        key: "project-list",
        route: "/page/component/project-list",
        component: <Projectlist />,
      },
      {
        name: "Project Pincode",
        key: "pincode-project",
        route: "/page/component/pincode-project",
        component: <Pincodeproject />,
      },
      {
        name: "Project Pincodelist",
        key: "project-pincodelist",
        route: "/page/component/project-pincodelist",
        component: <Pincodeprojectlist />,
      },
      {
        name: "Project Mmu",
        key: "project-mmu",
        route: "/page/component/project-mmu",
        component: <Projectmmu />,
      },
      {
        name: "Project Mmulist",
        key: "project-mmulist",
        route: "/page/component/project-mmulist",
        component: <Projectmmulist />,
      },

      {
        name: "Project team",
        key: "project-team",
        route: "/page/component/project-team",
        component: <Projectteam />,
      },
      {
        name: "Project teamlist",
        key: "project-teamlist",
        route: "/page/component/project-teamlist",
        component: <Projectteamlist />,
      },
    ],
  },

  {
    type: "collapse",
    name: "State Master",
    key: "state-master",
    icon: <BalconyIcon fontSize="medium">person</BalconyIcon>,
    collapse: [
      {
        name: "Create state",
        key: "create-state",
        route: "/page/component/create-state",
        component: <Createstate />,
      },
      {
        name: "State List",
        key: "state-list",
        route: "/page/component/state-list",
        component: <Statelist />,
      },
    ],
  },

  {
    type: "collapse",
    name: "District Master",
    key: "distric-master",
    icon: <AddRoadIcon fontSize="medium">person</AddRoadIcon>,
    collapse: [
      {
        name: "Create district",
        key: "create-district",
        route: "/page/component/create-distric",
        component: <CreateDistric />,
      },
      {
        name: "District List",
        key: "distric-list",
        route: "/page/component/distric-list",
        component: <Districlist />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Sub-District Master",
    key: "subDistric-master",
    icon: <AssistantDirectionIcon fontSize="medium"></AssistantDirectionIcon>,  
    collapse: [
      {
        name: "Create sub-district",
        key: "create-sub-distric",
        route: "/page/component/create-sub-distric",
        component: <SubDistrict />,
      },
      {
        name: "Sub-District List",
        key: "sub-distric-list",
        route: "/page/component/sub-distric-list",
        component: <SubDistrictList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Village Master",
    key: "village-master",
    icon: <HolidayVillageIcon fontSize="medium">person</HolidayVillageIcon>,
    collapse: [
      {
        name: "Create Village",
        key: "create-village",
        route: "/page/component/create-village",
        component: <Village />,
      },
      {
        name: "Village List",
        key: "village-list",
        route: "/page/component/village-list",
        component: <VillageList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Disease Master",
    key: "disease-master",
    icon: <CoronavirusIcon fontSize="medium"></CoronavirusIcon>,
    collapse: [
      {
        name: "Create Disease",
        key: "create-disease",
        route: "/page/component/create-disease",
        component: <Createdisease />,
      },
      {
        name: "Disease List",
        key: "disease-list",
        route: "/page/component/disease-list",
        component: <Diseaselist />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Test Master",
    key: "test",
    icon: <SpeedIcon fontSize="medium">person</SpeedIcon>,
    collapse: [
      {
        name: "Create Test",
        key: "create-test",
        route: "/page/component/create-test",
        component: <Createtest />,
      },
      {
        name: "Test List",
        key: "test-list",
        route: "/page/component/test-list",
        component: <Testlist />,
      },
    ],
  },

  
  
];




export default routesMasterDataManager