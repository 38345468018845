import React from 'react'

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Autocomplete } from "@mui/material";



import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

const Vitalslist = () => {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [item, setItem] = useState({});
  const [selectuom, setSelectuom] = useState('');
  const [selectvital, setSelectvital] = useState('');
  const [uomList, setUomList] = useState('');
  const [vitalList, setVitalList] = useState('');
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const [Updatename, setUpdatename] = useState("");
  const [vitalsrange, setVitalsRange] = useState("");
  const [err, setErr] = useState("");
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const defaultPropsvital = {
    options: !vitalList ? [{ name: "Loading...", id: 0 }] : vitalList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsuom={
    options: !uomList ? [{ name: "Loading...", id: 0 }] : uomList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  async function getvitalData() {
  await axios
  .get(`typemaster-service/api/TypeMaster/v1/get?category=VITAL`)
  .then((response) => {
    setVitalList(response.data?.data?.dtoList);
    getUomData(response.data?.data?.dtoList)
   
  })
  
  .catch((e) => {
    console.log("error", e);
  });
  }
  async function getUomData(vitalsIdList) {
  await axios
  .get(`typemaster-service/api/TypeMaster/v1/get?category=VITAL_UOM`)
  .then((response) => {
    setUomList(response.data?.data?.dtoList);
    getData(vitalsIdList, response.data?.data?.dtoList);

  
 
  })

  .catch((e) => {
    console.log("error", e);
  });
  }
  const onUpdatedata=(object,vitalsIdList,uomIdList)=> {
console.log("101",vitalsIdList)
console.log("102",uomIdList)
    for (let i = 0; i < vitalsIdList.length; i++) {

      if (vitalsIdList[i].name === object.type) {
        console.log("900", vitalsIdList[i])
        setSelectvital(vitalsIdList[i]);
      }
    }
    for (let i = 0; i < uomIdList.length; i++) {
      if (uomIdList[i].name === object.uom) {
        console.log("118", uomIdList[i])
        setSelectuom(uomIdList[i]);
      }
    }
    setItem(object)
    setUpdatename(object.name);
    setVitalsRange(object.range);
    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function onDelete(item) {
    if (window.confirm("Are you sure that you want to delete that user record"))
      await axios
        .put(
          "vitalsmaster-service/api/VitalsMaster/v1/delete",
          item
        )
        .then((response) => {
          console.log(response);
          getData();
        })
        .catch((error) => {
          console.log(error);
        });
  }



  async function updateData() {

    item.name = Updatename;
    item.range = vitalsrange;
    delete item.action
    await axios
      .put(
        "vitalsmaster-service/api/VitalsMaster/v1/update",
        item
      )
      .then((response) => {

        setOpen(false);
        getvitalData();
      })
      .catch((error) => {
        console.log(error);
      });

  }



  function getData(vitalsIdList,uomIdList) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Name", accessor: "name", width: "25%" },
        { Header: "Vitals Type", accessor: "type" },
        { Header: "Vitals Range", accessor: "range",width: "25%" },
        { Header: "UOM", accessor: "uom",width: "25%" },
       
        { Header: "action", accessor: "action", width: "12%" },
      ],
      rows: [],
    };
    axios
      .get(
        `vitalsmaster-service/api/VitalsMaster/v1/get`
      )
      .then((response) => {
        response.data.data.dtoList.map((item) => {

          item.action = (
            <span>
              <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item,vitalsIdList,uomIdList);
                  }}
                />
              </Link>
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />

              </Link>

            </span>
          );
          dataObj.rows.push(item);
        });

        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getvitalData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Vitals List

                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/page/component/create-vitals"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>



      <Dialog open={open} onClose={handleClose}>
        <DialogTitle >Update Vitals List</DialogTitle>
        <DialogContent style={{ width: 550, height: 250 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Vitals Name"
              variant="standard"
              fullWidth
              value={Updatename}
              onChange={(e) => setUpdatename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
            {...defaultPropsvital}
            value={selectvital}
            onChange={(event, newValue) => {
              // handleChange(event);
           

              setSelectvital(newValue);
              item.type = newValue.name;
             

            }}
            renderInput={(params) => (
              <MDInput
                {...params}

                variant="standard"
                label="Vitals Type"
              />
            )}
          />
             </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Vitals Type"
              variant="standard"
              fullWidth
              value={vitalsrange}    
              onChange={(e) => setVitalsRange(e.target.value)}

            />
           </MDBox>
           <MDBox mb={2}>
            <Autocomplete
            {...defaultPropsuom}
            value={selectuom}
            onChange={(event, newValue) => {
              // handleChange(event);
           

              setSelectuom(newValue);
              item.uom = newValue.name;
             

            }}
            renderInput={(params) => (
              <MDInput
                {...params}

                variant="standard"
                label="UOM"
              />
            )}
          />
             </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!Updatename}>Update</Button>
        </DialogActions>
      </Dialog>


      <Footer />
    </DashboardLayout>
  );
}

export default Vitalslist