import * as Yup from "yup";

import checkout from "layouts/pages/warehouse/new warehouse/schemas/form"

const {
  formField: { name, type, sapcode, firstline,pincode,state },
} = checkout;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
  //  [type.name]: Yup.string().required(type.errorMsg),
    [sapcode.name]: Yup.string().required(sapcode.errorMsg),
    [firstline.name]: Yup.string().required(firstline.errorMsg),
    //[pincode.name]: Yup.string().required(pincode.errorMsg),
    // [state.name]: Yup.string().required(state.errorMsg),
  }),
];

export default validations;
