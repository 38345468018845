import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";


import { Autocomplete, Grid, TextField } from "@mui/material";
import MDBadge from "components/MDBadge";

const PrescriptionQueue = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [openreq, setOpenreq] = React.useState(false);
  const [treatedlist, setTreatedlist] = useState("");
  const [categoryList, setCategoryList] = useState("");
  const [selectcategory, setSelectCategory] = useState("");
  const [item, setItem] = useState({});
  const [selecttreated, setSelecttreated] = useState(null);
  const [duration, setDuration] = useState("");
  const [dose, setDose] = useState("");
  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist);
  const [mmuvalue, setMmuvalue] = useState(null);
  const [getResponse, setResponse] = useState([]);
  const [changeReqTable, setChangeReqTable] = useState();
  const [mmucode, setMmucode] = useState(
    JSON.parse(localStorage.getItem("association"))[0].mmucode);

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const defaultPropsmmuCode = {
    options: !mmuList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuList,
    getOptionLabel: (option) => option.name + " (" + option.vehicle.name + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsTreated = {
    options: !treatedlist
      ? [
        { name: "TREATED", code: "X" },
        { name: "TREATMENT_IN_PROGRESS", code: "y" },

      ]
      : treatedlist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  const defaultPropsCategory = {
    options: !categoryList
      ? [
        { name: "ITEM", code: "A" },
        { name: "VITAL", code: "B" },
        { name: "COMPDOC", code: "C" },
        { name: "OCCUPATION", code: "D" },
        { name: "EDUCATION", code: "E" },
      ]
      : categoryList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  const handleClosereq = () => {
    console.log("dsfsdfs");
    setOpenreq(false);
  };
  const [itemList, setItemList] = useState("");
  const [itemValue, setItemValue] = useState(null);

  const [frequencyList, setFrequencyList] = useState("");
  const [frequencyValue, setFrequencyValue] = useState(null);

  const defaultPropsItem = {
    options: !itemList
      ? [
        { name: "Loading...", id: "X" }
      ]
      : itemList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsFreq = {
    options: !frequencyList
      ? [
        { description: "Loading...", code: "X" }
      ]
      : frequencyList,
    getOptionLabel: (option) => option.description,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  async function onUpdatePrescriptionqueue(object) {
    console.log("109", object);
    for (let i = 0; i < object.rows.length; i++) {
      var medObject = null;
      var freqObject = null;
      for (let j = 0; j < itemList.length; j++) {
        if (itemList[j].name == object.rows[i].medicinename) {
          medObject = itemList[j];
        }
      }
      for (let j = 0; j < frequencyList.length; j++) {
        if (frequencyList[j].description == object.rows[i].frequency) {
          freqObject = frequencyList[j];
        }
      }
      // var medObject = itemList.filter(employee => employee.name == object.rows[i].medicinename);
      // var freqObject = frequencyList.filter(employee => employee.description == object.rows[i].frequency);
      // console.log("med", medObject);
      // console.log("freqObject", freqObject);
      var medValue = null;
      object.rows[i].medSelector = (
        <Autocomplete
          style={{ width: "168px" }}
          {...defaultPropsItem}
          value={object.rows[i][medValue]}
          defaultValue={medObject}
          onChange={(event, newValue) => {
            object.rows[i].medValue = newValue
            // object.rows[i].medicinenameNew = newValue.name
            // object.rows[i].dosageNew = newValue.name
            // object.rows[i].uomNew = newValue.uom
          }}
          renderInput={(params) => (
            <TextField{...params} label="select-item"
            />
          )}
        />
      );

      var freqValue = null
      object.rows[i].frequencySelector = (<Autocomplete
        style={{ width: "120px" }}
        {...defaultPropsFreq}
        value={object.rows[i][freqValue]}
        defaultValue={freqObject}
        onChange={(event, newValue) => {
          // setFrequencyValue(newValue);
          object.rows[i].freqValue = newValue
          // object.rows[i].frequency = newValue.description;
        }}
        renderInput={(params) => (
          <TextField{...params} label="Freq."
          />
        )}
      />);

      object.rows[i].inputDose = (
        <MDInput
          type="number"
          variant="outlined"
          size="small"
          name={object.rows[i].dose}
          defaultValue={object.rows[i].dose}
          onChange={(e) => { object.rows[i].doseNew = e.target.value }}
          style={{ width: "70px" }}
        />
      );
      object.rows[i].inputDuration = (
        <MDInput
          type="number"
          variant="outlined"
          size="small"
          name={object.rows[i].duration}
          defaultValue={object.rows[i].duration}
          onChange={(e) => { object.rows[i].durationNew = e.target.value }}
          style={{ width: "70px" }}
        />
      );
    }

    const dataObj = {
      columns: [
        { Header: "medicine description", accessor: "medSelector", width: "20%" },
        { Header: "uom", accessor: "uom" },
        { Header: "freq.", accessor: "frequencySelector" },
        { Header: "duration", accessor: "inputDuration" },
        { Header: "dose", accessor: "inputDose" },
        // { Header: "Batch", accessor: "batch" },
        // { Header: "Given", accessor: "input" },
        // { Header: "action", accessor: "action" },
      ],
      rows: object.rows,
    };
    setChangeReqTable(dataObj);

    if (changeReqTable) {
      setOpenreq(true);
    }

  }

  async function getItemData() {
    await axios
      .get(
        `itemmaster-service/api/itemMaster/v1/get`
      )
      .then((response) => {
        setItemList(response.data.data.dtoList)
        getFrequencyData()

      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getFrequencyData() {
    await axios
      .get(
        `frequencymaster-service/api/frequencyMaster/v1/get`
      )
      .then((response) => {
        setFrequencyList(response.data.data.dtoList)

      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getDosesAggregationDataByVisit(mmucode) {
    setLoading(true);

    await axios
      .get(
        `aggregation-service/api/Aggregation/v1/getPrescription?mmucode=${mmucode}&recstatus=PRESCRIBED&rolecode=PH617`
      )
      .then((response) => {
        response.data.data.prescriptionAggregationDTOList.map((item) => {
          for (let i = 0; i < item.doseslist.length; i++) {
            item.doseslist[i].qty = item.doseslist[i].dose * item.doseslist[i].duration * item.doseslist[i].countperday
            const defaultPropsItem = {
              options: !item.doseslist[i].itembatchlist
                ? [
                  { displayname: "Loading...", itemcode: "X" }
                ]
                : item.doseslist[i].itembatchlist,
              getOptionLabel: (option) => option.displayname,
              isOptionEqualToValue: (option, value) => option.itemcode === value.itemcode,
            };

            // var itemValue = null
            item.doseslist[i].batch =
              <Autocomplete
                {...defaultPropsItem}
                name={item.doseslist[i].id}
                // value={item.doseslist[i].itembatch}
                onChange={(event, newValue) => {
                  item.doseslist[i].itembatch = newValue;

                }}
                renderInput={(params) => (
                  <TextField{...params} label="Batch"
                  />
                )}
              />
            item.doseslist[i].input = (
              <MDInput
                type="number"
                variant="outlined"
                size="small"
                name={item.doseslist[i].id}
                defaultValue={0}
                onChange={(e) => { item.doseslist[i].quantity = e.target.value }}
                style={{ width: "70px" }}
              />
            );
            // item.doseslist[i].action = (
            //   <MDButton
            //   size="small"
            //   variant="gradient"
            //   circular="true"
            //   color="success"

            //   onClick={() => {
            //     setOpenreq(true);
            //     onUpdatePrescriptionqueue(item.doseslist[i]);
            //   }}
            // >
            //   Req.change
            // </MDButton>
            // );
          }
          const dataObj = {
            columns: [
              { Header: "medicine description", accessor: "medicinename" },
              { Header: "freq.", accessor: "frequency" },
              { Header: "dur.", accessor: "duration" },
              { Header: "uom", accessor: "uom" },
              { Header: "Qty.", accessor: "qty" },
              { Header: "Batch", accessor: "batch" },
              { Header: "Given", accessor: "input" },
              // { Header: "action", accessor: "action" },
            ],
            rows: item.doseslist,
          };


          item.dataObj = dataObj;
        });
        setResponse(response.data.data.prescriptionAggregationDTOList);
        setApiResponse(response.data.data.prescriptionAggregationDTOList);
        console.log("====================================");
        console.log(response.data.data.prescriptionAggregationDTOList);
        console.log("====================================");

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function updateDosesList(objectList) {
    await axios.put(
      `doses-service/api/doses/v1/updateList`,
      objectList
    ).then((reaponse) => {
      getDosesAggregationDataByVisit(mmucode)
    }).catch((e) => {
      getDosesAggregationDataByVisit(mmucode)
    })
  }

  function handleUpdate(dosesObjectList) {

    for (let i = 0; i < dosesObjectList.length; i++) {
      delete dosesObjectList[i].batch;
      delete dosesObjectList[i].input;
      dosesObjectList[i].recstatus = "DELIVERED"
    }
    console.log("183", dosesObjectList);
    updateDosesList(dosesObjectList);
  }

  function handleRequest() {
    console.log("final 371", changeReqTable);
    var dataList = changeReqTable.rows;
    for (let i = 0; i < dataList.length; i++) {

      var previousdoseObj = {};
      var medValue = dataList[i].medValue;
      var dose = dataList[i].doseNew;
      var duration = dataList[i].durationNew;
      var frequency = dataList[i].freqValue?.description;
      var medicinename = dataList[i].medValue?.name;
      var dosage = dataList[i].medValue?.name;
      delete dataList[i].doseNew;
      delete dataList[i].durationNew;
      delete dataList[i].batch;
      delete dataList[i].freqValue;
      delete dataList[i].frequencySelector;
      delete dataList[i].input;
      delete dataList[i].inputDose;
      delete dataList[i].inputDuration;
      delete dataList[i].medSelector;
      delete dataList[i].medValue;
      if (typeof medValue !== 'undefined') {
        previousdoseObj = dataList[i];

        if (typeof dose !== 'undefined') {
          previousdoseObj.dose = dose;
        }
        if (typeof duration !== 'undefined') {
          previousdoseObj.duration = duration;
        }
        if (typeof frequency !== 'undefined') {
          previousdoseObj.frequency = frequency;
        }
        if (typeof medicinename !== 'undefined') {
          previousdoseObj.medicinename = medicinename;
        }
        if (typeof medValue !== 'undefined') {
          previousdoseObj.dosage = dosage;
        }
        // dataList[i].previousdose.previousdose = null;
        dataList[i].previousdose = previousdoseObj;
      }

    }
    console.log("final 413", dataList);
  }
  useEffect(() => {
    getDosesAggregationDataByVisit(mmucode);
    getItemData()
  }, []);

  // Search Button / Filter Data
  const [searched, setSearched] = useState(null);
  const [getApiResponse, setApiResponse] = useState([]);

  const requestSearch = (keyData) => {
    console.log("380", keyData)
    //setSearched(key);
    const searchdata = getResponse.filter((key) => {
        if (key.patient.name.toLowerCase().includes(keyData.toLowerCase()) || key.patient.age.includes(keyData)) {
            return key;
        }

    });
    if (keyData === null || keyData.length === 0) {
        setResponse(getApiResponse);
    }
    else
        setResponse(searchdata);
};


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              {/* <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Prescription Queue
                  </MDTypography>

                </span>
              </div> */}
              <Grid container spacing={3} mt={2}>
                <Grid item xs={12} sm={6}>
                  <div style={addButtonStyle}>
                    <span
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        Prescription Queue
                      </MDTypography>

                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox width="12rem" ml="auto">
                    <MDInput
                      placeholder="Search..."
                      value={searched}

                      onChange={(e) => {
                        requestSearch(e.target.value)
                        setSearched(e.target.value)
                      }}


                    />
                  </MDBox>
                </Grid>


              </Grid>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />

            <div>
              {getResponse.map((values) => (
                values.doseslist[0].recstatus == "OPEN" &&
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Name :
                          </text>
                          {" " + values.patient?.name}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Age :
                          </text>
                          {" " + values.patient?.age}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>Prescription :</text>
                          {" " + values.visit?.presid}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>Status :</text>

                          {" " +
                            values.visit?.recstatus}
                        </div>


                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <text style={{ color: "#FF00FF" }}>
                        <DataTable
                          table={values.dataObj}
                          showTotalEntries={false}
                          entriesPerPage={false}
                        />
                      </text>
                    </Typography>
                    <MDBox style={{
                      textAlign: "center",
                      marginTop: "5px"

                    }}>
                      <MDButton
                        circular="true"
                        color="success"
                        size="small"
                        style={{

                          marginRight: "15px"
                        }}
                        onClick={() => handleUpdate(values.doseslist)}
                        variant="gradient"
                      >
                        Update
                      </MDButton>
                      <MDButton
                        size="small"
                        variant="gradient"
                        circular="true"
                        color="success"

                        onClick={() => {

                          onUpdatePrescriptionqueue(values.dataObj);

                        }}
                      >
                        Req.change
                      </MDButton>

                    </MDBox>
                  </AccordionDetails>
                </Accordion>

              ))}
            </div>
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={openreq} onClose={handleClosereq}>

        <DialogContent style={{ width: 560, height: 330 }}>
          <Typography>
            <text style={{ color: "#FF00FF" }}>
              {changeReqTable && <DataTable
                table={changeReqTable}
                showTotalEntries={false}
                entriesPerPage={false}
              />}
            </text>
          </Typography>

          {/* <MDBox mb={2}>
            <Autocomplete
              {...defaultPropsCategory}
              value={selectcategory}
              onChange={(event, newValue) => {
                // handleChange(event);


                setSelectCategory(newValue);
                item.category = newValue.name;


              }}
              renderInput={(params) => (
                <MDInput
                  {...params}

                  fullWidth
                  label="Medicine Description"
                />
              )}
            />
          </MDBox>
          <MDBox mb={2}>

            <MDInput

              type="text"
              label="UOM"

              fullWidth
            // value={duration}
            // onChange={(e) => setDuration(e.target.value)}
            />

          </MDBox>

          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropsCategory}
              value={selectcategory}
              onChange={(event, newValue) => {
                // handleChange(event);


                setSelectCategory(newValue);
                item.category = newValue.name;


              }}
              renderInput={(params) => (
                <MDInput
                  {...params}

                  fullWidth
                  label="Frequency"
                />
              )}
            />
          </MDBox>

          <MDBox mb={2}>

            <MDInput
              type="text"
              label="Duration"

              fullWidth
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />

          </MDBox>


          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Dose"
              fullWidth

              value={dose}
              onChange={(e) => setDose(e.target.value)}

            />

          </MDBox> */}



        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosereq}>Cancel</Button>
          <Button onClick={handleRequest}>Submit</Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
};

export default PrescriptionQueue;