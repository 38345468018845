import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd, MdOutlinePreview } from "react-icons/md";
import { Link } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, TextField } from "@mui/material";

const Doclabresults = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [color] = useState("#344767");

  const [item, setItem] = useState({});
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist);
  const [mmuCodeList, setmmuCodeList] = useState(null);
  const [mmuvalue, setMmuvalue] = useState(null);

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  const dataObj = {
    columns: [
      { Header: "ptient id", accessor: "name" },

      { Header: "Prescription Id", accessor: "uom" },
      { Header: "current stock", accessor: "currentstock" },
      { Header: "Doctor", accessor: "itemtype" },
      { Header: "test date", accessor: "mmucode" },

      { Header: "action", accessor: "action" }
    ],
    rows: [],
  };

  const [tableDatas, setTableDatas] = useState(dataObj);


  const defaultPropsprojectMMU = {
    options: !mmuList ? [{ name: "Loading...", id: 0 }] : mmuList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };





  async function getLabresult(mmucode) {
    console.log("88", mmucode);

    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "ptient id", accessor: "name" },

        { Header: "Prescription Id", accessor: "uom" },
        { Header: "current stock", accessor: "currentstock" },
        { Header: "Doctor", accessor: "itemtype" },
        { Header: "test date", accessor: "mmucode" },

        { Header: "action", accessor: "action" }
      ],
      rows: [],
    };


    await axios
      .get(
        `patient-service/api/patient/v1/get?mmucode=${mmucode}`
      )
      .then((response) => {
        response.data.data.dtoList.map((item) => {
          item.action = (
            <span>
              <Link to={{}}>
                <MdOutlinePreview
                  style={iconStyle}
                  onClick={() => {
                    setOpen(true);

                  }}
                />
              </Link>


            </span>
          );
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Lab-Result
                  </MDTypography>
                </span>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      style={{ marginLeft: "20px" }}
                      sx={{ width: 300 }}
                      {...defaultPropsprojectMMU}
                      value={mmuvalue}
                      onChange={(event, newValue) => {
                        getLabresult(newValue.code)
                        setMmuvalue(newValue);
                        console.log(newValue);
                      }}

                      renderInput={(params) => (
                        <TextField {...params} label="Select MMU" />
                      )}
                    />
                  </Grid>

                </Grid>


              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      <Dialog open={open} onClose={handleClose}>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={10}  >
            <DialogTitle style={{textAlign:"center" }}>Lab Result</DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: "12px" }}  >
            <span>
              <Link to={{}}>
                <CancelIcon
                  style={iconStyle}
                  fontSize="large"
                  onClick={handleClose}
                />
              </Link>

            </span>
          </Grid>
        </Grid>

        <DialogContent style={{ width: 450, height: 220 }}>



          <h5 style={{ margin: "1px" }} >
            Lab Technician :
          </h5>
          <h5 style={{ margin: "1px" }} >
            Albumin Globulin
            ratio :
          </h5>


          <Grid item xs={12} sm={11}>
            <h5>
              Name :
            </h5>
          </Grid>

          <Grid item xs={12} sm={11}>
            <h5 style={{ margin: "3px", }}>
              UOM :
            </h5>
          </Grid>

          <Grid item xs={12} sm={11}>
            <h5 style={{ margin: "3px", }}>
              Range :
            </h5>
          </Grid>
          <Grid item xs={12} sm={11}>
            <h5 style={{ margin: "3px", }}>
              value :
            </h5>
          </Grid>

        </DialogContent>

      </Dialog>
      <Footer />
    </DashboardLayout>
  );
};






export default Doclabresults;
