/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import { Autocomplete, TextField } from "@mui/material";
import { BarLoader } from "react-spinners";

import moment from "moment-timezone";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { CSVLink } from "react-csv";

function PrescriptionReport() {
  const { sales, tasks, patient, prescription } = reportsLineChartData;
  const [barData, setBarData] = useState(reportsBarChartData);
  const [patientData, setPatientData] = useState(patient);
  const [presData, setPresData] = useState(prescription);
  const [totalVisits, setTotalVisits] = useState(0);
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalPres, setTotalPres] = useState(0);
  const [filterMmu, setFilterMmu] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [datas, setDatas] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [filename, setFilename] = useState("Prescription_Repot.csv");

  //   const [fendDate, setfEndDate] = useState(moment(endDate).format("MM/DD/YYYY"));
  //   const [fstartDate, setfStartDate] = useState(moment(startDate).format("MM/DD/YYYY"));
  //   console.log("totalDays", fendDate)
  //   console.log("totalDays", fstartDate)
  //   const [Difference_In_Time , setDifference_In_Time] = useState(new Date(fendDate).getTime - new Date(fstartDate).getTime);
  //   const [Difference_In_Days , setDifference_In_Days] = useState(Difference_In_Time / (1000 * 3600 * 24));
  //   const [totalDays, setTotalDays] = useState(Difference_In_Days);
  //   console.log("totalDays", totalDays)

  const [color] = useState("#344767");
  const [days, setDays] = useState(null);
  const [projectDetail, setProjectDetail] = useState(
    localStorage.getItem("project-Detail")
  );
  const [mmuList, setMmuList] = useState(
    projectDetail ? JSON.parse(projectDetail).mmulist : []
  );
  const [projectList, setProjectList] = useState(
    projectDetail ? JSON.parse(projectDetail).projectlist : []
  );
  const dataObj = {
    columns: [
      { Header: "prescription id", accessor: "presid" },
      { Header: "patientid", accessor: "patientid" },
      { Header: "name", accessor: "name" },

      { Header: "age", accessor: "age" },
      { Header: "doctor", accessor: "doctor" },
      { Header: "diagnosis", accessor: "diagnosistext" },
      { Header: "chief complaint", accessor: "chiefcomplaint" },
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  const [projectValue, setProjectValue] = useState(null);
  const [mmuvalue, setMmuvalue] = useState(null);
  const [engagementList, setEngagementList] = useState();

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography
          variant="body1"
          color="info"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  async function getEngagementData(projectCode) {
    await axios
      .get(
        `/visualization-service/api/visualization/v1//project/getTotalPatients?projectCodeList=${projectCode}`
      )
      .then((response) => {
        console.log("93", response.data);
        setEngagementList(response.data);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getVisitCount(mmucode) {
    await axios
      .get(
        `/visualization-service/api/visualization/v1/getTotalVisit?mmuCodeList=${mmucode}`
      )
      .then((response) => {
        console.log("106", response.data);
        setTotalVisits(response.data);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getBarData(mmucode) {
    await axios
      .get(
        `/visualization-service/api/visualization/v1/getCoverage?template=BARCHART001&mmuCodeList=${mmucode}`
      )
      .then((response) => {
        console.log("119", response.data);
        setBarData(response.data);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getPresData(mmucode) {
    await axios
      .get(
        `/visualization-service/api/visualization/v1/getLine?template=LINECHART002&mmuCodeList=${mmucode}&type=Prescription`
      )
      .then((response) => {
        console.log("149", response.data);
        setPresData(response.data);
        var totalCount = 0;
        response.data?.datasets?.data.map((count) => {
          totalCount = totalCount + parseInt(count, 10);
        });
        setTotalPres(totalCount);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getMmu(projectCode) {
    await axios
      .get(`/project-service/api/ProjectMMU/v1/get?projectcode=${projectCode}`)
      .then((response) => {
        console.log("149", response.data);
        setFilterMmu(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  function getMmuCode() {
    var mmucode = "";
    var projectCode = "";
    // if(mmuList.length > 0)
    mmuList.map((item) => {
      if (mmucode === "") {
        mmucode = mmucode + item.code;
      } else {
        mmucode = mmucode + "," + item.code;
      }
    });
    projectList.map((item) => {
      if (projectCode === "") {
        projectCode = projectCode + item.code;
      } else {
        projectCode = projectCode + "," + item.code;
      }
    });
    console.log("144", projectCode);
    getEngagementData(projectCode);
    getBarData(mmucode);
    getPatientData(mmucode);
    getPresData(mmucode);
    getVisitCount(mmucode);
  }
  async function getPatientDataByMmu(mmuCode) {
    setLoading(true);
    await axios
      .get(
        `/visualization-service/api/visualization/v2/getLine?template=LINECHART002&mmucode=${mmuCode}&type=Prescription`
      )
      .then((response) => {
        console.log("132", response.data);
        setPatientData(response.data);
        var totalCount = 0;
        response.data?.datasets?.data.map((count) => {
          totalCount = totalCount + parseInt(count, 10);
        });
        setTotalPatients(totalCount);
        const dataObj = {
          columns: [
            { Header: "prescription id", accessor: "presid" },
            { Header: "patientid", accessor: "patientid" },
            { Header: "name", accessor: "name" },

            { Header: "age", accessor: "age" },
            { Header: "doctor", accessor: "doctor" },
            { Header: "diagnosis", accessor: "diagnosistext" },
            { Header: "chief complaint", accessor: "chiefcomplaint" },
          ],
          rows: response.data?.objects,
        };
        setHeaders([
            { label: "prescription id", key: "presid" },
            { label: "patientid", key: "patientid" },
            { label: "name", key: "name" },
            { label: "age", key: "age" },
            { label: "doctor", key: "doctor" },
            { label: "diagnosis", key: "diagnosistext" },
            { label: "chief complaint", key: "chiefcomplaint" },
           
          ]);
          setDatas(response.data?.objects);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }
  async function getPatientDataByProject(projectCode) {
    setLoading(true);
    await axios
      .get(
        `/visualization-service/api/visualization/v2/getLineByProject?template=LINECHART002&projectCode=${projectCode}&type=Prescription`
      )
      .then((response) => {
        console.log("132", response.data);
        setPatientData(response.data);
        var totalCount = 0;
        response.data?.datasets?.data.map((count) => {
          totalCount = totalCount + parseInt(count, 10);
        });
        setTotalPatients(totalCount);
        const dataObj = {
          columns: [
            { Header: "prescription id", accessor: "presid" },
            { Header: "patientid", accessor: "patientid" },
            { Header: "name", accessor: "name" },

            { Header: "age", accessor: "age" },
            { Header: "doctor", accessor: "doctor" },
            { Header: "diagnosis", accessor: "diagnosistext" },
            { Header: "chief complaint", accessor: "chiefcomplaint" },
          ],
          rows: response.data?.objects,
        };
        setHeaders([
            { label: "prescription id", key: "presid" },
            { label: "patientid", key: "patientid" },
            { label: "name", key: "name" },
            { label: "age", key: "age" },
            { label: "doctor", key: "doctor" },
            { label: "diagnosis", key: "diagnosistext" },
            { label: "chief complaint", key: "chiefcomplaint" },
           
          ]);
          setDatas(response.data?.objects);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }
  async function getPatientDataByProjectV2(projectCode, startDate, endDate) {
    setLoading(true);
    await axios
      //   .get(
      //     `/visualization-service/api/visualization/v2/getData?template=LINECHART002&projectCode=${projectCode}&type=Prescription&currentDate=${date}&days=${days}`
      //   )
      .get(
        `/visualization-service/api/visualization/v2/getData?template=LINECHART002&projectCode=${projectCode}&type=Prescription&startDate=${startDate}&endDate=${endDate}`
      )
      //   .get(
      //     `http://localhost:9009/api/visualization/v2/getData?template=LINECHART002&projectCode=${projectCode}&type=Prescription&startDate=${startDate}&endDate=${endDate}`
      //   )
      .then((response) => {
        console.log("132", response.data);
        setPatientData(response.data);
        var totalCount = 0;
        response.data?.datasets?.data.map((count) => {
          totalCount = totalCount + parseInt(count, 10);
        });
        setTotalPatients(totalCount);
        const dataObj = {
          columns: [
            { Header: "prescription id", accessor: "presid" },
            { Header: "patientid", accessor: "patientid" },
            { Header: "name", accessor: "name" },

            { Header: "age", accessor: "age" },
            { Header: "doctor", accessor: "doctor" },
            { Header: "diagnosis", accessor: "diagnosistext" },
            { Header: "chief complaint", accessor: "chiefcomplaint" },
          ],
          rows: response.data?.objects,
        };
        setHeaders([
            { label: "prescription id", key: "presid" },
            { label: "patientid", key: "patientid" },
            { label: "name", key: "name" },
            { label: "age", key: "age" },
            { label: "doctor", key: "doctor" },
            { label: "diagnosis", key: "diagnosistext" },
            { label: "chief complaint", key: "chiefcomplaint" },
           
          ]);
          setDatas(response.data?.objects);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }
  async function getPatientDataByMmuV2(mmuCode, startDate, endDate) {
    setLoading(true);
    await axios

      //   .get(
      //     `/visualization-service/api/visualization/v2/getData?template=LINECHART002&mmucode=${mmuCode}&type=Prescription&currentDate=${date}&days=${days}`
      //   )
      .get(
        `/visualization-service/api/visualization/v2/getData?template=LINECHART002&mmucode=${mmuCode}&type=Prescription&startDate=${startDate}&endDate=${endDate}`
      )
      //   .get(
      //     `http://localhost:9009/api/visualization/v2/getData?template=LINECHART002&mmucode=${mmuCode}&type=Prescription&startDate=${startDate}&endDate=${endDate}`
      //   )
      .then((response) => {
        console.log("132", response.data);
        setPatientData(response.data);
        var totalCount = 0;
        response.data?.datasets?.data.map((count) => {
          totalCount = totalCount + parseInt(count, 10);
        });
        setTotalPatients(totalCount);
        const dataObj = {
          columns: [
            { Header: "prescription id", accessor: "presid" },
            { Header: "patientid", accessor: "patientid" },
            { Header: "name", accessor: "name" },

            { Header: "age", accessor: "age" },
            { Header: "doctor", accessor: "doctor" },
            { Header: "diagnosis", accessor: "diagnosistext" },
            { Header: "chief complaint", accessor: "chiefcomplaint" },
          ],
          rows: response.data?.objects,
        };
        setHeaders([
            { label: "prescription id", key: "presid" },
            { label: "patientid", key: "patientid" },
            { label: "name", key: "name" },
            { label: "age", key: "age" },
            { label: "doctor", key: "doctor" },
            { label: "diagnosis", key: "diagnosistext" },
            { label: "chief complaint", key: "chiefcomplaint" },
           
          ]);
          setDatas(response.data?.objects);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }
  function handleFilter() {
    // console.log("filter", mmuvalue.mmu?.code);
    // if (mmuvalue == null && projectValue != null && days != null) {
    //   console.log("proje");
    //   getPatientDataByProjectV2(projectValue.code, currentDate, days.day);
    // } else if (mmuvalue != null && days != null) {
    //   console.log("mmu");
    //   getPatientDataByMmuV2(mmuvalue.mmu?.code, currentDate, days.day);
    // } else {
    //   console.log("nothing");
    // }

    if (mmuvalue == null && projectValue != null) {
      console.log("proje");
      getPatientDataByProjectV2(projectValue.code, startDate, endDate);
    } else if (mmuvalue != null) {
      console.log("mmu");
      getPatientDataByMmuV2(mmuvalue.mmu?.code, startDate, endDate);
    } else {
      console.log("nothing");
    }
  }

  const defaultPropsProject = {
    options: !projectList ? [{ name: "Loading...", id: 0 }] : projectList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsmmuCode = {
    options: !filterMmu ? [{ mmu: { name: "Loading..." }, id: 0 }] : filterMmu,
    getOptionLabel: (option) => option.mmu?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsDays = {
    options: [
      { day: "1", uom: "day", code: "1" },
      { day: "2", uom: "days", code: "2" },
      { day: "3", uom: "days", code: "3" },
      { day: "5", uom: "days", code: "5" },
      { day: "7", uom: "days", code: "7" },
      { day: "10", uom: "days", code: "10" },
    ],
    getOptionLabel: (option) => option.day + " " + option.uom,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  const csvReport = {
    data: datas,
    headers: headers,
    filename: filename,
  };

  useEffect(() => {
    // getMmuCode();

    console.log("138", mmuList);
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <BarLoader
          color={color}
          loading={loading}
          aria-label="Loading Spinner"
        />

        <Grid container spacing={3} my={1}>
          <Grid item xs={4} sm={3}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // sx={{ width: 280 }}
              {...defaultPropsProject}
              value={projectValue}
              onChange={(event, newValue) => {
                setProjectValue(newValue);
                getPatientDataByProject(newValue.code);
                getMmu(newValue.code);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Project" />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // sx={{ width: 280, marginLeft: "24px" }}
              {...defaultPropsmmuCode}
              value={mmuvalue}
              onChange={(event, newValue) => {
                console.log(newValue);
                setMmuvalue(newValue);
                getPatientDataByMmu(newValue.mmu?.code);
                // getDataBymmu(newValue?.code);
                // setMmuvalue(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select MMU" />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={2}>
            <MDInput
              type="date"
              label="Select Date"
              value={currentDate}
              onChange={(e) => setCurrentDate(e.target.value)}
            />
          </Grid> */}
          {/* <Grid container spacing={3} my={1}> */}
          <Grid item xs={12} sm={2}>
            <MDInput
              type="date"
              label="Select Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <MDInput
              type="date"
              label="Select End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Grid>
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              id="days"
              {...defaultPropsDays}
              value={days}
              onChange={(event, newValue) => {
                console.log(newValue);
                setDays(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Days" />
              )}
            />
          </Grid> */}
          <Grid item xs={12} sm={1}>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={handleFilter}
            >
              Filter
            </MDButton>
          </Grid>
        </Grid>

        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="prescription"
                  description="Campaign Performance"
                  date="just updated"
                  chart={patientData}
                />
                <MDBox m={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"

                  // onClick={handleFilter}
                >
                  <CSVLink {...csvReport} style={{ color: "white" }}>
                    Export to CSV
                  </CSVLink>
                </MDButton>
              </MDBox>
                <DataTable table={tableDatas} canSearch={true} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PrescriptionReport;
