import * as Yup from "yup";
import checkout from "layouts/pages/users/new-user/schemas/form";

const {
  formField: {
    firstName,
    // lastName,
    username,
    email,
    password,
    role,
    repeatPassword,
   
    phonenumber,
    zip,
    twitter,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    // [lastName.name]: Yup.string().required(lastName.errorMsg),
    [username.name]: Yup.string().required(username.errorMsg),
    [role.name]: Yup.string().required(role.errorMsg),
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(6, password.invalidMsg),
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(6, password.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  }),
  Yup.object().shape({
  
    [phonenumber.name]: Yup.string()
      .required(phonenumber.errorMsg)
      .min(10, phonenumber.invalidMsg)
      .max(10, phonenumber.invalidMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
 
  }),
  Yup.object().shape({
    [twitter.name]: Yup.string().required(twitter.errorMsg),
  }),
];

export default validations;
