/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/pages/Association/new Association/schemas/form"

const {
  formField: { mmu, mmuname, vechicleassigned, vehicleNo,mmudescription, profile,equipmentassigned,warehouse,role},
} = checkout;

const initialValues = {
  [mmu.name]: "",
  [mmuname.name]: "",
  [vechicleassigned.name]: "",
  // [vehicleNo.name]: "",
  [mmudescription.name]: "",
  [equipmentassigned.name]: "",
  [warehouse.name]: "",
  [role.name]: "",
  [profile.name]: "",
  
 
};

export default initialValues;
