import * as Yup from "yup";
import checkout from "layouts/pages/PincodeProject/new Pincode/schemas/form";

const {
  formField: {
    createproject,
    projectselection,
    state,
    district,
    subDistrict,
    pincode,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    // [createproject.name]: Yup.string().required(createproject.errorMsg),
    // [projectselection.name]: Yup.string().required(projectselection.errorMsg),
  }),
];

export default validations;
