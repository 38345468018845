/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
;
import MDInput from "components/MDInput";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import MDTypography from "components/MDTypography";
import axios from "axios";
// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import lottie from "lottie-web";
import MDButton from "components/MDButton";
import swal from 'sweetalert';
// popup
import Button from '@mui/material/Button';
import { Formik, useFormik } from "formik";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { validationdata } from "./schemas/index"
import DialogTitle from '@mui/material/DialogTitle';
const initialValues = {
  current_password: "",
  new_password: "",
  confirm_password: "",

}

function Overview() {
  const [open, setOpen] = React.useState(false);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({

    initialValues: initialValues,
    validationSchema: validationdata,
    onSubmit: (values, action) => {

      const getData = {
        username: JSON.parse(localStorage.getItem("mmu-auth")).username,
        password: values.new_password.trim(),
        resourcecode: "DPF",

        approvedby: "",
        approveddate: "",
        classname: "",
        createdby: "",
        createddate: null,
        documentofproof: "",
        id: null,
        latlong: "",
        location: null,
        modifiedby: "",
        modifieddate: null,

        recstatus: "OPEN",
        status: "ACTIVE",
        syncstatus: "SYNCED",

      };

      axios
        .post(
          "profile-service-mongo/api/ProfileEntity/v2/setPassword",
          getData
        )

        .then((response) => {
          console.log("gfgfgf", response);
          swal("Alert!", "...your password has been sucessfully update!", "success")
            .then((value) => {
              // window.location.reload(true)
              action.resetForm();

            });



        })
        .catch((error) => {
          console.log(error);


        });


    }

  })

  const container = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,
      animationData: require('./data/ambulance.json'),
    });
  }, []);





  const handleClose = () => {

    setOpen(false);
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"

                info={{
                  fullName: localStorage.getItem("mmu-auth")
                    ? JSON.parse(localStorage.getItem("mmu-auth")).firstname +
                    " " +
                    JSON.parse(localStorage.getItem("mmu-auth")).lastname
                    : "",

                  mobile: localStorage.getItem("mmu-auth") ? JSON.parse(localStorage.getItem("mmu-auth")).phoneno : "",
                  email: localStorage.getItem("mmu-auth") ? JSON.parse(localStorage.getItem("mmu-auth")).emailid : "",
                  location: "INDIA",
                }}
                social={[

                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={10} xl={7} mt={-7} >
              <Grid container spacing={9}>
                <Grid item xs={12} sm={9}>
                  <MDTypography variant="h4" fontWeight="medium" color="blue" >
                    Healthcare to the Doorstep
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MDButton
                    variant="gradient" color="dark" size="small"
                    type="submit"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    changePassword
                  </MDButton>
                </Grid>

                <MDBox
                  ref={container}>
                </MDBox>


                {/* <ProfilesList title="conversations" profiles={profilesListData} shadow={false} /> */}
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
        {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox>
        </MDBox> */}
        {/* <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor1}
                label="project #2"
                title="modern"
                description="As Uber works through a huge amount of internal management turmoil."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor2}
                label="project #1"
                title="scandinavian"
                description="Music is something that everyone has their own specific opinion about."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor3}
                label="project #3"
                title="minimalist"
                description="Different people have different taste, and various types of music."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor4}
                label="project #4"
                title="gothic"
                description="Why would anyone pick blue over pink? Pink is obviously a better color."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
          </Grid>
        </MDBox> */}
      </Header>


      <Dialog open={open} onClose={handleClose}>

        <DialogTitle style={{ textAlign: "center" }}>Update Password</DialogTitle>
        <DialogContent style={{ width: 450, height: 270 }}>
          <form onSubmit={handleSubmit}>
            <MDBox pb={3} px={3}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} mt={1}>
            <MDInput
              fullWidth 
              name="current_password"
              id="current_password"
              type="password"
              label="Current Password"
              autoComplete="off"
              value={values.current_password}
             onBlur={handleBlur}
              onChange={handleChange}
            />
            
             {errors.current_password && touched.current_password? 
             (<p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.current_password}</p>):null}
          </Grid> */}
                <Grid item xs={12}>
                  <MDInput
                    fullWidth
                    name="new_password"
                    id="new_password"
                    type="password"
                    label="New Password"
                    autoComplete="off"
                    value={values.new_password}
                    onBlur={handleBlur}
                    onChange={handleChange}

                  />
                  {errors.new_password && touched.new_password ?
                    (<p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.new_password}</p>) : null}
                </Grid>
                <Grid item xs={12}>
                  <MDInput
                    fullWidth
                    name="confirm_password"
                    id="confirm_password"
                    type="password"
                    autoComplete="off"
                    label="Confirm New Password"
                    value={values.confirm_password}
                    onBlur={handleBlur}
                    onChange={handleChange}

                  />

                  {errors.confirm_password && touched.confirm_password ?
                    (<p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.confirm_password}</p>) : null}
                </Grid>
                <Grid item xs={12}>
                  {/* <MDBox ml="auto"  >
          <MDButton variant="gradient" color="dark" size="small" onClick={handleClose}>
             Cancel
            </MDButton>
            
          </MDBox> */}
                  <MDBox mt={-2} ml={27}  >
                    <MDButton variant="gradient" color="dark" size="small" type="submit"
                    // onClick={updatePassword}
                    >
                      update password
                    </MDButton>
                  </MDBox>


                </Grid>
              </Grid>
              {/* <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">Password requirements</MDTypography>
        </MDBox> */}
              {/* <MDTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </MDBox> */}

            </MDBox>
          </form>
        </DialogContent>
        {/* <DialogActions>
        <Button >Cancel</Button>
        
        </DialogActions> */}

      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;

