/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-vitals-form",
  formField: {
    vitalsName: {
      name: "vitalsName",
      label: "VitalsName",
      type: "text",
      errorMsg: "vitalsName name is required.",
    },
    vitalsType: {
      name: "vitalsType",
      label: "vitalsType",
      type: "number",
      errorMsg: "vitalsType is required.",
    },
    vitalrange: {
      name: "vitalrange",
      label: "Vital Range",
      type: "text",
      errorMsg: "vitalrange is required.",
    },
    uom:{
      name: "uom",
      label: "Uom",
      type: "text",
      errorMsg: "uom is required.",
    }
  
  },
};

export default form;
