// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/Village/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function VillageInfo({ formData }) {
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");

  const [hierarchicalCode, setHierarchicalCode] = React.useState(null);
  const [value, setValue] = React.useState(null);

  const [stateList, setStateList] = useState("");
  const [districtList, setDistrictList] = useState("");
  const [subDistrictList, setSubDistrictList] = useState("");
  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setDistrictValue] = useState(null);
  const [subDistrictValue, setSubDistrictValue] = useState(null);
  const { formField, values, errors, touched } = formData;

  const defaultPropsState = {
    options: !stateList
      ? [
          {
            state: {
              shortname: "- -",
              name: "Loading...",
            },
            id: 0,
          },
        ]
      : stateList,
    getOptionLabel: (option) =>
      option.state?.name + " (" + option.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const districtDefaultProp = {
    options: !districtList
      ? [
          {
            district: {
              shortname: "- -",
              name: "Loading...",
            },
            id: 0,
          },
        ]
      : districtList,

    getOptionLabel: (option) =>
      option.district?.name + " (" + option.district?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const subDistrictDefaultProp = {
    options: !subDistrictList
      ? [
          {
            subdistrict: {
              shortname: "- -",
              name: "Loading...",
            },
            id: 0,
          },
        ]
      : subDistrictList,

    getOptionLabel: (option) =>
      option.subdistrict?.name + " (" + option.subdistrict?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const {
    state,
    district,
    subDistrict,
    villageCode,
    shortName,
    villageName,
    pincode,
  } = formField;
  var {
    state: stateV,
    district: districtV,
    subDistrict: subDistrictV,
    villageCode: villageCodeV,
    shortName: shortNameV,
    villageName: villageNameV,
    pincode: pincodeV,
  } = values;

  async function getDistrictData(stateCode) {
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${stateCode}`
      )
      .then((response) => {
        setDistrictList(response.data.data.dtoList);
        //  setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getSubDistrictData(districtCode) {
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&district.code=${districtCode}`
      )
      .then((response) => {
        setSubDistrictList(response.data.data.dtoList);
        //  setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getData() {
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=state`
      )
      .then((response) => {
        setStateList(response.data.data.dtoList);
        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Village Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsState}
                value={stateValue}
                onChange={(event, newValue) => {
                  // handleChange(event);
                  setStateValue(newValue);
                  // setValue(newValue);
                  getDistrictData(newValue?.state?.code);
                  values.state = newValue?.state;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.state && touched.state}
                    success={state.length > 0 && !errors.state}
                    variant="standard"
                    label={state.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...districtDefaultProp}
                value={districtValue}
                onChange={(event, newValue) => {
                  setDistrictValue(newValue);
                  getSubDistrictData(newValue?.district?.code);
                  values.district = newValue.district;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.district}
                    success={district.length > 0 && !errors.district}
                    variant="standard"
                    label={district.label}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...subDistrictDefaultProp}
                value={subDistrictValue}
                onChange={(event, newValue) => {
                  // handleChange(event);
                  setSubDistrictValue(newValue);
                  // setValue(newValue);

                  values.subDistrict = newValue?.subdistrict;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.subDistrict && touched.subDistrict}
                    success={subDistrict.length > 0 && !errors.subDistrict}
                    variant="standard"
                    label={subDistrict.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={villageName.type}
                label={villageName.label}
                name={villageName.name}
                value={villageNameV}
                placeholder={villageName.placeholder}
                error={errors.villageName && touched.villageName}
                success={villageNameV?.length > 0 && !errors.villageName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={shortName.type}
                label={shortName.label}
                name={shortName.name}
                value={shortNameV}
                placeholder={shortName.placeholder}
                error={errors.shortName && touched.shortName}
                success={shortNameV?.length > 0 && !errors.shortName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={villageCode.type}
                label={villageCode.label}
                name={villageCode.name}
                value={villageCodeV}
                placeholder={villageCode.placeholder}
                error={errors.villageCode && touched.villageCode}
                success={villageCodeV.length > 0 && !errors.villageCode}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={pincode.type}
                label={pincode.label}
                name={pincode.name}
                value={pincodeV}
                placeholder={pincode.placeholder}
                error={errors.pincode && touched.pincode}
                success={pincodeV?.length > 0 && !errors.villageCode}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for VillageInfo
VillageInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default VillageInfo;
