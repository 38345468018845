import * as Yup from "yup";
import checkout from "layouts/pages/Village/schemas/form";

const {
  formField: {
    state,
    district,
    subDistrict,
    villageCode,
    shortName,
    villageName,
    pincode,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    // [state.name]: Yup.string().required(state.errorMsg),
    // [district.name]: Yup.string().required(district.errorMsg),
    [villageCode.name]: Yup.string().required(villageCode.errorMsg),
    [shortName.name]: Yup.string().required(shortName.errorMsg),
    [villageName.name]: Yup.string().required(villageName.errorMsg),
   // [pincode.name]: Yup.string().required(pincode.errorMsg),
  }),
];

export default validations;
