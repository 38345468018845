
import React, { useState, useEffect } from 'react';
import "./DataContainer.css"
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { Card, Grid } from '@mui/material';
import axios from "axios"
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { BarLoader } from "react-spinners";


const Chatus = () => {
  const [messages, setMessages] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [text, setText] = useState("");
const [loading,setLoading]=useState(false)
const [color] = useState("#8c0073");
  

  const toggleSummary = () => {
    setShowSummary(!showSummary);
  };

  const handleSend = () => {
    const input = document.querySelector('.chat-input input');
    const text = input.value.trim();
    if (text !== '') {
      const newMessage = {
        id: Date.now(),
        sender: 'user',
        text: text,
      };
      setMessages([...messages, newMessage]);
      input.value = '';
    }
  };



async  function data(question) {
setLoading(true)
    console.log(question)
    const object = {
      question: question
    }
    console.log("object",object)
   await axios.post(
       "https://smaiapi.softwaremathematics.com/predict/", object
     

      )

      .then((response) => {
        console.log("gfgfgf", response?.data?.answer);
         setText(response?.data?.answer);
         setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        alert(error.message)
        setLoading(false)
   
      });

  }


 
  useEffect(() => {

    if (messages.length > 0 && messages[messages.length - 1].sender === 'user') {
       data(messages[messages.length - 1].text)
      console.log("105", text)
      setTimeout(() => {

        if (text !== "") {
          const newMessage = {
            id: Date.now(),
            sender: 'bot',
            text: text,
          };
       
          setMessages([...messages, newMessage]);

        }
        setText("");

      }, 1000);
    }
  }, [messages,text]);
  const [ask, setAsk] = useState('');
  const [typingIndex, setTypingIndex] = useState(0);

  useEffect(() => {
  
     const message = "Ask me about 5G"
    const interval = setInterval(() => {
      if (typingIndex < message.length) {
        setAsk((prevText) => prevText + message[typingIndex]);
        setTypingIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [typingIndex]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h4 style={{ textAlign: "center", color: "#1A73E8" }}>{ask}</h4>
      <Card>
     

        <div className="chat-container">
          <div className="chat-messages">
         
            { messages.map((message) => (
              <div key={message.id} className={`message ${message.sender === 'user' ? 'user' : 'bot'}`}>
                {message.text}
              </div>
            ))}
            <BarLoader
                    color={color}
                   
                    loading={loading}
                    aria-label="Loading Spinner"
                />
               
          </div>
          <div className="chat-input">
            <input type="text" placeholder="Type a message" />
            <button onClick={handleSend}>Send</button>
          </div>
        </div>


      </Card>
      <MDBox pt={3}>
        <Card >
          <div className="code-summary">
            <h4 style={{ textAlign: "center" }}>We have made this to give information about 5g</h4>
            
            
              <div className="summary-content">
                <p>
                  5G also known as the 5th generation telecommunication technology that is aimed to deliver higher multi-data speeds, ultra-low latency, increased network capacity and a uniform user experience to multiple users at same time. 5G is up to 100 times faster than 4G in speed and has a high level of performance needed for an increasingly connected society.
                </p>
                <p>
                  Advantages of 5G are

                  Massive network capacity for multiple users at same time.

                  Swift upload and download speed internet access across multiple device and users.

                  Ultra-low latency while downloading and uploading rich content, multimedia, and business applications.

                  Provides high level of performance needed for the internet of things (IOT).

                  to enable 5g on sim you Airtel 4G physical and e-SIMs will be automatically upgraded to 5G however, your device must be 5G compatible.
                </p>
                <p>
                  While 5G is designed to be the next generation of wireless technology, it is not expected to completely replace 4G immediately. Instead, 5G will likely work alongside 4G to offer better overall network performance.

                  5G will only be available in specific locations.
                </p>
              </div>
           
          </div>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};



export default Chatus;
