/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    name: {
      name: "name",
      label: "NAME",
      type: "text",
      errorMsg: "name is required.",
    },

    description: {
      name: "description",
      label: "Description",
      type: "text",
      errorMsg: "description is required.",
    },

    state: {
      name: "state",
      label: "state",
      type: "text",
      errorMsg: "state is required.",
    },
    district: {
      name: "district",
      label: "District",
      type: "text",
      errorMsg: "district is required.",
    },
    subDistrict: {
      name: "subDistrict",
      label: "Sub-District",
      type: "text",
      errorMsg: "subDistrict is required.",
    },

    pincode: {
      name: "pincode",
      label: "pincode",
      type: "number",
      errorMsg: "pincode is required.",
    },
    warehouse: {
      name: "warehouse",
      label: "warehouse",
      type: "text",
      errorMsg: "warehouse is required.",
    },

    projecthirarichalcode: {
      name: "parenthierachical",
      label: "Parent hiearachical",
      type: "text",
      errorMsg: "parenthierachical is required.",
    },
  },
};

export default form;
