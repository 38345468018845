// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/Lab/new-lab/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function DistricInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const [stateValue, setStateValue] = useState(null);
  const [stateList, setStateList] = useState("");
  const [districValue, setDistricValue] = React.useState(null);
  const [districList, setDistricList] = useState("");
  const { formField, values, errors, touched } = formData;

  const defaultPropsState = {
    options: !stateList
      ? [
          {
            state: {
              shortname: "- -",
              name: "Loading...",
            },
            id: 0,
          },
        ]
      : stateList,
    getOptionLabel: (option) =>
      option.state?.name + " (" + option.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const { districCode, shortname, districtname, state } = formField;
  var {
    districCode: districCodeV,

    shortname: shortnameV,

    districtname: districtnameV,
  } = values;

  async function getData() {
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=state`
      )
      .then((response) => {
        setStateList(response.data.data.dtoList);
        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">District master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsState}
                value={stateValue}
                onChange={(event, newValue) => {
                  setStateValue(newValue);

                  values.state = newValue?.state;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.state && touched.state}
                    success={state.length > 0 && !errors.state}
                    variant="standard"
                    label={state.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={districtname.type}
                label={districtname.label}
                name={districtname.name}
                value={districtnameV}
                placeholder={districtname.placeholder}
                error={errors.districtname && touched.districtname}
                success={districtnameV.length > 0 && !errors.districtname}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={shortname.type}
                label={shortname.label}
                name={shortname.name}
                value={shortnameV}
                placeholder={shortname.placeholder}
                error={errors.shortname && touched.shortname}
                success={shortnameV.length > 0 && !errors.shortname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={districCode.type}
                label={districCode.label}
                name={districCode.name}
                value={districCodeV}
                placeholder={districCode.placeholder}
                error={errors.districCode && touched.districCode}
                success={districCodeV.length > 0 && !errors.districCode}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for DistricInfo
DistricInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DistricInfo;
