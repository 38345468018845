import checkout from "layouts/pages/Test/new test/schemas/form";
const {
  formField: {
    name,
    testname,
    investigationname,
    range,
    AssignEquipment,
    mapware,
    EquipmentList,
  },
} = checkout;

const initialValues = {
  [name.name]: "",
  [testname.name]: "",
  // [EquipmentList.name]: "",

  // [range.name]: "",
  [investigationname.name]: "",
  // [mapware.name]: "",
};

export default initialValues;
