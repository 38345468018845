import * as Yup from "yup";
import checkout from "layouts/pages/Requsition/new Requsition/schemas/form";

const {
  formField: {},
} = checkout;

const validations = [
  Yup.object().shape({
    // [WHID.name]: Yup.string().required(WHID.errorMsg),
    // [mmuCode.name]: Yup.string().required(mmuCode.errorMsg),
    //  [quantityRqst.name]: Yup.string().required(quantityRqst.errorMsg),
    // [AssignEquipment.name]: Yup.string().required(AssignEquipment.errorMsg),
    // [MMUItemCode.name]: Yup.string().required(MMUItemCode.errorMsg),
    // [mapware.name]: Yup.string().required(mapware.errorMsg),
  }),
];

export default validations;
