// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { item } from "examples/Sidenav/styles/sidenavItem";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Accordians from "./Accordians";

function Requisition() {
    const comp = "SM";
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [color] = useState("#344767");
    const [getResponse, setResponse] = useState([]);
    const [approvedQty, setApprovedQty] = useState(0);
    const [givenQty, setGivenQty] = useState("");
    // const [tableStatus, setTableStatus] = useState();

    const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
    const addButtonStyle = {
        display: "flex",
        fontSize: "2em",
        flexDirection: "column",
        marginRight: "10px",
    };
    const tranactionalObj = {
        mmuid: "",
        mrnumber: "",
        ponnumber: "",
        whid: "",
        approvedby: "",
        approveddate: "",
        blockid: "",
        createdby: "",
        createddate: null,
        documentofproof: "",
        flowtype: "",
        id: null,
        itemname: "",
        itemtype: "",
        latlong: "",
        location: null,
        mmucode: "",
        modifiedby: "",
        modifieddate: "",
        quantity: approvedQty,
        referencenumber: "",
        reqLineitemid: "",
        sourcereference: "",
        targetreference: "",
        uom: "",
        recstatus: "OPEN",
        status: "ACTIVE",
        syncstatus: "SYNCED",
    };

    function returnResponse(getResponse) {
        return "";
    }
    function handleSubmit(obj) {
        console.log("obj", obj);
        for (var i = 0; i < getResponse.length; i++) {
            if (getResponse[i].medRequisitionGenTxnDTO.mrnumber === obj.mrnumber) {
                console.log("done", getResponse[i]);
                for (
                    var j = 0;
                    j < getResponse[i].medRequisitionGenDTOList.length;
                    j++
                ) {
                    if (getResponse[i].medRequisitionGenDTOList[j].id === obj.id) {
                        console.log("item", getResponse[i].medRequisitionGenDTOList[j]);
                        getResponse[i].medRequisitionGenDTOList[j].quantityRcvd = givenQty;
                        console.log(getResponse);
                        setResponse(getResponse);
                        getData();
                    }
                }
            }
        }
    }

    async function getData() {
        setLoading(true);

        await axios
            .get(
                `api/MedRequisitionGen/v1/getRequisitionAggregation?recStatus=OPEN`
            )
            .then((response) => {
                response.data.data.medRequisitionAggregationList.map((item) => {
                    const dataObj = {
                        columns: [
                            { Header: "name", accessor: "name", width: "30%" },
                            { Header: "QUANTITY", accessor: "quantityrqst", width: "30%" },
                            { Header: "action", accessor: "action" },
                        ],
                        rows: [],
                    };
                    item.medrequisitiongendtoList.map((i) => {
                        // if (
                        //   i.quantityapproved === i.quantityrcvd &&
                        //   i.quantityapproved !== "" &&
                        //   i.quantityrcvd !== "" &&
                        //   typeof i.quantityapproved !== "undefined" &&
                        //   typeof i.quantityrcvd !== "undefined"
                        // ) {
                        //   i.input = (
                        //     <MDInput
                        //       type="number"
                        //       variant="outlined"
                        //       size="small"
                        //       disabled="true"
                        //       style={{ width: "70px" }}
                        //     />
                        //   );
                        // } else {
                        //   i.input = (
                        //     <MDInput
                        //       type="number"
                        //       variant="outlined"
                        //       size="small"
                        //       onChange={(e) => setGivenQty(e.target.value)}
                        //       style={{ width: "70px" }}
                        //     />
                        //   );
                        // }

                        i.action =
                            item.action = (
                                <span>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        size="small"
                                        value="Submit"
                                        style={{ marginRight: "10px" }}

                                    >
                                        HOLD
                                    </MDButton>

                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        size="small"
                                        value="Submit"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => {
                                            handleSubmit(i);
                                        }}
                                    >
                                        PROCESS
                                    </MDButton>

                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        size="small"
                                        value="Submit"
                                        onClick={() => {
                                            handleSubmit(i);
                                        }}
                                    >
                                        CANCEL
                                    </MDButton>

                                </span>
                            );





                        dataObj.rows.push(i);
                    });

                    item.dataObj = dataObj;
                });
                setResponse(response.data.data.medRequisitionAggregationList);
                console.log("====================================");
                console.log(getResponse);
                console.log(response.data.data.medRequisitionAggregationList);
                console.log("====================================");

                setLoading(false);
            })
            .catch((e) => {
                console.log("error", e);
            });
    }
    useEffect(() => {
        getData();
        // getResponse;
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={3}>
                <MDBox>
                    <Card>
                        <MDBox pl={3} pr={3} lineHeight={1}>
                            <div style={addButtonStyle}>
                                <span
                                    style={{
                                        alignSelf: "flex-start",
                                        marginTop: "20px",
                                    }}
                                >
                                    <MDTypography variant="h5" fontWeight="medium">
                                        Requisition Transaction
                                    </MDTypography>
                                </span>

                                <Link
                                    style={{
                                        alignSelf: "flex-end",
                                        marginRight: "10px",
                                        marginTop: "-23px",
                                        color: "#344767",
                                    }}
                                    to={"pages/stock/create-stock"}
                                >
                                    <MdOutlineLibraryAdd />
                                </Link>
                            </div>
                        </MDBox>
                        {/* <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            /> */}
                        <div>
                            {getResponse.map((values, i) => (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            <div style={{ fontSize: "15px", color: "#7b809a" }}>
                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>
                                                        S.NO :
                                                    </text>
                                                    {i + 1}

                                                </div>
                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>
                                                        INDENT NO :
                                                    </text>
                                                    {" " + values.medrequisitiongentxndto.mrnumber}
                                                </div>

                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>
                                                        MMU :
                                                    </text>
                                                    {" " + values.medrequisitiongentxndto.mmucode}
                                                </div>
                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>
                                                        CREATED BY :
                                                    </text>
                                                    {" " + values.medrequisitiongentxndto.createdby}
                                                </div>

                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>STATUS :</text>
                                                    {" " + values.medrequisitiongentxndto.recstatus}
                                                </div>
                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>CREATED DATE :</text>
                                                    {" " + values.medrequisitiongentxndto.createddate}
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <text style={{ color: "#FF00FF" }}>
                                                <DataTable
                                                    table={values.dataObj}
                                                    showTotalEntries={false}
                                                    entriesPerPage={false}
                                                />
                                            </text>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </Card>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}





export default Requisition