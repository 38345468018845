import React from 'react'
import Powerbi from './Powerbi'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'

const MicroSoftpower = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
    <Powerbi/>
    </DashboardLayout>
  )
}

export default MicroSoftpower