import * as Yup from "yup";

export const validationScehdule = Yup.object({
    // current_password : Yup.string().min(2).max(6).required("please enter valid password"),
    title : Yup.string().min(2).max(25).required("tittle is required field"),
    address : Yup.string().required("address is required field"),
   // startdate : Yup.string().required("startdate is required"),
})
 

