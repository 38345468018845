// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";

// vitals page components
import FormField from "layouts/pages/type/new-type/componets/FormField";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";
import axios from "axios";

function TypeInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [categoryValue, setCategoryValue] = React.useState(null);
  const [categoryList, setCategoryList] = useState("");
  const [categorytypeList, setCategorytypeList] = useState("");
  const [categorytypevalue, setCategorytypeValue] = React.useState(null);
  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const { typeName, typeDescrption, Category, Categorytype } = formField;
  var { typeName: typeNameV, typeDescrption: typeDescrptionV } = values;

  const defaultPropsCategorytype = {
    options: !categorytypeList
      ? [
          { name: "OPTIONS", code: "x" },
          { name: "ADMIN_OPTIONS", code: "y" },
        ]
      : categorytypeList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  const defaultPropsCategory = {
    options: !categoryList
      ? [
          { name: "ITEM", code: "A" },
          { name: "VITAL", code: "B" },
          { name: "COMPDOC", code: "C" },
          { name: "OCCUPATION", code: "D" },
          { name: "EDUCATION", code: "E" },
        ]
      : categoryList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  async function getCategoryTypeData() {
  

    // getting category-type
    await axios
      .get(
        `category-service/api/Category/v1/get?type=CATEGORY_TYPE`
      )
      .then((response) => {
        getCategoryData();
        setCategorytypeList(response.data.data.dtoList);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getCategoryData() {
   
    // getting category
    await axios
      .get(
        `category-service/api/Category/v1/get?type=CATEGORY`
      )
      .then((response) => {
        setCategoryList(response.data.data.dtoList);
        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
  
    getCategoryTypeData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Type Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={typeName.type}
                label={typeName.label}
                name={typeName.name}
                value={typeNameV}
                placeholder={typeName.placeholder}
                error={errors.typeName && touched.typeName}
                success={typeNameV.length > 0 && !errors.typeName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={typeDescrption.type}
                label={typeDescrption.label}
                name={typeDescrption.name}
                value={typeDescrptionV}
                placeholder={typeDescrption.placeholder}
                error={errors.typeDescrption && touched.typeDescrption}
                success={typeDescrptionV.length > 0 && !errors.typeDescrption}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsCategory}
                value={categoryValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setCategoryValue(newValue);

                  values.Category = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.Category && touched.Category}
                    success={Category.length > 0 && !errors.Category}
                    variant="standard"
                    label={Category.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsCategorytype}
                value={categorytypevalue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setCategorytypeValue(newValue);

                  values.Categorytype = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.Categorytype && touched.Categorytype}
                    success={Categorytype.length > 0 && !errors.Categorytype}
                    variant="standard"
                    label={Categorytype.label}
                  />
                )}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for TypeInfo
TypeInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default TypeInfo;
