import * as React from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Autocomplete, FormControlLabel, Grid } from "@mui/material";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// @mui material components

// Data

import { Link } from "react-router-dom";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
//EDit button

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import MDBadgeDot from "components/MDBadgeDot";
function UsersListTable() {
  const [state, setState] = React.useState({ status: true });
  const [loading, setLoading] = useState(true);
  const [tableDatas, setTableDatas] = useState({});
  const [color] = useState("#344767");
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = useState({});
  const [username, setUsername] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstname, setFirstName] = useState("");

  const [roleList, setRoleList] = useState("");
  const [departmentList, setDepartmentList] = useState("");
  const [designationList, setDesignationList] = useState("");
  const [selectrole, setSelectrole] = useState("");
  const [selectdesi, setSelectDesi] = useState("");
  const [selectdep, setSelectdep] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("mmu-auth")));

  const [checked, setChecked] = useState(false);
  const [status, setStatus] = useState(false);

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const defaultProps = {
    options: !roleList ? [{ name: "Loading...", id: 0 }] : roleList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsparentdepartment = {
    options: !departmentList ? [{ name: "Loading...", id: 0 }] : departmentList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparentdesignation = {
    options: !designationList
      ? [{ name: "Loading...", id: 0 }]
      : designationList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const [toggle, setToggle] = useState(false);
  const toggler = (checked, request, roleIdList, DeptIdList) => {
    console.log("toggle 86", checked);
    if (checked) {
      request.recstatus = "DEACTIVATED";

      console.log("req 88", request.recstatus);
    } else {
      request.recstatus = "APPROVED";

      console.log("req 91", request.recstatus);
    }
    updateProfile(request, roleIdList, DeptIdList);
  };

  async function getRoleData() {
    await axios
      .get(
        `role-service-mongo/api/role/v2/get`
      )
      .then((response) => {
        setRoleList(response.data.data.dtoList);

        getdepartmentData(response.data.data.dtoList);
        console.log("81", response.data?.data?.dtoList);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getdepartmentData(roleIdList) {
    console.log("96", roleIdList);
    await axios
      .get(
        `department-service-mongo/api/department/v2/get`
      )
      .then((response) => {
        setDepartmentList(response.data.data.dtoList);
        getData(roleIdList, response.data?.data?.dtoList);
        console.log(roleIdList, response.data?.data?.dtoList);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getDesignationData(deptCode, code) {
    await axios
      .get(
        `designation-service-mongo/api/designation/v2/get?deptcode=${deptCode}`
      )
      .then((response) => {
        setDesignationList(response.data.data.dtoList);
        console.info("120", response.data.data.dtoList);
        console.info("121", code);
        for (let i = 0; i < response.data.data.dtoList.length; i++) {
          if (response.data.data.dtoList[i].code === code) {
            setSelectDesi(response.data.data.dtoList[i]);
            console.info("123", response.data.data.dtoList[i]);
          }
        }

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  const onUpdatedata = (object, roleIdList, DeptIdList) => {
    console.log("139", roleIdList);
    for (let i = 0; i < roleIdList.length; i++) {
      if (roleIdList[i].name === object.rolename) {
        setSelectrole(roleIdList[i]);
      }
    }

    for (let i = 0; i < DeptIdList.length; i++) {
      if (DeptIdList[i].name === object.departmentname) {
        setSelectdep(DeptIdList[i]);
        getDesignationData(DeptIdList[i].code, object.designationcode);
      }
    }

    setItem(object);
    setUsername(object.username);
    setFirstName(object.firstname);
    setLastName(object.lastname);
    setEmail(object.emailid);
    setPhoneNo(object.phoneno);

    setOpen(true);
  };

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function onDelete(item) {
    if (
      window.confirm(
        "Are you sure that you want to Delete That User " + item.fullname
      )
    )
      item.modifiedby = user?.firstname + " " + user?.lastname + " (" + user?.username + ")";
      item.modifieddate = new Date()
      await axios
        .put(
          "profile-service-mongo/api/ProfileEntity/v2/delete",
          item
        )
        .then((response) => {
          console.log(response);
          // getData();
          getRoleData();
        })
        .catch((error) => {
          console.log(error);
        });
  }
  async function updateData() {
    item.username = username;
    item.emailid = email;
    item.phoneno = phoneNo;
    item.firstname = firstname;
    item.lastname = lastName;
    item.modifiedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;
    // item.modifieddate = new Date();
    delete item.action;
    await axios
      .put(
        "profile-service-mongo/api/ProfileEntity/v2/update",
        item
      )
      .then((response) => {
        setOpen(false);
        getRoleData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function updateProfile(request, roleList, deptList) {
    request.modifiedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;

    delete request.action;
    delete request.deactivate;
    await axios
      .put(
        "profile-service-mongo/api/ProfileEntity/v2/update",
        request
      )
      .then((response) => {
        console.log(response);
        getData(roleList, deptList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData(roleIdList, DeptIdList) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "username", accessor: "username" },
        { Header: "fullname", accessor: "fullname" },
        { Header: "role", accessor: "rolename" },

        { Header: "department", accessor: "departmentname" },
        { Header: "designation", accessor: "designationname" },
        { Header: "status", accessor: "recstatuscolor" },
        { Header: "mmucode", accessor: "mmucode" },
        { Header: "phoneNo", accessor: "phoneno" },
        { Header: "deactivate", accessor: "deactivate" },  
        { Header: "emailId", accessor: "emailid" },
        { Header: "action", accessor: "action" },
      ],
      rows: [],
    };
    axios
      .get(
        `profile-service-mongo/api/ProfileEntity/v2/get?resourcecode=${process.env.REACT_APP_rescoursecode}`
      )
      .then((response) => {
        console.log("====================================");
        console.log(response);
        console.log("====================================");
        response.data.data.dtoList.map((item) => {
          if (item.firstname != null) {
            item.fullname = "" + item.firstname + " ";
          }
          if (item.lastname != null) {
            item.fullname += item.lastname;
          }
          if (item.recstatus === "DEACTIVATED") {
            console.log("295", item.recstatus);
            setToggle(true);
          }
          // status
          if (item.recstatus === "APPROVED") {
            item.recstatuscolor = 
            (
              <MDBadgeDot color='success' size='xs' badgeContent={item.recstatus} />
              // <span style={{ color: "green" }}>{item.recstatus}</span>
            );
          }  else if (item.recstatus === "ASSIGNED") {
            item.recstatuscolor = (
              <MDBadgeDot color='info' size='xs' badgeContent={item.recstatus} />
             // <span style={{ color: "BLUE" }}>{item.recstatus}</span>
            );
          } else {
            item.recstatuscolor = (
              <MDBadgeDot color='warning' size='xs' badgeContent={item.recstatus} />
              // <span style={{ color: "grey" }}>{item.recstatus}</span>
            );
          }
          item.deactivate = (
            <span>
              {item.recstatus !== "DEACTIVATED" && (
                <FormControlLabel
                  name={item.id}
                  control={
                    <Switch
                      onClick={(e) =>
                        toggler(e.target.checked, item, roleIdList, DeptIdList)
                      }
                    />
                  }
                  label="de-activated"
                />
              )}
              {item.recstatus === "DEACTIVATED" && (
                <FormControlLabel
                  name={item.id}
                  control={
                    <Switch
                      onClick={(e) =>
                        toggler(e.target.checked, item, roleIdList, DeptIdList)
                      }
                      defaultChecked
                    />
                  }
                  label="de-activated"
                />
              )}
            </span>
          );

          //  item.recstatus === "CLOSED"{setChecked(true

          item.action = (
            <span>
              <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item, roleIdList, DeptIdList);
                  }}
                />
              </Link>

              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getRoleData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={0}>
        <MDBox mb={0}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <MDTypography variant="h5" fontWeight="medium">
                  User List
                </MDTypography>
                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    size: "20px",
                    color: "#344767",
                  }}
                  to={"/pages/users/new-user"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{textAlign:"centre"}}>Update User</DialogTitle>
        <DialogContent style={{ width: 450, height: 490 }}>
        <MDBox mb={2}>
              <MDInput
                type="text"
                label="FirstName"
                variant="standard"
                fullWidth
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="LastName"
                variant="standard"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </MDBox >
         
          <MDBox mb={2}>
              <MDInput
              disabled="true"
                type="text"
                label="User Name"
                variant="standard"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <span style={{ color: "red" }}>{err}</span>
            </MDBox>
            <MDBox mb={2}>
              <Autocomplete
                {...defaultProps}
                value={selectrole}
                onChange={(event, newValue) => {
                  // handleChange(event);
                  item.rolename = newValue.name;
                  item.rolecode = newValue.rolecode;

                  setSelectrole(newValue);
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label="Role" />
                )}
              />
            </MDBox >
          
          <MDBox mb={2}>
              <Autocomplete
                {...defaultPropsparentdepartment}
                value={selectdep}
                onChange={(event, newValue) => {
                  // handleChange(event);
                  item.departmentname = newValue.name;
                  item.departmentcode = newValue.code;
                  getDesignationData(newValue.code, "");
                  setSelectdep(newValue);
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label="Department" />
                )}
              />
           </MDBox>
           <MDBox mb={2}>
              <Autocomplete
                {...defaultPropsparentdesignation}
                value={selectdesi}
                onChange={(event, newValue) => {
                  // handleChange(event);
                  item.designationcode = newValue.code;
                  item.designationname = newValue.name;

                  setSelectDesi(newValue);

                  // values.department = newValue.hierarchicalcode;
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label="Designation" />
                )}
              />
            </MDBox>
          
            <MDBox mb={2}>
           
              <MDInput
                type="number"
                label="Phone no"
                variant="standard"
                fullWidth
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
              />
           </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Email"
                variant="standard"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
          

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!username}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default UsersListTable;








// import * as React from "react";
// import Card from "@mui/material/Card";

// // Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
// import { Autocomplete, FormControlLabel, Grid } from "@mui/material";
// // Material Dashboard 2 PRO React examples
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
// // @mui material components

// // Data

// import { Link } from "react-router-dom";
// import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import { BarLoader } from "react-spinners";
// import { FaRegEdit } from "react-icons/fa";
// //EDit button

// import Button from "@mui/material/Button";

// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";

// import DialogTitle from "@mui/material/DialogTitle";
// import Switch from "@mui/material/Switch";
// import swal from 'sweetalert';

// function UsersListTable() {
//   const [state, setState] = React.useState({ status: true });
//   const [loading, setLoading] = useState(true);
//   const [tableDatas, setTableDatas] = useState({});
//   const [color] = useState("#344767");
//   const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
//   const [open, setOpen] = React.useState(false);
//   const [item, setItem] = useState({});
//   const [username, setUsername] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [firstname, setFirstName] = useState("");

//   const [stateList, setStateList] = useState("");
//   const [districtList, setDistrictList] = useState("");
//   const [subDistrictList, setSubDistrictList] = useState("");
//   const [pincodeList, setPincodeList] = useState("");


//   const [selectstate, setSelectState] = useState("");
//   const [selectdistrict, setSelectdistrict] = useState("");
//   const [selectsubdistrict, setSelectsubdistrict] = useState("");
//   const [selectpincode, setSelectpincode] = useState("");

//   const [roleList, setRoleList] = useState("");
//   const [departmentList, setDepartmentList] = useState("");
//   const [designationList, setDesignationList] = useState("");
//   const [selectrole, setSelectrole] = useState("");
//   const [selectdesi, setSelectDesi] = useState("");
//   const [selectdep, setSelectdep] = useState("");
//   const [phoneNo, setPhoneNo] = useState("");
//   const [email, setEmail] = useState("");
//   const [err, setErr] = useState("");

//   const [checked, setChecked] = useState(false);
//   const [status, setStatus] = useState(false);

//   const addButtonStyle = {
//     display: "flex",
//     fontSize: "2em",
//     flexDirection: "column",
//     marginRight: "10px",
//   };
//   const defaultProps = {
//     options: !roleList ? [{ name: "Loading...", id: 0 }] : roleList,
//     getOptionLabel: (option) => option.name,
//     isOptionEqualToValue: (option, value) => option.id === value.id,
//   };

//   const defaultPropsparentdepartment = {
//     options: !departmentList ? [{ name: "Loading...", id: 0 }] : departmentList,
//     getOptionLabel: (option) => option.name,
//     isOptionEqualToValue: (option, value) => option.id === value.id,
//   };
//   const defaultPropsparentdesignation = {
//     options: !designationList
//       ? [{ name: "Loading...", id: 0 }]
//       : designationList,
//     getOptionLabel: (option) => option.name,
//     isOptionEqualToValue: (option, value) => option.id === value.id,
//   };
//   const defaultPropsState = {
//     options: !stateList
//       ? [{ id: 0, state: { name: "Loading...", shortname: "" } }]
//       : stateList,
//     getOptionLabel: (option) =>
//       option.state?.name + " (" + option.state?.shortname + ")",
//     isOptionEqualToValue: (option, value) => option.id === value.id,
//   };
//   const defaultPropsDistrict = {
//     options: !districtList
//       ? [{ id: 0, district: { name: "Loading...", shortname: "" } }]
//       : districtList,
//     getOptionLabel: (option) =>
//       option.district?.name + " (" + option.district?.shortname + ")",
//     isOptionEqualToValue: (option, value) => option.id === value.id,
//   };
//   const defaultPropsSubDistrict = {
//     options: !subDistrictList
//       ? [{ id: 0, subdistrict: { name: "Loading...", shortname: "" } }]
//       : subDistrictList,
//     getOptionLabel: (option) =>
//       option.subdistrict?.name + " (" + option.subdistrict?.shortname + ")",
//     isOptionEqualToValue: (option, value) => option.id === value.id,
//   };
//   const defaultPropspincode = {
//     options: !pincodeList
//       ? [{ id: 0, village: { name: "Loading..." }, pincode: "" }]
//       : pincodeList,
//     getOptionLabel: (option) =>
//       option.village?.name + " (" + option.pincode + ")",
//     isOptionEqualToValue: (option, value) => option.id === value.id,
//   };
//   const [toggle, setToggle] = useState(false);
//   const toggler = (checked, request, roleIdList, DeptIdList) => {
//     console.log("toggle 86", checked);
//     if (checked) {
//       request.recstatus = "DEACTIVATED";

//       console.log("req 88", request.recstatus);
//     } else {
//       request.recstatus = "APPROVED";

//       console.log("req 91", request.recstatus);
//     }
//     updateProfile(request, roleIdList, DeptIdList);
//   };

//   async function getRoleData() {
//     await axios
//       .get(
//         `role-service-mongo/api/role/v2/get`
//       )
//       .then((response) => {
//         setRoleList(response.data.data.dtoList);

//         getdepartmentData(response.data.data.dtoList);
//         console.log("81", response.data?.data?.dtoList);
//       })

//       .catch((e) => {
//         console.log("error", e);
//       });
//   }
//   async function getdepartmentData(roleIdList) {
//     console.log("96", roleIdList);
//     await axios
//       .get(
//         `department-service-mongo/api/department/v2/get`
//       )
//       .then((response) => {
//         setDepartmentList(response.data.data.dtoList);
//         stateData(roleIdList, response.data?.data?.dtoList)

//         console.log(roleIdList, response.data?.data?.dtoList);
//       })

//       .catch((e) => {
//         console.log("error", e);
//       });
//   }

//   async function getDesignationData(deptCode, code) {
//     await axios
//       .get(
//         `designation-service-mongo/api/designation/v2/get?deptcode=${deptCode}`
//       )
//       .then((response) => {
//         setDesignationList(response.data.data.dtoList);
//         console.info("120", response.data.data.dtoList);
//         console.info("121", code);
//         for (let i = 0; i < response.data.data.dtoList.length; i++) {
//           if (response.data.data.dtoList[i].code === code) {
//             setSelectDesi(response.data.data.dtoList[i]);
//             console.info("123", response.data.data.dtoList[i]);
//           }
//         }

//         setLoading(false);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }
//   async function stateData(roleIdList, DeptIdList) {
//     //setting state===========
//     await axios
//       .get(
//         `pincode-service/api/pincode/v1/get?type=STATE`
//       )
//       .then((response) => {
//         setStateList(response.data.data.dtoList);
//         getData(roleIdList, DeptIdList, response.data.data.dtoList);
//         console.log("135", response.data?.data?.dtoList);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }

//   async function getDistrictData(stateCode) {
//     console.log("146", stateCode);

//     //setting pincode===========
//     await axios
//       .get(
//         `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${stateCode}`
//       )
//       .then((response) => {
//         setDistrictList(response.data.data.dtoList);

//         setLoading(false);
//         return [response.data.data.dtoList];
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }
//   async function getSubDistrictData(stateCode, districtCode) {
//     //setting pincode===========
//     await axios
//       .get(
//         `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&state.code=${stateCode}&district.code=${districtCode}`
//       )
//       .then((response) => {
//         console.log("201", response);
//         setSubDistrictList(response.data.data.dtoList);
//         setLoading(false);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }
//   async function getVillageData(stateCode, districtCode, subDistrictCode) {
//     //setting pincode===========
//     await axios
//       .get(
//         `pincode-service/api/pincode/v1/get?type=VILLAGE&state.code=${stateCode}&district.code=${districtCode}&subdistrict.code=${subDistrictCode}`
//       )
//       .then((response) => {
//         setPincodeList(response.data.data.dtoList);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }
//   const onUpdatedata = (object, roleIdList, DeptIdList, stateData) => {
//     console.log("261", stateData);
//     for (let i = 0; i < roleIdList.length; i++) {
//       if (roleIdList[i].name === object.rolename) {
//         setSelectrole(roleIdList[i]);
//       }
//     }

//     for (let i = 0; i < DeptIdList.length; i++) {
//       if (DeptIdList[i].name === object.departmentname) {
//         setSelectdep(DeptIdList[i]);
//         getDesignationData(DeptIdList[i].code, object.designationcode);
//       }
//     }
//     for (let i = 0; i < stateData.length; i++) {
//       if (stateData[i].state.code === object.pincode.state.code) {
//         console.log("237", stateData[i]);
//         setSelectState(stateData[i]);
//         var statecode = stateData[i].state.code;
//         axios
//           .get(
//             `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=` +
//             stateData[i].state.code
//           )
//           .then((response) => {
//             setDistrictList(response.data.data.dtoList);

//             setLoading(false);
//             for (let i = 0; i < response.data.data.dtoList.length; i++) {
//               if (
//                 response.data.data.dtoList[i].district.code ===
//                 object.pincode.district.code
//               ) {
//                 setSelectdistrict(response.data.data.dtoList[i]);
//                 var selecteddistricCode =
//                   response.data.data.dtoList[i].district.code;

//                 axios
//                   .get(
//                     `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&state.code=${statecode}&district.code=${selecteddistricCode}`
//                   )
//                   .then((response) => {
//                     setSubDistrictList(response.data.data.dtoList);

//                     for (
//                       let i = 0;
//                       i < response.data.data.dtoList.length;
//                       i++
//                     ) {
//                       if (
//                         response.data.data.dtoList[i].subdistrict.code ===
//                         object.pincode.subdistrict.code
//                       ) {
//                         setSelectsubdistrict(response.data.data.dtoList[i]);
//                         var selectpincodeCode =
//                           response.data.data.dtoList[i].subdistrict.code;

//                         axios
//                           .get(
//                             `pincode-service/api/pincode/v1/get?type=VILLAGE&state.code=${statecode}&district.code=${selecteddistricCode}&subdistrict.code=${selectpincodeCode}`
//                           )
//                           .then((response) => {
//                             setPincodeList(response.data.data.dtoList);
//                             console.log("276", response);
//                             for (
//                               let i = 0;
//                               i < response.data.data.dtoList.length;
//                               i++
//                             ) {
//                               if (
//                                 response.data.data.dtoList[i].village.code ===
//                                 object.pincode.village.code
//                               ) {
//                                 setSelectpincode(response.data.data.dtoList[i]);
//                               }
//                             }
//                           })
//                           .catch((e) => {
//                             console.log("error", e);
//                           });
//                       }
//                     }
//                   })
//                   .catch((e) => {
//                     console.log("error", e);
//                   });
//               }
//             }
//           })
//           .catch((e) => {
//             console.log("error", e);
//           });
//       }
//     }


//     setItem(object);
//     setUsername(object.username);
//     setFirstName(object.firstname);
//     setLastName(object.lastname);
//     setEmail(object.emailid);
//     setPhoneNo(object.phoneno);

//     setOpen(true);
//   };

//   const handleClose = () => {
//     console.log("dsfsdfs");
//     setOpen(false);
//   };

//   async function onDelete(item) {
//     // if (
//     //   window.confirm(
//     //     "Are you sure that you want to Delete That User " + item.fullname
//     //   )
//     // )
//     //   // item.modifiedby =
//     //   //   JSON.parse(localStorage.getItem("mmu-auth")).firstname +
//     //   //   " " +
//     //   //   JSON.parse(localStorage.getItem("mmu-auth")).lastname;
//     //   await axios
//     //     .put(
//     //       "https://apithf.softwaremathematics.com/profile-service-mongo/api/ProfileEntity/v2/delete",
//     //       item
//     //     )
//     //     .then((response) => {
//     //       console.log(response);
//     //       // getData();
//     //       getRoleData();
//     //     })
//     //     .catch((error) => {
//     //       console.log(error);
//     //     });
//     swal({
//       title: "Are you sure?",
//       text: "Once deleted, you will not be able to recover this " + item.fullname,
//       icon: "warning",
//       buttons: true,
//       dangerMode: true,
//     })
//       .then((willDelete) => {
//         console.log(willDelete)
//         if (willDelete) {
//           axios
//             .put(
//               'profile-service-mongo/api/ProfileEntity/v2/delete',
//               item
//             )
//             .then((response) => {
//               console.log(response);
//               getData();
//             })
//           swal("Poof! Your user record has been deleted!", {
//             icon: "success",

//           });
//         } else {
//           swal("Your user record is safe!");
//         }
//       });
//   }
//   async function updateData() {
//     item.username = username;
//     item.emailid = email;
//     item.phoneno = phoneNo;
//     item.firstname = firstname;
//     item.lastname = lastName;
//     item.modifiedby =
//       JSON.parse(localStorage.getItem("mmu-auth")).firstname +
//       " " +
//       JSON.parse(localStorage.getItem("mmu-auth")).lastname;
//     // item.modifieddate = new Date();
//     delete item.action;
//     await axios
//       .put(
//         "profile-service-mongo/api/ProfileEntity/v2/update",
//         item
//       )
//       .then((response) => {
//         setOpen(false);
//         getRoleData();
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }
//   async function updateProfile(request, roleList, deptList) {
//     request.modifiedby =
//       JSON.parse(localStorage.getItem("mmu-auth")).firstname +
//       " " +
//       JSON.parse(localStorage.getItem("mmu-auth")).lastname;

//     delete request.action;
//     delete request.deactivate;
//     await axios
//       .put(
//         "profile-service-mongo/api/ProfileEntity/v2/update",
//         request
//       )
//       .then((response) => {
//         console.log(response);
//         getRoleData(roleList, deptList);
//         // getData(roleList, deptList);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }

//   function getData(roleIdList, DeptIdList, stateData) {
//     setLoading(true);
//     const dataObj = {
//       columns: [
//         { Header: "username", accessor: "username" },
//         { Header: "fullname", accessor: "fullname" },
//         { Header: "role", accessor: "rolename" },

//         { Header: "department", accessor: "departmentname" },
//         { Header: "designation", accessor: "designationname" },
//         { Header: "emailId", accessor: "emailid" },
//         { Header: "phoneNo", accessor: "phoneno" },
//         { Header: "deactivate", accessor: "deactivate" },
//         { Header: "state", accessor: "pincode.state.name" },
//         { Header: "action", accessor: "action" },
//       ],
//       rows: [],
//     };
//     axios
//       .get(
//         `profile-service-mongo/api/ProfileEntity/v2/get`
//       )
//       .then((response) => {
//         console.log("====================================");
//         console.log(response);
//         console.log("====================================");
//         response.data.data.dtoList.map((item) => {
//           if (item.firstname != null) {
//             item.fullname = "" + item.firstname + " ";
//           }
//           if (item.lastname != null) {
//             item.fullname += item.lastname;
//           }
//           if (item.recstatus === "DEACTIVATED") {
//             console.log("295", item.recstatus);
//             setToggle(true);
//           }
//           item.deactivate = (
//             <span>
//               {item.recstatus !== "DEACTIVATED" && (
//                 <FormControlLabel
//                   name={item.id}
//                   control={
//                     <Switch
//                       onClick={(e) =>
//                         toggler(e.target.checked, item, roleIdList, DeptIdList)
//                       }
//                     />
//                   }
//                   label="de-activated"
//                 />
//               )}
//               {item.recstatus === "DEACTIVATED" && (
//                 <FormControlLabel
//                   name={item.id}
//                   control={
//                     <Switch
//                       onClick={(e) =>
//                         toggler(e.target.checked, item, roleIdList, DeptIdList)
//                       }
//                       defaultChecked
//                     />
//                   }
//                   label="de-activated"
//                 />
//               )}
//             </span>
//           );

//           //  item.recstatus === "CLOSED"{setChecked(true

//           item.action = (
//             <span>
//               {/* <Link to={{}}>
//                 <FaRegEdit
//                   style={iconStyle}
//                   onClick={() => {
//                     onUpdatedata(item, roleIdList, DeptIdList,stateData);
//                   }}
//                 />
//               </Link> */}

//               <Link to={{}}>
//                 <MdDelete
//                   style={iconStyle}
//                   onClick={() => {
//                     onDelete(item);
//                   }}
//                 />
//               </Link>
//             </span>
//           );
//           dataObj.rows.push(item);
//         });
//         console.log("res70", dataObj);
//         setTableDatas(dataObj);
//         setLoading(false);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }

//   useEffect(() => {
//     getRoleData();
//   }, []);

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox pt={6} pb={0}>
//         <MDBox mb={0}>
//           <Card>
//             <MDBox p={3} lineHeight={1}>
//               <div style={addButtonStyle}>
//                 <MDTypography variant="h5" fontWeight="medium">
//                   User List
//                 </MDTypography>
//                 <Link
//                   style={{
//                     alignSelf: "flex-end",
//                     marginRight: "10px",
//                     marginTop: "-23px",
//                     size: "20px",
//                     color: "#344767",
//                   }}
//                   to={"/pages/users/new-user"}
//                 >
//                   <MdOutlineLibraryAdd />
//                 </Link>
//               </div>
//             </MDBox>
//             <BarLoader
//               color={color}
//               loading={loading}
//               aria-label="Loading Spinner"
//             />
//             {!loading && <DataTable table={tableDatas} canSearch={true} />}
//           </Card>
//         </MDBox>
//       </MDBox>
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle style={{ textAlign: "center" }}>Update User</DialogTitle>
//         <DialogContent style={{ width: 550, height: 350 }}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <MDInput
//                 type="text"
//                 label="FirstName"
//                 variant="standard"
//                 fullWidth
//                 value={firstname}
//                 onChange={(e) => setFirstName(e.target.value)}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <MDInput
//                 type="text"
//                 label="LastName"
//                 variant="standard"
//                 fullWidth
//                 value={lastName}
//                 onChange={(e) => setLastName(e.target.value)}
//               />
//             </Grid>
//           </Grid>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <MDInput
//                 type="text"
//                 label="User Name"
//                 variant="standard"
//                 fullWidth
//                 value={username}
//                 onChange={(e) => setUsername(e.target.value)}
//               />
//               <span style={{ color: "red" }}>{err}</span>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <Autocomplete
//                 {...defaultProps}
//                 value={selectrole}
//                 onChange={(event, newValue) => {
//                   // handleChange(event);
//                   item.rolename = newValue.name;
//                   item.rolecode = newValue.rolecode;

//                   setSelectrole(newValue);
//                 }}
//                 renderInput={(params) => (
//                   <MDInput {...params} variant="standard" label="Role" />
//                 )}
//               />
//             </Grid>
//           </Grid>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <Autocomplete
//                 {...defaultPropsparentdepartment}
//                 value={selectdep}
//                 onChange={(event, newValue) => {
//                   // handleChange(event);
//                   item.departmentname = newValue.name;
//                   item.departmentcode = newValue.code;
//                   getDesignationData(newValue.code, "");
//                   setSelectdep(newValue);
//                 }}
//                 renderInput={(params) => (
//                   <MDInput {...params} variant="standard" label="Department" />
//                 )}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <Autocomplete
//                 {...defaultPropsparentdesignation}
//                 value={selectdesi}
//                 onChange={(event, newValue) => {
//                   // handleChange(event);
//                   item.designationcode = newValue.code;
//                   item.designationname = newValue.name;

//                   setSelectDesi(newValue);

//                   // values.department = newValue.hierarchicalcode;
//                 }}
//                 renderInput={(params) => (
//                   <MDInput {...params} variant="standard" label="Designation" />
//                 )}
//               />
//             </Grid>
//           </Grid>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <MDInput
//                 type="number"
//                 label="Phone no"
//                 variant="standard"
//                 fullWidth
//                 value={phoneNo}
//                 onChange={(e) => setPhoneNo(e.target.value)}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <MDInput
//                 type="text"
//                 label="Email"
//                 variant="standard"
//                 fullWidth
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//             </Grid>
//           </Grid>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>

//               <Autocomplete
//                 {...defaultPropsState}
//                 value={selectstate}
//                 onChange={(event, newValue) => {
//                   setSelectState(newValue);
//                   getDistrictData(newValue.state?.code);
//                   item.pincode.state.name = newValue.state.name;
//                   item.pincode.state.code = newValue.state.code;
//                   item.pincode.state.shortname = newValue.state.shortname;

//                   console.log("321", selectstate);
//                 }}
//                 renderInput={(params) => (
//                   <MDInput {...params} variant="standard" label="State" />
//                 )}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <Autocomplete
//                 {...defaultPropsDistrict}
//                 value={selectdistrict}
//                 onChange={(event, newValue) => {
//                   // handleChange(event);
//                   //item.type = newValue.name;

//                   setSelectdistrict(newValue);
//                   getSubDistrictData(
//                     newValue.state?.code,
//                     newValue.district?.code
//                   );
//                   item.pincode.district.name = newValue.district.name;
//                   item.pincode.district.code = newValue.district.code;
//                   item.pincode.district.shortname =
//                     newValue.district.shortname;
//                   console.log("517", selectdistrict);
//                 }}
//                 renderInput={(params) => (
//                   <MDInput {...params} variant="standard" label="Distric" />
//                 )}
//               />
//             </Grid>
//           </Grid>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <Autocomplete
//                 {...defaultPropsSubDistrict}
//                 value={selectsubdistrict}
//                 onChange={(event, newValue) => {
//                   // handleChange(event);

//                   setSelectsubdistrict(newValue);
//                   getVillageData(
//                     newValue.state?.code,
//                     newValue.district?.code,
//                     newValue.subdistrict?.code
//                   );

//                   item.pincode.subdistrict.name =
//                     newValue.subdistrict.name;
//                   item.pincode.subdistrict.code =
//                     newValue.subdistrict.code;
//                   item.pincode.subdistrict.shortname =
//                     newValue.subdistrict.shortname;
//                   console.log("405", selectsubdistrict);
//                 }}
//                 renderInput={(params) => (
//                   <MDInput {...params} variant="standard" label="subdistic" />
//                 )}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <Autocomplete
//                 {...defaultPropspincode}
//                 value={selectpincode}
//                 onChange={(event, newValue) => {
//                   // handleChange(event);
//                   // item.type = newValue.name;

//                   setSelectpincode(newValue);
//                   item.pincode.village.name = newValue.village.name;
//                   item.pincode.village.code = newValue.village.code;
//                   item.pincode.village.shortname =
//                     newValue.village.shortname;
//                   console.log("424", selectpincode);
//                 }}
//                 renderInput={(params) => (
//                   <MDInput {...params} variant="standard" label="pincode" />
//                 )}
//               />
//             </Grid>
//           </Grid>

//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button onClick={updateData} disabled={!username}>
//             Update
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <Footer />
//     </DashboardLayout>
//   );
// }

// export default UsersListTable;


