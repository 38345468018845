
import Analytics from "layouts/dashboards/analytics";

import ProfileOverview from "layouts/pages/profile/profile-overview";

import SignInBasic from "layouts/authentication/sign-in/basic";


// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";
import PatientReport from "components/MMU/components/Analytics/PatientReport";
import PrescriptionReport from "components/MMU/components/Analytics/PrescriptionReport";
import Test from "components/MMU/components/Analytics/Test";
import StockReport from "components/MMU/components/Analytics/StockReport";
import MedicalRequisition from "components/MMU/components/Analytics/MedicalRequisition";
import MicroSoftpower from "layouts/pages/Microsoft-bi/MicroSoftpower";




const routesAnalyzer = [
  {
    type: "collapse",

    name: localStorage.getItem("mmu-auth")
    ? JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname
    : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
     
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
      
    ],

  },
 { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Power-Bi",
        key: "power-bi",
        route: "/pages/profile/power-bi",
        component: <MicroSoftpower/>,
      },
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "Patient Report",
        key: "patient-report",
        route: "/dashboards/patient-report",
        component: <PatientReport />,
      },
      {
        name: "Prescription Report",
        key: "prescription-report",
        route: "/dashboards/prescription-report",
        component: <PrescriptionReport />,
      },
      {
        name: "Download Report",
        key: "download-report",
        route: "/dashboards/download-report",
        component: <Test />,
      },
      {
        name: "Stock Report",
        key: "stock-report",
        route: "/pages/account/stock-report",
        component: <StockReport/>,
      },
      
      {
        name: "Medical Requisition",
        key: "medical-requistion",
        route: "/pages/account/Medical-requisition",
        component: <MedicalRequisition/>,
      },
    ],
  },
];


 
 export default routesAnalyzer



