

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";


// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Calendar from "react-awesome-calendar";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";
import "./sechdular.css"
import axios from "axios";
import { BarLoader } from "react-spinners";

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import moment from "moment-timezone";
import MDButton from "components/MDButton";

import { Formik, useFormik } from "formik";
import { validationScehdule } from "./schemas"
import { FaRegTimesCircle } from "react-icons/fa";
import swal from 'sweetalert';
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import DataTable from "examples/Tables/DataTable";
import angry from "../../../../assets/images/angry.png"
import {

  MdOutlineLibraryAdd,

} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import { Autocomplete, TextField, FormControlLabel, } from "@mui/material";
import { item } from "examples/Sidenav/styles/sidenavItem";

const initialValues = {
  title: "",
  address: "",
}

function MmuScheduler() {



  const [open, setOpen] = useState(false);
  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist
  );
  const [defaultMMU, setDefaultMMU] = useState();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [value, onChange] = useState(new Date());
  const [buttonCondition, setButtonCondition] = useState(true);
  const [buttonValue, setButtonValue] = useState("ONLINE");
  const [address, setAddress] = useState("");
  const [colors, setColors] = useState("");
  const [mmucode, setMmucode] = useState("");


  const [startD, setStartD] = useState(moment().format('YYYY-MM-DD'));

  const [startT, setStartT] = useState(moment().format('HH:mm'));

  const [endD, setEndD] = useState(moment().format('YYYY-MM-DD'));

  const [endT, setEndT] = useState(moment().format('HH:mm'));
  const [enddate, setEnddate] = useState(new Date(endD + " " + endT));
  // console.log("start", enddate)
  const [startdate, setStartdate] = useState(new Date(startD + " " + startT));
  // console.log("start", startdate)

  const [title, setTitle] = useState("");
  const [events, setEvents] = useState([]);


  const [pincodeFinal, setPincodeFinal] = useState(null);
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(4);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRowValues, setSelectedRowValues] = useState([]);
  const dataObj = {
    columns: [
      { Header: "flag", accessor: "flag" },
      { Header: "title", accessor: "title" },
      { Header: "village", accessor: "completeaddress" },
      { Header: "startDatetime", accessor: "startdate" },

      { Header: "enddatetime", accessor: "enddate" },


      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };

  const [tableData, setTableData] = useState(dataObj);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: validationScehdule,
    onSubmit: (values) => {
      console.log("93", values)
      console.log("94", new Date(startD + " " + startT))

      const eventObj = {

        address: null,
        documentofproof: "",
        mmucode: mmucode,
        enddate: convertTZ(new Date(endD + " " + endT)),
        startdate: convertTZ(new Date(startD + " " + startT)),
        title: values.title,
        id: null,
        flag: buttonValue,
        pincode: pincodeFinal,

        location: null,
        createdby:
          JSON.parse(localStorage.getItem("mmu-auth")).firstname +
          " " +
          JSON.parse(localStorage.getItem("mmu-auth")).lastname,
        createddate: null,
        modifiedby: "",
        modifieddate: null,
        requiredattendees: "",
        recstatus: "OPEN",
        status: "ACTIVE",
        syncstatus: "SYNCED",
      };
      submitForm(eventObj);
      console.log("572", eventObj)
    }
  })
  const toggler = (checked) => {
    console.log("toggle 126", checked);
    if (checked === true) {
      console.log("yes")
      setButtonCondition(true)
      setButtonValue("ONLINE")
    }
    else {
      console.log("false")
      setButtonCondition(false)
      setButtonValue("OFFLINE")
    }

  };

  function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
    // return new Date((typeof date === "string" ? new Date(date) : date));   
  }
  function convertTZ(date) {
    var format = "YYYY-MM-DDTHH:mm:ss.sssZ";
    return moment(date, format).tz("Asia/Kolkata").format(format);
  }


  const defaultPropsMMU = {
    options: !mmuList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuList,
    getOptionLabel: (option) => option.name + "(" + option.vehicle?.name + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,

  };


  const comp = "SM";

  const [color] = useState("#344767");

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };




  async function getEvent(mmucode) {
    // setLoading(false);
    // await axios
    //   .get(
    //     `event-service/api/event/v1/get?mmucode=${mmucode}`
    //   )
    //   .then((response) => {
    //     console.log("event", response.data.data.dtoList);
    //     response.data.data.dtoList.map((obj) => {
    //       obj.color = obj.documentofproof;
    //       obj.from = convertTZ(obj.startdate, "Asia/Kolkata");
    //       obj.to = convertTZ(obj.enddate, "Asia/Kolkata");

    //       // obj.from = new Date(obj.startdate)
    //       // obj.to = new Date(obj.enddate)
    //     });
    //     setEvents(response.data.data.dtoList);
    //     console.log("events", response.data.data.dtoList);
    //     setLoading(true);
    //   })
    //   .catch((e) => {
    //     console.log("error", e);
    //     setLoading(true);
    //   });
  }


  function closePopup() {
    setOpen(false);

  }

  const createEvent = async (eventObjlist) => {
    for (let i = 0; i < eventObjlist.length; i++) {
      eventObjlist[i].documentofproof = "#FFA500";
      delete eventObjlist[i].action;

    }



    if (eventObjlist.length > 0) {
      await axios
        .post(
          "event-service/api/event/v1/createList",
          eventObjlist
        )


        .then((response) => {
          console.log("gfgfgf", response);
          getEvent(mmucode);

          closePopup();
          setTableData(dataObj)

          swal("Good job!", "Successfully Done!", "success")


        })
        .catch((error) => {
          console.log(error);
          getEvent(mmucode);
          setTableData(dataObj)
          closePopup();
          sweetAlert("Oops...", "Something went wrong!", "error");
        });

    }
    else {
      sweetAlert("Oops...", "Please Add TableList First!", "error");



    }

  };


  function onMMUChange(){
    setCurrentPage(0);
    fetchData(mmucode, currentPage, pageSize, searchQuery);
  }


  useEffect(() => {
    if (mmucode) {
      fetchData(mmucode, currentPage, pageSize, searchQuery);
    }

  }, [currentPage, pageSize]);



  // Fetch the data from the API
  function fetchData(mmucode, page, size, query) {
    setData([])
    // values.PincodeList = []
    // tableData.rows = []
    // Make an API request using fetch or any other library
    // Replace the URL with your API endpoint
    fetch(`https://smapi.softwaremathematics.com/project-service/api/Project/v1/getPinCodeByProject?mmu.code=${mmucode}&ispageable=true&page=${page}&size=${size}&st=${query}`, 
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-Tenant': 'MMT20240418'
            }
          }
         )
      .then(response => response.json())
      .then(result => {
        console.log("result", result)
        setData(result?.data?.page?.content);
        setTotalPages(result?.data?.page?.totalPages);
      }).catch(e => {
        console.log(e);
        setData([])
      });
  }

  const removeItem = function (id) {

    const objWithIdIndex = tableData.rows.findIndex((obj) => obj.pincode.id === id);
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
    values.PincodeList = tableData.rows;
  };

  const addData = (item) => {

    console.log(item);
    if (item.tittle === undefined || item.tittle == "") {
      swal({
        content: {
          element: 'img',
          attributes: {
            src: angry,
            width: 165,
            height: 112

          },
        },
        title: 'Tittle is missing',


      });
    }
    else if (item.startDate === undefined) {
      swal({
        content: {
          element: 'img',
          attributes: {
            src: angry,
            width: 165,
            height: 112

          },
        },
        title: 'Start Date is missing',


      });

    }
    else if (item.startTime == undefined) {
      swal({
        content: {
          element: 'img',
          attributes: {
            src: angry,
            width: 165,
            height: 112

          },
        },
        title: 'Start Time is missing',


      });
    }
    else if (item.EndDate === undefined) {
      swal({
        content: {
          element: 'img',
          attributes: {
            src: angry,
            width: 165,
            height: 112

          },
        },
        title: 'End Date is missing',


      });
    }
    else if (item.endTime == undefined) {

      swal({
        content: {
          element: 'img',
          attributes: {
            src: angry,
            width: 165,
            height: 112

          },
        },
        title: "End Time is missing!",


      });
    }
    else {



      const eventObj = {

        address: null,
        documentofproof: "",
        mmucode: mmucode,
        enddate: convertTZ(new Date(item.EndDate + " " + item.endTime)),
        startdate: convertTZ(new Date(item.startDate + " " + item.startTime)),
        title: item.tittle,
        id: null,
        flag: buttonValue,
        pincode: item.pincode,
        completeaddress: item.completeaddress,
        location: null,
        createdby:
          JSON.parse(localStorage.getItem("mmu-auth")).firstname +
          " " +
          JSON.parse(localStorage.getItem("mmu-auth")).lastname,
        createddate: null,
        modifiedby: "",
        modifieddate: null,
        requiredattendees: "",
        recstatus: "OPEN",
        status: "ACTIVE",
        syncstatus: "SYNCED",
      };

      eventObj.action = (
        <span>

          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",

              }}
              onClick={() => removeItem(eventObj.pincode.id)}
            />
          </Link>

        </span>
      );



      let filter = tableData.rows.filter(
        (a) => a.pincode.id == item.pincode.id
      );

      if (filter.length < 1) {

        tableData.rows.push(eventObj);
        dataObj.rows = tableData.rows;
        setTableData(dataObj);
        values.PincodeList = tableData.rows;

      }
      console.log("evntobhhh", eventObj)
    }
  };

  console.log("valuespincode", values.PincodeList)


  // Handle the next page button click
  function handleNextPage() {
    setCurrentPage(currentPage => currentPage + 1);
  }

  // Handle the previous page button click
  function handlePreviousPage() {
    setCurrentPage(currentPage => currentPage - 1);
  }
  function handlePreviousPage() {
    setCurrentPage(currentPage => currentPage - 1);
  }

  // Handle the search button click
  function handleSearch() {
    setCurrentPage(1); // Reset to the first page when searching
    fetchData(1, pageSize, searchQuery);
  }


  // Handle checkbox change

  function handleSelectRow(item) {

    setSelectedRowValues(selectedRowValues => [...selectedRowValues, item]);
  }

  function createPincode(data) {

    console.log(data)
    setOpen(false)
  }


  // Render the table rows with add button
  function renderRows() {
    return (
      <tbody>
        {data.map(item => (
          <tr key={item.id}>
            <td>
              <MDButton size="small" onClick={() => addData(item)}
                color="info">
                
                Add
              </MDButton>
            </td>

            <td>{item.completeaddress}</td>
            <td>

              <MDInput
                type="text"
                variant="outlined"
                size="small"
                name={item.id}
                label="tittle"

                onChange={(e) => (item.tittle = e.target.value)}

              />

            </td>
            <td>
              <MDInput
                type="date"
                onChange={(e) => (item.startDate = e.target.value)} />
            </td>
            <td>
              <MDInput type="time" onChange={(e) => (item.startTime = e.target.value)} />
            </td>
            <td>
              <MDInput
                type="date"
                onChange={(e) => (item.EndDate = e.target.value)} />
            </td>
            <td>
              <MDInput type="time" onChange={(e) => (item.endTime = e.target.value)} />
            </td>

            {/* Add more columns as needed */}
          </tr>
        ))}
      </tbody>
    );
  }



  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox pt={3}>

          <MDBox>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <Autocomplete
                    defaultValue={defaultMMU}
                    disablePortal
                    id="combo-box-demo"
                    sx={{ width: 280 }}
                    {...defaultPropsMMU}
                    onChange={(event, newValue) => {
                      setMmucode(newValue?.code);
                      getEvent(newValue?.code);
                      values.PincodeList = []
                      tableData.rows = []
                      fetchData(newValue?.code, 0, pageSize, searchQuery)
                      // getPinCode(newValue?.code);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="MMU" />
                    )}
                  />
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-46px",
                    color: "#344767",
                  }}
                  onClick={() => setOpen(true)}
                  to={{}}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            {loading2 && <Calendar onClickEvent={(e) => console.log(e)} events={events} />}
            {/* <Calendar onClickEvent={(e) => console.log(e)} events={events} /> */}
          </MDBox>
        </MDBox>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle style={{ textAlign: "center" }}>Create Event</DialogTitle>
        <DialogContent >


          <Grid mt={0.5} container spacing={3}>

            <Grid item xs={12} sm={9}>
              <span>
                {buttonCondition === true && (
                  <FormControlLabel
                    // name={item.id}
                    control={
                      <Switch
                        color="warning"
                        onClick={(e) =>
                          toggler(e.target.checked)

                        }
                        defaultChecked
                      />
                    }
                    label=<b style={{ color: "#4CAF50" }}>ONLINE</b>
                  />
                )}
                {buttonCondition !== true && (
                  <FormControlLabel
                    // name={item.id}
                    control={
                      <Switch

                        onClick={(e) =>
                          toggler(e.target.checked)

                        }

                      />
                    }
                    label=<b style={{ color: "#ee3b3b" }}>OFFLINE</b>
                  />
                )}

              </span>
            </Grid>


            <Grid item xs={12} sm={3}> 
              <MDInput
                type="text"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  onMMUChange();
                }}

                label="Serach"

              />
            </Grid>
          </Grid>
          <div>
            <table className="data-table">
              <thead>
                <tr>
                  <th>Action</th>

                  <th>Name</th>
                  <th>Title</th>
                  <th>Start Date</th>
                  <th>Start Time</th>
                  <th>End Date</th>
                  <th>End Time</th>
                  {/* Add more table headers as needed */}
                </tr>
              </thead>
              {renderRows()}
            </table>
            <div className="pagination-buttons">
              <MDButton
              size="small"
           style={{marginRight:"20px"}}
                color="info"
                onClick={handlePreviousPage}
                disabled={currentPage === 0}
              >
                Previous
              </MDButton>
              <p className="total-pages">Page {currentPage + 1} of {totalPages}</p>
              <MDButton
              size="small"
              style={{marginLeft:"20px"}}
                color='success'
                onClick={handleNextPage}
                disabled={currentPage === totalPages - 1}
              >
                Next
              </MDButton>
            </div>
          </div>

          <h3 style={{ textAlign: "center" }}>Selected Event List</h3>
          <DataTable
            showTotalEntries={false}
            entriesPerPage={false}
            table={tableData}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Close</Button> */}
          <MDButton color="info" onClick={handleClose}>Close</MDButton>
          <MDButton onClick={() => createEvent(values.PincodeList)} color="success">
            Create
          </MDButton>
        </DialogActions>

      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default MmuScheduler;
