/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    project: {
      name: "project",
      label: "Project", 
      type: "text",
      errorMsg: "project is required.",
    },

    department: {
      name: "department",
      label: "Department", 
      type: "text",
      errorMsg: "department is required.",
    },

    designation: {
      name: "designation",
      label: "Designation",
      type: "text",
      errorMsg: "designation is required.",
    },
   
  
   
   
    profile: {
      name: "profile",
      label: "Profile", 
      type: "text",
      errorMsg: "profile is required.",
    },
   
  

   
  },
};

export default form;
