/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    mmu: {
      name: "mmu",
      label: "Select MMU",
      type: "text",
      errorMsg: "MMU is required.",
    },
    mmuname: {
      name: "mmuname",
      label: "MMU NAME",
      type: "text",
      errorMsg: "mmuname is required.",
    },
    vechicleassigned: {
      name: "vechicleassigned",
      label: "vehicle No",
      type: "text",
      errorMsg: "vechicleassigned is required.",
    },
    // vehicleNo: {
    //   name: "vehicleNo",
    //   label: "Vehicle No",
    //   type: "text",
    //   errorMsg: "Vehicle No is required.",
    // },
    mmudescription: {
      name: "mmudescription",
      label: "MMU Description",
      type: "text",
      errorMsg: "mmudescription is required.", 
    },
    equipmentassigned: {
      name: "equipmentassigned",
      label: "Equipment Assigned",
      type: "text",
      errorMsg: "equipmentassigned is required.", 
    },
    warehouse: {
      name: "warehouse",
      label: "Ware House",
      type: "text",
      errorMsg: "warehouse is required.", 
    },
    role: {
      name: "role",
      label: "Role", 
      type: "text",
      errorMsg: "Please select role.",
    },
    profile: {
      name: "profile",
      label: "Profile", 
      type: "text",
      errorMsg: "Please select profile.",
    },
  },
};

export default form;
