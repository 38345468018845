
// import React, { useEffect, useRef } from "react";
// import { useForm } from "react-cool-form";
// import MDInput from "components/MDInput";
// import { useNavigate } from "react-router-dom";
// import Card from "@mui/material/Card";
// import BasicLayout from "layouts/authentication/components/BasicLayout";
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
// import MDTypography from "components/MDTypography";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import axios from "axios";
// import { logIn } from "../../../../redux/Actions/authActions"
// import { useDispatch, useSelector } from "react-redux"
// import lottie from "lottie-web";
// import swal from 'sweetalert';
// export default function Basic() {
//   const [getemail, setEmail] = React.useState("");
//   const [getpassword, setPassword] = React.useState("");
//   const [confirmPassword, setConfirmPassword] = React.useState("");
//   const navigate = useNavigate();
//   const dispatch = useDispatch()
//   const container = useRef(null);
//   const { isAuthenticated } = useSelector((({ AuthReducer }) => AuthReducer))
//   const [show, setShow] = React.useState(true);
//   const { form, use } = useForm({

//     defaultValues: { email: "", password: "", resourcecode: "DPF" },

//     onSubmit: (values) => loginUser(values)

//   });
//   async function checkusername(name) {
//     console.log("31", name);

//     await axios
//       .get(`profile-service-mongo/api/ProfileEntity/v2/get?username=${name}`)
//       .then((response) => {
//         console.log("36", response.data.data.dtoList[0].password)
//         if (response.data.data.dtoList[0].password == null || "") {

//           setShow(false);

//         }
//         else {
//           setShow(true);
//         }

//         console.log("38", response);

//       })

//       .catch((e) => {
//         console.log("error", e);
//       });

//   }

//   async function handlesetpassword(usersname, userpassword, conformpassword) {
//     console.log("259", usersname)
//     console.log("260", userpassword)
//     console.log("263", conformpassword)
//     const getData = {
//       username: usersname.trim(),
//       password: userpassword.trim(),
//       resourcecode: "DPF",

//       approvedby: "",
//       approveddate: "",
//       classname: "",
//       createdby: "",
//       createddate: null,
//       documentofproof: "",
//       id: null,
//       latlong: "",
//       location: null,
//       modifiedby: "",
//       modifieddate: null,

//       recstatus: "OPEN",
//       status: "ACTIVE",
//       syncstatus: "SYNCED",

//     };
//     if (userpassword && userpassword === conformpassword) {


//       await axios
//         .post(
//           "profile-service-mongo/api/ProfileEntity/v2/setPassword",
//           getData
//         )

//         .then((response) => {
//           console.log("gfgfgf", response);
//           swal("Alert!", "...your password has been sucessfully update!","success")
//           .then((value) => {
//             window.location.reload(true)

//           });



//         })
//         .catch((error) => {
//           console.log(error);


//         });
//     }
//     else {

//       // navigate('/authentication/sign-in/basic')
//       swal("Alert!", "...Your password and confirmation password do not match.!","warning")
//         .then((value) => {
//           window.location.reload(true)

//         });

//     }
//   }

//   const errors = use("errors");


//   const loginUser = async (data) => {
//     const getData = {
//       username: data.email,
//       password: data.password,
//       resourcecode: "DPF"

//     };

//     dispatch(logIn(getData))
//   };

//   useEffect(() => {
//     if (isAuthenticated) {

//       navigate('/dashboards/analytics')
//     }
//   }, [isAuthenticated])

//   useEffect(() => {
//     lottie.loadAnimation({
//       container: container.current,
//       renderer: "svg",
//       autoplay: true,
//       animationData: require("./hospital.json"),

//     });
//   }, []);


//   return (
//     <BasicLayout image={bgImage}>
//       <MDBox>
//         <Card style={{ marginTop: "183px" }}>

//           <MDBox
//             variant="gradient"
//             bgColor="info"
//             borderRadius="lg"
//             coloredShadow="info"
//             mx={2}
//             mt={-3}
//             p={4}
//             mb={1}
//             textAlign="center"

//           >
//             <MDTypography variant="h4" fontWeight="medium" color="white">
//               MMU ADMIN LOGIN
//             </MDTypography>

//           </MDBox>

//           <form ref={form} noValidate>

//             <MDBox pt={4} pb={5} px={5}>



//               <MDBox mb={2} mt={2}>
//                 <MDInput name="email" style={{ width: "261px" }} value={getemail} onBlur={(e) => {
//                   console.log("focusout")
//                   checkusername(getemail.trim());
//                 }}
//                   onChange={(e) => setEmail(e.target.value)} placeholder="Username" required />
//                 {errors.email && <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.email}</p>}

//               </MDBox>

//               {
//                 show ?

//                   <MDBox mb={2} mt={4}>
//                     <MDInput name="password"

//                       style={{ width: "261px" }}
//                       type="password" placeholder="Password" required />

//                     {errors.password && <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.password}</p>}
//                   </MDBox> : null}

//               {
//                 show ?
//                   null : <MDBox>
//                     <MDInput name="password"
//                       onChange={(e) => setPassword(e.target.value)}

//                       type="password" placeholder="set New Password" required style={{ width: "261px" }}

//                     />

//                     {errors.password && <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.password}</p>}
//                   </MDBox>}

//               {
//                 show ? null : <MDBox >
//                   <MDInput name="conformpassword"
//                     type="password" placeholder="set conform Password" required style={{ width: "261px" }}
//                     onChange={(e) => setConfirmPassword(e.target.value)}
//                   />

//                   {errors.password && <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.password}</p>}
//                 </MDBox>}

//               {
//                 show ?
//                   <MDBox mb={1}>
//                     <MDButton variant="gradient" color="info" fullWidth type="submit">
//                       Log In
//                     </MDButton>
//                   </MDBox> : <MDButton variant="gradient" color="info" fullWidth type="button"
//                     onClick={() => handlesetpassword(getemail.trim(), getpassword.trim(), confirmPassword.trim())}  >
//                     Set Password
//                   </MDButton>
//               }
//             </MDBox>

//           </form>
//           {/* } */}

//         </Card>
//         <MDBox mt={-28} mr={-55}>
//           <MDBox
//             ml={60}
//             style={{ height: "100%", width: "50%" }}
//             ref={container}
//           >

//           </MDBox>
//         </MDBox>
//       </MDBox>
//     </BasicLayout>

//   );
// }

import React, { useEffect, useRef } from "react";
import { useForm } from "react-cool-form";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { logIn } from "../../../../redux/Actions/authActions"
import { useDispatch, useSelector } from "react-redux"
import lottie from "lottie-web";
//navigate to other screen=============
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
export default function Basic() {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const container = useRef(null);
  const { isAuthenticated } = useSelector((({ AuthReducer }) => AuthReducer))

  const { form, use } = useForm({

    defaultValues: { email: "", password: "", resourcecode: "DPF" },

    onSubmit: (values) => loginUser(values)

  });


  const errors = use("errors");


  const loginUser = async (data) => {
    const getData = {
      username: data.email,
      password: data.password,
      resourcecode: "DPF"

    };

    dispatch(logIn(getData))
  };

  useEffect(() => {
    if (isAuthenticated) {

     navigate('/pages/profile/profile-overview')
    
    }
  }, [isAuthenticated])

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,

      animationData: require("./hospital.json"),
    });
  }, []);


  return (
    <BasicLayout image={bgImage}>




      <Card height="100%" style={{ marginTop: "183px" }}>

        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-1}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
            Sign In
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your username and password to logIn
          </MDTypography>
        </MDBox>




        <form ref={form} noValidate>

          <MDBox pt={4} pb={3} px={3}>

            <MDBox>

              <MDBox mb={2}>
                <MDInput name="email" placeholder="Username" required style={{ width: "310px" }} />
                {errors.email && <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.email}</p>}
              </MDBox>



              <MDBox mb={2}>

                <MDInput name="password" type="password" placeholder="Password" required style={{ width: "310px" }} />
                {errors.password && <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.password}</p>}
              </MDBox>

              <MDBox mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  Log In
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>

        </form>



      </Card>
      <MDBox mt={-28} mr={-65}>
        <MDBox
          ml={55}
          style={{ height: "100%", width: "50%" }}
          ref={container}
        >

        </MDBox>
      </MDBox>

    </BasicLayout>
  );
}





