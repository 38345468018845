import * as Yup from "yup";
import checkout from "layouts/pages/mmucreation/new mmucreation/schemas/form";

const {
  formField: {
    MMUName,
    MMUDescription,
    
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [MMUName.name]: Yup.string().required(MMUName.errorMsg),
    //[MMUDescription.name]: Yup.string().required(MMUDescription.errorMsg),
    // [EquipmentList.name]: Yup.string().required(EquipmentList.errorMsg),
    // [AssignEquipment.name]: Yup.string().required(AssignEquipment.errorMsg),
    // [VehicleAssign.name]: Yup.string().required(VehicleAssign.errorMsg),
    // [mapware.name]: Yup.string().required(mapware.errorMsg),
  }),
];

export default validations;
