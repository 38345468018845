import * as Yup from "yup";
import checkout from "layouts/pages/Project/new Project/schemas/form";

const {
  formField: {
    name,
    description,
    state,
    district,
    subDistrict,
    pincode,
    projecthirarichalcode,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [description.name]: Yup.string().required(description.errorMsg),
    // [state.name]: Yup.string().required(state.errorMsg),
    // [district.name]: Yup.string().required(district.errorMsg),
    // [subDistrict.name]: Yup.string().required(subDistrict.errorMsg),
    // [pincode.name]: Yup.string().required(pincode.errorMsg),
    // [projecthirarichalcode.name]: Yup.string().required(
    //   projecthirarichalcode.errorMsg
    // ),
    [name.name]: Yup.string().required(name.errorMsg),
  }),
];

export default validations;
