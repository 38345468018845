/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/pages/nurseregistration/new-nurseregistration/schemas/form";

const {
  formField: {
    mobileno,
    name,
    // mmunumber,
    // village,
    address,
    age,
    email,
    // gender,
    // marital,
    // education,
    // occupation,
    // rationcard,
    // disability,
    // comdoc,
    docno,
   
    name2,
    mobileno2,
    address2,
    
  
   
  },
} = checkout;

const initialValues = {
  [mobileno.name]: "",
   [name.name]: "",
  // [mmunumber.name]: "",
  // [village.name]: "",
  [address.name]: "",
  [age.name]: "",
  
  // [gender.name]: "",
  // [marital.name]: "",
  // [education.name]: "",
  // [occupation.name]: "",
  // [rationcard.name]: "",
  // [disability.name]: "",
  // [comdoc.name]: "",
  [docno.name]: "",
  [name2.name]: "",
  [mobileno2.name]: "",
  [address2.name]: "",

  
  
  
  
  

};

export default initialValues;
