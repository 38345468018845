/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-type-form",
  formField: {
    typeName: {
      name: "typeName",
      label: "TypeName",
      type: "text",
      errorMsg: "TypeName name is required.",
    },
    typeDescrption: {
      name: "typeDescrption",
      label: "TypeDescrption",
      type: "text",
      errorMsg: "typeDescrption is required.",
    },
    Category: {
      name: "Category",     
      label: "Category",
      type: "text",
      errorMsg: "Category is required.",
    },
    Categorytype: {
      name: "Categorytype",     
      label: "Category Type",
      type: "text",
      errorMsg: "Categorytype is required.",
    },
   
   
  
  },
};

export default form;
