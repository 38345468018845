import * as React from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Autocomplete } from "@mui/material";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

//EDit button

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

function Statelist() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [itemtList, setItemList] = useState("");
  const [item, setItem] = useState({});
  const [open, setOpen] = React.useState(false);
  const [selectitem, setSelectItem] = useState("");
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const defaultProps = {
    options: !itemtList ? [{ name: "Loading...", id: 0 }] : itemtList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  async function getItemtData() {
    await axios
      .get(
        `typemaster-service/api/TypeMaster/v1/get?category=ITEM`
      )
      .then((response) => {
        setItemList(response.data?.data?.dtoList);
        getData(response.data?.data?.dtoList);
        console.log("67", response.data?.data?.dtoList);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }
  async function onUpdatedata(object, itemList) {
    setSelectItem({ name: "Loading...", id: 0 });
    console.log("86", itemtList);
    setItemList(itemList);

    console.log("hii", object);
    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i].name === object.type) {
        console.log("900", itemList[i]);
        setSelectItem(itemList[i]);
      }
    }
    //  let depobj= departmentList.map((obj) => obj.code ===
    //     object.parentdepcode)

    //   setSelectItem(depobj)

    setItem(object);

    // setUpdatename(object.name);
    // setUpdateDescrption(object.description);
    setOpen(true);
    console.log("102", setOpen(true));
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function onDelete(item) {
    if (
      window.confirm("Are you sure that you want to delete that State record")
    )
      await axios
        .put(
          "pincode-service/api/pincode/v1/delete",
          item
        )
        .then((response) => {
          console.log(response);
          getData();
        })
        .catch((error) => {
          console.log(error);
        });
  }
  async function updateData() {
    // item.name = Updatename;
    // item.description = UpdateDescrption;
    item.modifiedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;
    delete item.action;
    await axios
      .put(
        "itemmaster-service/api/itemMaster/v1/update",
        item
      )
      .then((response) => {
        setOpen(false);
        getItemtData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData(typelist) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "State Name", accessor: "state.name", width: "25%" },
        { Header: "Short Name", accessor: "state.shortname", width: "20%" },
        { Header: "State Code", accessor: "state.code" },
        { Header: "created by", accessor: "createdby" },
        { Header: "action", accessor: "action", width: "12%" },
      ],
      rows: [],
    };
    axios
      .get(
        `pincode-service/api/pincode/v1/get?type=STATE`
      )
      .then((response) => {
        response.data.data.dtoList.map((item) => {
          item.action = (
            <span>
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getItemtData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    State Table
                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/page/component/create-state"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Item</DialogTitle>
        <DialogContent style={{ width: 550, height: 190 }}>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultProps}
              value={selectitem}
              onChange={(event, newValue) => {
                // handleChange(event);
                item.type = newValue.name;

                setSelectItem(newValue);

                // values.department = newValue.hierarchicalcode;
                console.log("281", selectitem);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="Item Type" />
              )}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData}>Update</Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default Statelist;
