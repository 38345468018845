
const form = {
  formId: "new warehouse-form",
  formField: {
    name: {
      name: "name",
      label: "name",
      type: "text",
      errorMsg: "name is required.",
    },
    type: {
      name: "type",
      label: "type",
      type: "text",
      errorMsg: "type is reqired.",
    },
    sapcode: {
      name: "sapcode",
      label: "Sapcode",
      type: "text",
      errorMsg: "sapcode is required.",
    },
    firstline: {
      name: "firstline",
      label: "firstline",
      type: "text",
      errorMsg: "firstline is required.",
    },
    state: {
      name: "state",
      label: "state",
      type: "text",
      errorMsg: "state is required.",
    },
    district: {
      name: "district",
      label: "District",
      type: "text",
      errorMsg: "district is required.",
    },
    subDistrict: {
      name: "subDistrict",
      label: "Sub-District",
      type: "text",
      errorMsg: "subDistrict is required.",
    },

    pincode: {
      name: "pincode",
      label: "pincode",
      type: "number",
      errorMsg: "pincode is required.",
    },
  },
};

export default form;

