/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-department-form",
  formField: {
    departmentName: {
      name: "departmentName",
      label: "departmentName",
      type: "text",
      errorMsg: "department name is required.",
    },
    departmentDescription: {
      name: "departmentDescription",
      label: "departmentDescription",
      type: "text",
      errorMsg: "departmentDescription is required.",
    },
   
    department: {
      name: "department",
      label: "Parent Department",
      type: "text",
      errorMsg: "Department is required.",
    },
  
  },
};

export default form;
