// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "layouts/pages/role/new-role/componets/FormField";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

function RoleInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const { roleName, roleDescrption } = formField;
  var { roleName: roleNameV, roleDescrption: roleDescrptionV } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Role Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={roleName.type}
                label={roleName.label}
                name={roleName.name}
                value={roleNameV}
                placeholder={roleName.placeholder}
                error={errors.roleName && touched.roleName}
                success={roleNameV.length > 0 && !errors.roleName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={roleDescrption.role}
                label={roleDescrption.label}
                name={roleDescrption.name}
                value={roleDescrptionV}
                placeholder={roleDescrption.placeholder}
                error={errors.roleDescrption && touched.roleDescrption}
                success={roleDescrptionV.length > 0 && !errors.roleDescrption}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}></Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for RoleInfo
RoleInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RoleInfo;
