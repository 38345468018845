const form = {
  formId: "new item-form",
  formField: {
    // itemame: {
    //   name: "itemame",
    //   label: "Itemname",
    //   type: "text",
    //   errorMsg: "item is required.",
    // },
    itemtype: {
      name: "itemtype",
      label: "Itemtype",
      type: "text",
      errorMsg: "itemtype is reqired.",
    },
    sapcode: {
      name: "sapcode",
      label: "Sapcode",
      type: "text",
      errorMsg: "sapcode is required.",
    },
    uom: {
      name: "uom",
      label: "Uom",
      type: "text",
      errorMsg: "uom is required.",
    },
  },
};

export default form;
