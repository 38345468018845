function getFilter(profileId, page){
    const data = [{
        page:"Test",
        filtername:"AUTO-COMPLETE",
        collectionname:"ProjectTeam",
        sequence:"1",
        field:"code",
        value:"",
        listvalue: [{name : "P1", code:"x"},{name : "P2", code:"y"}],
        label:"Select Project"

    },{
        page:"Test",
        filtername:"DATE",
        collectionname:"ProjectTeam",
        sequence:"1",
        field:"code",
        value: new Date().toDateString(),
        listvalue: [{name : "M1", code:"x"},{name : "M2", code:"y"}],
        label:"Select Date"

    }]
    return data;
}
export {getFilter};
