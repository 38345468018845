/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-type-form",
  formField: {
    diseaseName: {
      name: "diseaseName",
      label: "Disease Name",
      type: "text",
      errorMsg: "diseaseName name is required.",
    },
    diseaseDescrption: {
      name: "diseaseDescrption",
      label: "Disease Descrption",
      type: "text",
      errorMsg: "diseaseDescrption is required.",
    },
    diseasetype: {
      name: "diseasetype",     
      label: "Disease Type",
      type: "text",
      errorMsg: "diseasetype is required.",
    },
    // Categorytype: {
    //   name: "Categorytype",     
    //   label: "Category Type",
    //   type: "text",
    //   errorMsg: "Categorytype is required.",
    // },
   
   
  
  },
};

export default form;
