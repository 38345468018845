import * as Yup from "yup";
import checkout from "layouts/pages/Department/new-department/schemas/form";

const {
  formField: { departmentName, departmentDescription, department  },
} = checkout;

const validations = [
  Yup.object().shape({
    [departmentName.name]: Yup.string().required(departmentName.errorMsg),
    [departmentDescription.name]: Yup.string().required(departmentDescription.errorMsg),
 
    // [department.name]: Yup.string().required(department.errorMsg),
 
  }),
];

export default validations;
