// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "layouts/pages/role/new-role/componets/FormField";


import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

function StateInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");



  const { formField, values, errors, touched } = formData;
 
  const {statecode, shortname,statename} = formField;
  var {
    statecode: statecodeV,
    shortname:  shortnameV,
    statename:statenameV,
    
  } = values;

  

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">State Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
          <FormField
                type={statename.type}
                label={statename.label}
                name={statename.name}
                value={statenameV}
                placeholder={statename.placeholder}
                error={errors.statename && touched.statename}
                success={statenameV.length > 0 && !errors.statename}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={shortname.type}
                label={shortname.label}
                name={shortname.name}
                value={shortnameV}
                placeholder={shortname.placeholder}
                error={errors.shortname && touched.shortname}
                success={shortnameV.length > 0 && !errors.shortname}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
               <FormField
                type={statecode.type}
                label={statecode.label}
                name={statecode.name}
                value={statecodeV}
                placeholder={statecode.placeholder}
                error={errors.statecode && touched.statecode}
                success={statecodeV.length > 0 && !errors.statecode}
              />
            </Grid>
         
          </Grid>
         
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for StateInfo
StateInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default StateInfo;




