import * as Yup from "yup";
import checkout from "layouts/pages/Lab/new-lab/schemas/form";

const {
  formField: { testName, testResult, presId, mmu, detailResult },
} = checkout;

const validations = [
  Yup.object().shape({
    [testName.name]: Yup.string().required(testName.errorMsg),
    [testResult.name]: Yup.string().required(testResult.errorMsg),
    [presId.name]: Yup.string().required(presId.errorMsg),
    [mmu.name]: Yup.string().required(mmu.errorMsg),
    [detailResult.name]: Yup.string().required(detailResult.errorMsg),
  }),
];

export default validations;
